// import Vue from 'vue';

const state = () => ({
  globalComponentSize: 'md',
});

const getters = {
  globalComponentSize: (state) => {
    return state.globalComponentSize;
  },
};

const actions = {
  clearSiteWideData: ({ commit }) => {
    commit('clearData');
  },
  changeGlobalComponentSize: ({ commit }, value) => {
    if (value === 'sm') {
      document.getElementById('app').style['font-size'] = '.75rem';
    } else if (value === 'md') {
      document.getElementById('app').style['font-size'] = '1rem';
    } else if (value === 'lg') {
      document.getElementById('app').style['font-size'] = '1.25rem';
    }

    commit('setGlobalComponentSize', value);
  },
  toastError: ({ dispatch }, message) => {
    dispatch('toast', { type: 'error', displayText: message }, { root: true });
  },
  toastSuccess: ({ dispatch }, message) => {
    dispatch(
      'toast',
      { type: 'success', displayText: message },
      { root: true }
    );
  },
  toast: {
    //Define the action stub here, making sure there is a root level action for toast. Components or other modules are expected to subscribe to
    //  and act on this action. Currently the pin module does not need to do anything to the state.
    root: true,
    handler() {
      return;
    },
  },
};

const mutations = {
  clearData: (state) => {
    // state = []
    // don't change global component size
    console.log(state);
  },
  setGlobalComponentSize: (state, value) => {
    state.globalComponentSize = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
