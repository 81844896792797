import axios from 'axios';

export default {
  /*
  Expected Request, loginTokenAssembly
  {
    _id: string,
    type: string,
    "isAdmin": {"type": "boolean"},
    expiration: Date, // optional
    token: string
  }

  Expected Response
  {
    responseCode: string,
    clubs: [
      {
        _id: string,
        clubName: string,
        owner: string, // loginId
        clubTokenAssembly: {
          _id: string,
          type: string,
          expiration: Date, // optional
          token: string
        }
      }
    ]
  }
  */
  getAllClubsForLoginSession: (loginTokenAssembly) =>
    new Promise((resolve, reject) => {
      let credentials = loginTokenAssembly;
      let path = '/api/club/';
      axios
        .post(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .post(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),

  /* 
  Expected input, loginTokenAssembly
  {
    loginTokenAssembly: {
      _id: string,
      type: string,
      expiration: Date,
      "isAdmin": {"type": "boolean"},
      token: string
    },
    clubToSave: {
        _id: string,
        clubName: string,
        owner: string, // loginId
        _rev: string // must exist for updates
      }  
  }

  Expected Response
  {
    responseCode: string,
    clubs: [
      {
        responseCode: string,
        club: {
          _id: string,
          clubName: string,
          owner: string, // loginId
          _rev: string,
          clubTokenAssembly: {
            _id: string,
            type: string,
            expiration: Date, // optional
            token: string
          }
        }
      }
    ]
  }
  */
  saveClub: (loginTokenAssembly, club) =>
    new Promise((resolve, reject) => {
      let credentials = {
        loginTokenAssembly: loginTokenAssembly,
        clubsToSave: [club],
      };
      let path = '/api/club/';
      axios
        .put(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .put(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
};
