<template>
  <div>
    <b-form id="addClubForm" @submit="onSubmit" @reset="onCancel">
      <b-form-group
        id="clubNameGroup"
        label="Club Name*:"
        label-for="clubName"
        :size="globalComponentSize"
      >
        <b-form-input
          id="clubName"
          v-model="form.clubName"
          required
          placeholder="Enter Club Name"
          :autofocus="true"
          :size="globalComponentSize"
        ></b-form-input>
      </b-form-group>

      <hr />

      <div>* indicates required field</div>

      <div>
        <b-button
          class="float-left"
          id="btnCancel"
          type="reset"
          variant="default"
          tabindex="-1"
          :size="globalComponentSize"
          >Cancel</b-button
        >

        <b-button
          class="float-right"
          id="btnSubmitClub"
          type="submit"
          variant="primary"
          :size="globalComponentSize"
          >Save</b-button
        >
      </div>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
const defaultFormValues = {
  clubName: '',
};
export default {
  name: 'AddClubForm',
  created() {
    this.$root.$emit('bv::hide::popover');
  },
  data() {
    return {
      form: defaultFormValues,
    };
  },
  computed: {
    ...mapGetters('sitewide', ['globalComponentSize']),
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let newClub = Object.assign({}, this.form);
      this.$emit('newclub', newClub);
      this.setDefaultValues();
    },
    onCancel() {
      this.$emit('cancel');
      this.setDefaultValues();
    },
    setDefaultValues() {
      this.form.clubName = '';
    },
  },
};
</script>
<style scoped></style>
