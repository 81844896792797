import loginApi from '../../api/login';
import jsSHA from 'jssha';
import router from '../../router';
import _ from 'lodash';

// ********************************************************
//   BEGIN: Encryption Ease of use methods with NaCl
// ********************************************************
const nacl = require('tweetnacl');
const nacl_util = require('tweetnacl-util');
const newNonce = () => nacl.randomBytes(nacl.box.nonceLength);
const generateKeyPair = () => nacl.box.keyPair();
const encrypt = (
  secretOrSharedKey, //: Uint8Array,
  jsonToEncrypt //: any,
) => {
  const nonce = newNonce();
  const messageUint8 = nacl_util.decodeUTF8(JSON.stringify(jsonToEncrypt));
  const encrypted = nacl.box.after(messageUint8, nonce, secretOrSharedKey);

  const fullMessage = new Uint8Array(nonce.length + encrypted.length);
  fullMessage.set(nonce);
  fullMessage.set(encrypted, nonce.length);

  const base64FullMessage = nacl_util.encodeBase64(fullMessage);

  return base64FullMessage;
};
// ********************************************************
//   END: Encryption Ease of use methods with NaCl
// ********************************************************

const state = () => ({
  loginSession: { isLoggedIn: false },
  localKeys: null,
  passwordEncryptionKey: null,
  confirmingLoginMessage:
    'Page loaded improperly. You should only be here via confirmation email links.',
  isConfirmed: null,
  loginProcessing: false,
  loadLoginAttemptsProcessing: false,
  loginAttempts: [],
  isGettingLoginKeys: false,
});

const getters = {
  isLoggedIn: (state) => {
    return state.loginSession.isLoggedIn;
  },
  isAdmin: (state) => {
    let isAdmin = false;
    if (state.loginSession.isLoggedIn) {
      isAdmin = state.loginSession.loginToken.isAdmin;
    }
    return isAdmin;
  },
  loginKeysLoaded: (state) => {
    return state.localKeys && state.passwordEncryptionKey;
  },
  isLoginProcessing: (state) => {
    return state.loginProcessing;
  },
  isGettingLoginKeys: (state) => {
    return state.isGettingLoginKeys;
  },
  getLoginTokenAssembly: (state) => {
    return state.loginSession.loginToken;
  },
  getLoggedInUsername: (state) => {
    return state.loginSession.loginToken._id;
  },
  getConfirmingLoginMessage: (state) => {
    return state.confirmingLoginMessage;
  },
  getIsConfirmed: (state) => {
    return state.isConfirmed;
  },
  getLoginAttempts: (state) => {
    return state.loginAttempts;
  },
  isLoadLoginAttemptsProcessing: (state) => {
    return state.loadLoginAttemptsProcessing;
  },
};

const actions = {
  createLoginKeys: async ({ commit }) => {
    try {
      console.time('pin.module.login.createLoginKeys');
      // we need a passwordEncryptionKey
      commit('setIsGettingLoginKeys', true);
      let callResult = await loginApi.getEncryptionKey();
      let passwordEncryptionKey = '';
      if (callResult.responseCode === 'normal') {
        passwordEncryptionKey = callResult.responseKey;
        passwordEncryptionKey = new Uint8Array(
          Object.values(passwordEncryptionKey)
        );
      } else {
        // retry once
        callResult = await loginApi.getEncryptionKey();
        if (callResult.responseCode === 'normal') {
          passwordEncryptionKey = callResult.responseKey;
          passwordEncryptionKey = new Uint8Array(
            Object.values(passwordEncryptionKey)
          );
        } else {
          console.log(
            `Failed to get security keys from server. Message from server was: ${callResult.message}`
          );
        }
      }

      // we need a set of local keys so only we can decrypt responses with them
      let localKeys = generateKeyPair();

      commit('setIsGettingLoginKeys', false);
      commit('setLoginKeys', [localKeys, passwordEncryptionKey]);
    } catch (err) {
      console.log('there was a problem here: ' + err);
    } finally {
      console.timeEnd('pin.module.login.createLoginKeys');
    }
  },
  tryLogin: async ({ commit, state, dispatch }, loginCredentials) => {
    try {
      console.time('pin.module.login.tryLogin');
      commit('setLoginProcessing', true);
      dispatch(
        'toast',
        { type: 'info', displayText: 'Logging In...' },
        { root: true }
      );
      let encryptedPassword = encryptPassword(state, loginCredentials.password);

      // try logging in
      let loginResult = await loginApi.tryLogin(
        loginCredentials._id,
        encryptedPassword,
        state.localKeys.publicKey
      );

      if (
        loginResult.responseCode === 'normal' &&
        loginResult.loginSession.isLoggedIn === true
      ) {
        commit('setLoginSession', loginResult.loginSession);
        dispatch(
          'toast',
          { type: 'success', displayText: 'You are Logged In!' },
          { root: true }
        );
      } else {
        commit('setLoginSession', { isLoggedIn: false });
        dispatch(
          'toast',
          {
            type: 'error',
            displayText: '<h4>!! Login Failed !!</h4>  Please try again.',
          },
          { root: true }
        );
      }
      commit('setLoginProcessing', false);
    } catch (err) {
      dispatch(
        'toast',
        {
          type: 'error',
          displayText: '<h4>!! Login Failed !!</h4>  Please try again.',
        },
        { root: true }
      );
      commit('setLoginProcessing', false);
      console.log(err);
    } finally {
      console.timeEnd('pin.module.login.tryLogin');
    }
  },
  startLoginManager: async ({ state, commit, dispatch }) => {
    setInterval(function () {
      if (state.loginSession.isLoggedIn) {
        // if session expiration is within 10 mins lets refresh the token.
        if (state.loginSession.loginToken !== undefined) {
          let now = new Date();
          let nowAsISOString = now.toISOString();
          let tenMinutesFromNow = new Date();
          let tenMinutesFromNowAsISOString = new Date(
            tenMinutesFromNow.setMinutes(now.getMinutes() + 10)
          ).toISOString();
          let expirationAsISOString = state.loginSession.loginToken.expiration;

          if (
            expirationAsISOString < tenMinutesFromNowAsISOString &&
            expirationAsISOString > nowAsISOString
          ) {
            // console.log("time to refresh the login Token")
            // TODO: need to write the api in login for this to take an existing non expired token assembly and return a new refreshed one if the given token is valid.
            // TODO: Prompt user to see if they want to be kept logged in
            // With a 30 day login session per device this becomes unlikely they will be in the app when the expiration comes up, just go ahead and let it logout and force them to login again. ... terrible user experience for the moment.
          } else if (expirationAsISOString < nowAsISOString) {
            console.log('The login token is Expired.');

            commit('clearData');
            dispatch('logout', {}, { root: true });
            dispatch(
              'toast',
              {
                type: 'info',
                displayText:
                  'Your login has expired and you have been logged out.',
              },
              { root: true }
            );
          }
        }
      }
    }, 10000);
  },
  loadLoginAttempts: async ({ commit, dispatch }, loginTokenAssembly) => {
    try {
      console.time('pin.module.login.loadLoginAttempts');
      commit('setLoadLoginAttemptsProcessing', true);
      commit('setLoginAttempts', []);

      let loginResult = await loginApi.loadLoginAttempts(loginTokenAssembly);

      if (loginResult.responseCode === 'normal') {
        commit('setLoginAttempts', loginResult.loginAttempts);
      } else {
        commit('setLoadLoginAttemptsProcessing', { isLoggedIn: false });
        dispatch(
          'toast',
          {
            type: 'error',
            displayText: 'Failed to load login attempts',
          },
          { root: true }
        );
      }
      commit('setLoadLoginAttemptsProcessing', false);
    } catch (err) {
      dispatch(
        'toast',
        {
          type: 'error',
          displayText: 'Failed to load login attempts. unhandled exception.',
        },
        { root: true }
      );
      commit('setLoadLoginAttemptsProcessing', false);
      console.log(err);
    } finally {
      console.timeEnd('pin.module.login.loadLoginAttempts');
    }
  },
  tryCreateLogin: async ({ state, dispatch }, createLoginData) => {
    try {
      console.time('pin.module.login.tryCreateLogin');

      dispatch(
        'toast',
        {
          type: 'createLoginSubmit',
          displayText:
            'Submitting account creation request for ' + createLoginData._id,
        },
        { root: true }
      );

      let encryptedPassword = await encryptPassword(
        state,
        createLoginData.password
      );

      // try logging in
      let createLoginResult = await loginApi.tryCreateLogin(
        createLoginData._id,
        encryptedPassword,
        createLoginData.firstName,
        createLoginData.lastName,
        state.localKeys.publicKey
      );

      if (createLoginResult.responseCode === 'normal') {
        dispatch(
          'toast',
          {
            type: 'createLogin',
            displayText:
              'A confirmation e-mail has been sent to ' + createLoginData._id,
          },
          { root: true }
        );
      } else {
        dispatch(
          'toast',
          {
            type: 'createLoginFailed',
            displayText:
              'There was a problem creating an account for ' +
              createLoginData._id +
              '.<br />The account may already exist or you may need to submit the request again.<br />Do you need to reset your password?',
          },
          { root: true }
        );
      }
    } catch (err) {
      dispatch(
        'toast',
        {
          type: 'createLoginFailed',
          displayText:
            'There was a problem creating an account for ' +
            createLoginData._id +
            '<br />Please try again.',
        },
        { root: true }
      );
      console.log(err);
    } finally {
      console.timeEnd('pin.module.login.tryCreateLogin');
    }
  },
  tryResetPassword: async ({ state, dispatch }, createLoginData) => {
    try {
      console.time('pin.module.login.tryResetPassword');

      dispatch(
        'toast',
        {
          type: 'info',
          displayText:
            'Submitting password reset request for ' + createLoginData._id,
        },
        { root: true }
      );

      let encryptedPassword = await encryptPassword(
        state,
        createLoginData.password
      );

      // try logging in
      let createLoginResult = await loginApi.tryResetPassword(
        createLoginData._id,
        encryptedPassword,
        state.localKeys.publicKey
      );

      if (createLoginResult.responseCode === 'normal') {
        dispatch(
          'toast',
          {
            type: 'success',
            displayText:
              'A confirmation e-mail has been sent to ' + createLoginData._id,
          },
          { root: true }
        );
      } else {
        dispatch(
          'toast',
          {
            type: 'error',
            displayText:
              'There was a problem resetting password for account ' +
              createLoginData._id +
              '.<br />The account may not exist or you may need to submit the request again.<br />If that does not work try logging out and back in..',
          },
          { root: true }
        );
      }
    } catch (err) {
      dispatch(
        'toast',
        {
          type: 'error',
          displayText:
            'There was a problem resetting password for account ' +
            createLoginData._id +
            '.<br />The account may not exist or you may need to submit the request again.<br />If that does not work try logging out and back in..',
        },
        { root: true }
      );
      console.log(err);
    } finally {
      console.timeEnd('pin.module.login.tryResetPassword');
    }
  },
  logOut: async ({ commit, dispatch }) => {
    commit('clearData');

    dispatch('logout', {}, { root: true });

    dispatch(
      'toast',
      { type: 'info', displayText: 'You have been logged out.' },
      { root: true }
    );
  },
  logout: {
    //Define the action stub here
    root: true,
    handler() {
      return;
    },
  },
  confirmCreateAccount: async (
    { commit },
    { _id, verificationCode, redirectDelay }
  ) => {
    try {
      console.time('pin.module.login.confirmCreateAccount');
      // set the message to say we're confirming the account
      commit(
        'setConfirmingLoginMessage',
        `Confirming account: ${_id}. Please wait a moment.`
      );
      commit('setIsConfirmed', false);

      // api call to confirm account
      const verificationResult = await loginApi.confirmLogin(
        _id,
        verificationCode
      );

      if (verificationResult.responseCode === 'normal') {
        commit(
          'setConfirmingLoginMessage',
          `Verified username: ${_id}. You may now login.`
        );
        commit('setIsConfirmed', true);
        setTimeout(() => {
          router.push({ name: 'home' });
        }, redirectDelay);
      } else {
        // TODO, resend confirmation mail
        commit(
          'setConfirmingLoginMessage',
          `Failed to verify username: ${_id}. Please ensure you followed the link you were emailed exactly.`
        );
        commit('setIsConfirmed', false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      console.timeEnd('pin.module.login.confirmCreateAccount');
    }
  },
};

const mutations = {
  clearData: (state) => {
    state.loginSession = { isLoggedIn: false };
    // state.localKeys = null; // Need to keep these around even on logout
    // state.passwordEncryptionKey = null; // Need to keep these around even on logout
    state.confirmingLoginMessage =
      'Page loaded improperly. You should only be here via confirmation email links.';
    state.isConfirmed = null;
    state.loginProcessing = false;
    state.loadLoginAttemptsProcessing = false;
    state.loginAttempts = [];
    state.isGettingLoginKeys = false;
  },
  setLoginProcessing: (state, value) => {
    state.loginProcessing = value;
  },
  setIsGettingLoginKeys: (state, value) => {
    state.isGettingLoginKeys = value;
  },
  setLoadLoginAttemptsProcessing: (state, value) => {
    state.loadLoginAttemptsProcessing = value;
  },
  setLoginSession: (state, loginSession) => {
    // example login Session:
    // TODO, update loginToken to loginTokenAssembly
    // {"isLoggedIn":true,"loginToken":{"_id":"loginUserName@gmail.com","type":"login","expiration":"2221-01-01T21:42:30.582Z","token":"some long encrypted gibberish"}}

    if (loginSession && loginSession.isLoggedIn === true) {
      state.loginSession = loginSession;
    } else {
      state.loginSession = loginSession;
    }
  },
  setLoginKeys: (state, keyArray) => {
    state.localKeys = keyArray[0];
    state.passwordEncryptionKey = keyArray[1];
  },
  setConfirmingLoginMessage: (state, message) => {
    state.confirmingLoginMessage = message;
  },
  setIsConfirmed: (state, isConfirmed) => {
    state.isConfirmed = isConfirmed;
  },
  setLoginAttempts: (state, loginAttempts) => {
    state.loginAttempts = _.orderBy(loginAttempts, ['attemptDate'], ['desc']);
  },
};

const encryptPassword = (state, unencryptedPassword) => {
  // Encode the password with SHA3-512
  let shaObj = new jsSHA('SHA3-512', 'TEXT', { encoding: 'UTF8' });
  shaObj.update(unencryptedPassword); // TODO, to increase complexity of brute force attacks, combine the password with the username to generate the hash, this will invalidate any rainbow table lookup
  var hashedPassword = shaObj.getHash('HEX');

  // encrypt password
  const sharedA = nacl.box.before(
    state.passwordEncryptionKey, // encrypting with server public key
    state.localKeys.secretKey // signing with our local secret key
  );
  return encrypt(sharedA, hashedPassword);
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
