<template>
  <div>
    <div>{{ currentMessage }}</div>
    <button @click="sendMessage('test message')">Check Status</button>
  </div>
</template>
<script>
// import { io } from 'socket.io-client';
// const WebSocket = require('ws');

export default {
  name: 'StatusBar',
  async created() {
    // TODO JACK https://github.com/ramkumarshankar/vue-simple-websocket

    console.log(
      'starting timmer to maintain connection to websocket server for status'
    );

    // // this code is good up till trying to reconnect
    // setInterval(function() {
    try {
      console.log('checking connection');
      console.log('the connection is ' + this.connection);

      if (this.connection === undefined || this.connection === null) {
        const base_url = window.location.host.toLowerCase();
        const protocol =
          window.location.protocol.toLowerCase() === 'https:' ? 'wss' : 'ws';
        this.connection = new WebSocket(
          `${protocol}://${base_url}/api/login/status/`
        );

        this.connection.onopen = function (event) {
          console.log(event);
        };

        this.connection.onmessage = function (event) {
          console.log('message received: ' + event.data);
          this.currentMessage = event.data;
        };
        this.connection.addEventListener('message', (e) => {
          this.connection.onmessage(e);
        });

        this.connection.onclose = function () {
          console.log('connection closed: ');
        };
        this.connection.addEventListener('close', (e) => {
          this.connection.onclose(e);
        });
      }
    } catch (e) {
      console.log(e);
    }
    // }, 5000)

    // const base_url = window.location.host.toLowerCase()
    // this.connection = io(`ws://${base_url}/api/login/status/`)

    // const base_url = window.location.host.toLowerCase()
    // // const base_url = '192.168.0.13:8080'
    // try {
    //   const socket = io.connect(`ws://${base_url}`, {
    //     path: '/api/login/status/',
    //     transports: ['websocket'],
    //     extraHeaders: {}
    //   })

    //   socket.on("connect_error", (err) => {
    //     console.log(`connect_error due to ${err.message}`);
    //   });

    //   console.log('socket is: ')
    //   console.log(socket)
    //   console.log('that was the socket')
    //   // socket.on('connect', () => {
    //   //   console.log('connected with socket id ')
    //   // })

    //   // socket.emit('message', 'sample message')
    // } catch (error) {
    //   console.log(error)
    // }

    // this.$socketClient.onOpen = () => {
    //   console.log('socket connected');
    // };
    // this.$socketClient.onMessage = (msg) => {
    //   console.log(JSON.parse(msg.data));
    // };
    // this.$socketClient.onClose = () => {
    //   console.log('socket closed');
    // };
    // this.$socketClient.onError = () => {
    //   console.log('socket error');
    // };
  },
  components: {},
  data() {
    return {
      connection: null,
      message: 'no messages yet',
    };
  },
  sockets: {
    connect: function (event) {
      console.log(event);
    },
  },
  methods: {
    sendMessage(message) {
      try {
        console.log('sending message: ' + message);

        this.connection.send(message);
        // let data = {
        //   type: 'message',
        //   text: message,
        // };
        // this.$socketClient.sendObj(data);
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted: function () {
    // console.log('trying to get status')
    // this.connection.send('please give me status')
  },
  computed: {
    currentMessage: {
      get() {
        return this.message;
      },
      set(value) {
        this.message = value;
      },
    },
  },
};
</script>
<style scoped></style>
