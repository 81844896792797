import axios from 'axios';

export default {
  /*
  Expected Request, optional loginTokenAssembly
  `{
    "type": "object",
    "properties": {
      "loginTokenAssembly": {
        "type": "object",
        "properties": {
          "_id": {"type": "string"},
          "type": {"type": "string"},
          "expiration": {"type": "string"},
          "isAdmin": {"type": "boolean"},
          "token": {"type": "string"}
        },
        "required": [
          "_id",
          "type",
          "token",
          "isAdmin",
          "expiration"
        ]  
      }
    },
    "required": [ ]
  }`

  Expected Response
  {
    {
      responseCode: 'error',
      countOfScoresChanged: 0,
      description: '',
      startDataScoresThatDoNotHaveClubIdSet: -1,
      startDataScoresThatDoHaveClubIdSet: -1
    }
  }
  */
  updateScoresRemoveTime: (loginTokenAssembly) =>
    new Promise((resolve, reject) => {
      let credentials = {
        loginTokenAssembly: loginTokenAssembly,
      };
      let path = '/api/admin/updateScoresRemoveTime/';
      axios
        .post(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .post(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
};
