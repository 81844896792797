<template>
  <div class="container">
    <b-spinner id="loadingSpinner" v-if="!pinGroupsLoaded" />
    <b-tabs id="pinGroupList" v-if="pinGroupsLoaded">
      <b-tab
        v-for="(pinGroup, idx) in pinGroups"
        :key="idx"
        :title="pinGroup.local + ' ' + pinGroup.classification"
      >
        <h5>{{ pinGroup.description }}</h5>
        <b-tabs>
          <b-tab
            v-for="(bowClass, jdx) in pinGroup.bowClasses"
            :key="jdx"
            :title="bowClass.bowType"
          >
            <h5>{{ bowClass.note }}</h5>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Pin</th>
                  <th>Distance</th>
                  <th>Target Size</th>
                  <th>Score</th>
                  <th v-if="bowClass.pinScoreOptionsHasNotes">Note</th>
                  <th>Pin Name</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(pinOption, kdx) in bowClass.pinScoreOptions.filter(option => option.shouldDisplayUI)"
                  :key="kdx"
                >
                  <td>
                    <PinIcon
                      :pinColor="pinOption.pinColor"
                      :pinLevel="pinOption.pinLevel_id"
                    />
                  </td>
                  <td>{{ pinOption.distance_meters }} m</td>
                  <td>{{ pinOption.targetSize_cm }} cm</td>
                  <td>{{ pinOption.score }}</td>
                  <td v-if="bowClass.pinScoreOptionsHasNotes">
                    {{ pinOption.note }}
                  </td>
                  <td>{{ pinOption.pinName }}</td>
                </tr>
              </tbody>
            </table>
          </b-tab>
        </b-tabs>
        <p>{{ pinGroup.summary }}</p>
        <p v-for="(pinNote, idx) in pinNotes" :key="idx">
          {{ pinNote.name }} : {{ pinNote.description }}
        </p>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import PinIcon from '../components/PinIcon';
import { mapGetters } from 'vuex';

export default {
  name: 'PinLookupX',
  components: {
    PinIcon,
  },
  computed: {
    ...mapGetters('pin', {
      pinGroups: 'pinGroups',
      pinGroupsLoaded: 'pinGroupsLoaded',
      pinNotes: 'pinNotes',
    }),
  },
};
</script>
<style scoped></style>
