import Vue from 'vue';
import App from './App.vue';
import VueMask from 'v-mask';
import router from './router';
import store from './store';
import { BootstrapVue, IconsPlugin, ToastPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import PortalVue from 'portal-vue';
import Toasted from 'vue-toasted';
// import VueSocketIO from 'vue-socket.io'
// import VueSimpleWebsocket from 'vue-simple-websocket';

Vue.config.productionTip = false;
Vue.use(VueMask);
Vue.use(PortalVue);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(ToastPlugin);
Vue.use(Toasted);

// const base_url = window.location.host.toLowerCase()
// console.log('btw, the base_url is: ' + base_url);
// Vue.use(new VueSocketIO({
//   connection: `ws://${base_url}`,
//   options: {path: '/api/login/status', transports: ['websocket']},
// }));

// Configure the server to connect to and reconnection parameters
// Here, we enable reconnection and retry every 5s if connection is lost
// const base_url = window.location.host.toLowerCase();
// console.log('btw, the base_url is: ' + base_url);
// let websocketEndpoint = `ws://${base_url}`;
// Vue.use(VueSimpleWebsocket, websocketEndpoint, {
//   reconnectEnabled: true,
//   reconnectInterval: 5000,
//   path: '/api/login/status/'
// });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
