<template>
  <svg width="244.93536" height="245.14903" viewBox="0 0 250 250">
    <defs id="defs1101" />
    <sodipodi:namedview
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1"
      objecttolerance="10"
      gridtolerance="10"
      guidetolerance="10"
      inkscape:pageopacity="0"
      inkscape:pageshadow="2"
      inkscape:window-width="640"
      inkscape:window-height="480"
      id="namedview1099"
    />
    <inkscape:clipboard
      style="
        font-variation-settings: normal;
        opacity: 1;
        vector-effect: none;
        fill: #ff0000;
        fill-opacity: 0.00581695;
        stroke: #000000;
        stroke-width: 1.00157102;
        stroke-linecap: butt;
        stroke-linejoin: miter;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-dashoffset: 0;
        stroke-opacity: 1;
        stop-color: #000000;
        stop-opacity: 1;
      "
      min="-1131.0391,-274.36611"
      max="-886.10374,-29.217081"
    />
    <ellipse
      style="
        opacity: 1;
        fill: #ff0000;
        fill-opacity: 0.00581695;
        fill-rule: evenodd;
        stroke: #000000;
        stroke-width: 1.00157;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-opacity: 1;
      "
      id="path10-3-5-6"
      cx="120.38839"
      cy="72.047241"
      rx="71.374512"
      ry="71.546455"
    />
    <ellipse
      style="
        opacity: 1;
        fill: #ff0000;
        fill-opacity: 0.00581695;
        fill-rule: evenodd;
        stroke: #000000;
        stroke-width: 1.00157;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-opacity: 1;
      "
      id="path10-3-5-7-8"
      cx="71.875298"
      cy="149.98087"
      rx="71.374512"
      ry="71.546455"
    />
    <ellipse
      style="
        opacity: 1;
        fill: #ff0000;
        fill-opacity: 0.00581695;
        fill-rule: evenodd;
        stroke: #000000;
        stroke-width: 1.00157;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-opacity: 1;
      "
      id="path10-3-5-3-1"
      cx="173.06007"
      cy="149.9003"
      rx="71.374512"
      ry="71.546455"
    />
    <path
      style="
        opacity: 1;
        fill: #0000ff;
        fill-opacity: 0.64654;
        fill-rule: evenodd;
        stroke: #000000;
        stroke-width: 0.357705;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-opacity: 1;
      "
      d="m 120.51461,197.14071 c -11.97592,-12.93541 -18.93475,-32.31638 -18.04006,-50.24314 0.12661,-2.53976 0.26834,-4.65337 0.31521,-4.69691 0.0465,-0.0435 1.40447,0.20855 3.01757,0.5602 9.92376,2.16328 22.76644,1.81722 32.87989,-0.88599 1.71368,-0.45804 3.17322,-0.77596 3.24352,-0.70649 0.4532,0.44799 0.72253,9.20246 0.4117,13.38266 -0.77907,10.47796 -3.06871,18.83188 -7.55543,27.56659 -2.98038,5.80223 -8.05383,13.04202 -11.28945,16.11 l -1.05256,0.99802 z"
      id="path962-2"
    />
    <path
      style="
        opacity: 1;
        fill: #0000ff;
        fill-opacity: 0.64654;
        fill-rule: evenodd;
        stroke: #000000;
        stroke-width: 0.357705;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-opacity: 1;
      "
      d="m 111.62425,142.39681 c -3.63998,-0.50127 -8.00299,-1.43554 -8.36719,-1.79171 -0.51855,-0.50715 1.05706,-7.78927 2.84443,-13.14626 3.17677,-9.5212 9.47014,-20.11025 15.27965,-25.70916 l 1.23912,-1.19421 2.56018,2.97992 c 3.58654,4.1746 7.92793,10.75543 10.22868,15.50497 3.0093,6.2122 6.75103,18.59909 6.11721,20.25081 -0.20334,0.53018 -4.22729,1.6682 -9.36635,2.64899 -3.36461,0.64215 -16.98879,0.94511 -20.53573,0.45665 z"
      id="path964-9"
    />
    <text
      xml:space="preserve"
      style="
        font-style: normal;
        font-weight: normal;
        font-size: 39.9999px;
        line-height: 1.25;
        font-family: sans-serif;
        fill: #000000;
        fill-opacity: 1;
        stroke: none;
        stroke-width: 0.999999;
      "
      x="93.003304"
      y="244.58263"
      id="text968-9"
    >
      <tspan
        sodipodi:role="line"
        x="93.003304"
        y="244.58263"
        style="stroke-width: 0.999999"
        id="tspan970-8"
      >
        OR
      </tspan>
    </text>
    <path
      style="
        opacity: 1;
        fill: #0000ff;
        fill-opacity: 0.64654;
        fill-rule: evenodd;
        stroke: #000000;
        stroke-width: 0.505872;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-opacity: 1;
      "
      d="m 59.740118,219.71002 c -8.8989,-1.57944 -19.488,-5.87554 -27.2004,-11.03541 -13.0795,-8.7507 -24.3549998,-24.21336 -28.5276998,-39.12125 -2.8809,-10.29304 -3.44539996,-23.53974 -1.4137,-33.17766 4.9708,-23.58039 20.0024998,-42.022014 42.1108998,-51.663674 2.1501,-0.93771 4.0217,-1.59261 4.159,-1.45532 0.1373,0.13728 0.6215,2.05247 1.0761,4.25598 2.8647,13.886894 11.6119,29.063444 22.0145,38.195734 7.4169,6.51112 17.3789,12.32286 25.7883,15.04466 l 3.409302,1.10344 v 7.6985 c 0,9.28644 0.8869,15.07682 3.5367,23.09005 3.1424,9.50316 7.7108,17.61176 13.851,24.58489 l 2.8004,3.1804 -3.1492,2.81779 c -9.1577,8.19373 -22.149002,14.38405 -34.679302,16.5246 -5.9926,1.02371 -17.8878,1.00233 -23.7759,-0.0427 z"
      id="path1051"
    />
    <path
      style="
        opacity: 1;
        fill: #0000ff;
        fill-opacity: 0.64654;
        fill-rule: evenodd;
        stroke: #000000;
        stroke-width: 0.505872;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-opacity: 1;
      "
      d="m 97.620818,139.03341 c -22.9109,-8.02784 -40.2974,-27.32532 -45.9947,-51.049874 -0.5336,-2.22233 -0.9719,-4.40106 -0.9738,-4.84162 0,-0.59135 1.1795,-1.08578 4.5162,-1.88892 22.202,-5.34407 45.365602,0.2182 62.568902,15.02468 l 3.6546,3.14538 -2.6289,2.866544 c -3.4587,3.77151 -8.5804,11.6213 -10.9978,16.85596 -2.3765,5.14632 -4.7675,12.56572 -5.6238,17.45157 -0.353,2.014 -0.7759,3.64489 -0.9397,3.62421 -0.1639,-0.0206 -1.775302,-0.55525 -3.581002,-1.18793 z"
      id="path1053"
    />
    <path
      style="
        opacity: 1;
        fill: #0000ff;
        fill-opacity: 0.64654;
        fill-rule: evenodd;
        stroke: #000000;
        stroke-width: 0.505872;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-opacity: 1;
      "
      d="m 142.60921,135.62344 c -2.13462,-11.0962 -8.41804,-23.92184 -16.15279,-32.97082 -1.4144,-1.65473 -2.5716,-3.169234 -2.5716,-3.365564 0,-0.55785 5.9272,-5.37296 9.9864,-8.11269 15.73833,-10.62251 36.22274,-14.55881 54.18589,-10.41242 l 2.3501,0.54247 -0.69746,3.84464 c -1.62746,8.97103 -5.44459,18.251274 -10.86137,26.406224 -3.91219,5.8898 -13.29374,15.36161 -18.8506,19.03195 -4.20501,2.77743 -13.17643,7.37133 -15.45821,7.91553 -1.26984,0.30285 -1.33766,0.20169 -1.93036,-2.87932 z"
      id="path1055"
    />
    <path
      style="
        opacity: 1;
        fill: #0000ff;
        fill-opacity: 0.64654;
        fill-rule: evenodd;
        stroke: #000000;
        stroke-width: 0.505872;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-opacity: 1;
      "
      d="m 162.01808,219.81109 c -13.18568,-2.32775 -26.41036,-8.57572 -35.58316,-16.81122 l -2.7533,-2.4719 2.9682,-3.49175 c 8.07639,-9.50085 13.22411,-20.14305 15.98111,-33.0387 0.93367,-4.36716 1.13675,-6.9255 1.15045,-14.4932 l 0.0166,-9.18989 1.28135,-0.3186 c 0.70474,-0.17523 3.21322,-1.22236 5.57441,-2.32696 20.89766,-9.77623 35.74795,-28.54514 40.31095,-50.948024 1.1169,-5.48358 0.71457,-5.28355 6.40869,-3.18618 9.29889,3.42514 17.91152,8.97161 25.30698,16.29752 5.73468,5.680754 10.11142,11.776424 13.61415,18.961014 5.53954,11.36235 7.58672,21.01219 7.12549,33.58757 -0.41097,11.20495 -2.47376,19.46549 -7.22297,28.92461 -9.88912,19.69647 -27.35203,33.19116 -49.17771,38.00273 -5.26393,1.16045 -19.63882,1.44965 -25.00128,0.50298 z"
      id="path1057"
    />
  </svg>
</template>

<script>
export default {};
</script>
