<template>
  <div>
    <b-form :id="'addScoreForm' + id" @submit="onSubmit" @reset="onCancel">
      <b-form-group>
        <b-input-group
          id="scoredDateGroup"
          prepend="Score Date*"
          description="The date the score was shot."
          :size="globalComponentSize"
        >
          <b-form-datepicker
            id="scoredDate"
            v-model="form.scoredDate"
            required
            today-button
          ></b-form-datepicker>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group
          id="classificationGroup"
          prepend="Classification*"
          :size="globalComponentSize"
        >
          <b-form-radio-group
            id="classification"
            v-model="form.classification"
            :options="classifications"
            buttons
            button-variant="outline-primary"
            name="classification-btn-outline"
            required
          ></b-form-radio-group>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group
          id="bowTypeGroup"
          prepend="Bow Type*"
          :size="globalComponentSize"
        >
          <b-form-radio-group
            id="bowType"
            v-model="form.bowType"
            :options="bowTypes"
            buttons
            button-variant="outline-primary"
            required
            :size="globalComponentSize"
          ></b-form-radio-group>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group
          id="localGroup"
          prepend="Local*"
          :size="globalComponentSize"
        >
          <b-form-radio-group
            id="local"
            v-model="form.local"
            :options="locals"
            buttons
            button-variant="outline-primary"
            name="local-btn-outline"
            required
          ></b-form-radio-group>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group
          id="distanceGroup"
          prepend="Distance*"
          append="meters"
          :size="globalComponentSize"
        >
          <b-form-radio-group
            id="distance"
            v-model.number="form.distance"
            :options="targetDistances"
            buttons
            button-variant="outline-primary"
            required
          ></b-form-radio-group>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group
          id="targetSizeGroup"
          prepend="Target Size*"
          append="cm"
          :size="globalComponentSize"
        >
          <b-form-radio-group
            id="targetSize"
            v-model.number="form.targetSize"
            :options="targetSizes"
            buttons
            button-variant="outline-primary"
            required
          ></b-form-radio-group>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group
          id="pointsGroup"
          prepend="Points*"
          :size="globalComponentSize"
        >
          <b-form-input
            id="points"
            v-model.number="form.points"
            required
            type="number"
            placeholder="Enter total points acquired"
            :autofocus="true"
            :no-wheel="true"
          ></b-form-input>
        </b-input-group>
      </b-form-group>

      <b-form-group id="countsGroupFormGroup">
        <div id="CountsGroup" class="row">
          <b-input-group
            class="col"
            id="XCountsGroup"
            prepend="X"
            :size="globalComponentSize"
          >
            <b-form-input
              id="Xs"
              v-model.number="form.xCount"
              type="number"
              placeholder="#"
              :no-wheel="true"
            ></b-form-input>
          </b-input-group>
          <b-input-group
            class="col"
            id="TenCountsGroup"
            prepend="10"
            :size="globalComponentSize"
          >
            <b-form-input
              id="Tens"
              v-model.number="form.tenCount"
              type="number"
              placeholder="#"
              :no-wheel="true"
            ></b-form-input>
          </b-input-group>
          <b-input-group
            class="col"
            id="NineCountsGroup"
            prepend="9"
            :size="globalComponentSize"
          >
            <b-form-input
              id="Nines"
              v-model.number="form.nineCount"
              type="number"
              placeholder="#"
              :no-wheel="true"
            ></b-form-input>
          </b-input-group>
        </div>
      </b-form-group>

      <hr />
      <div>* indicates required field</div>

      <div>
        <b-button
          class="float-left"
          id="btnCancel"
          type="reset"
          variant="default"
          tabindex="-1"
          :size="globalComponentSize"
          >Cancel</b-button
        >

        <b-button
          class="float-right"
          id="btnSubmitScore"
          type="submit"
          variant="primary"
          :size="globalComponentSize"
          >Save</b-button
        >
      </div>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

const defaultFormValues = {
  participant_id: '',
  bowType: null,
  classification: null,
  local: 'outdoor',
  distance: 30,
  targetSize: 122,
  scoredDate: new Date(new Date().toLocaleDateString())
    .toISOString()
    .substring(0, 10),
  points: null,
  xCount: null,
  tenCount: null,
  nineCount: null,
};
export default {
  name: 'AddScoreForm',
  data() {
    return {
      form: defaultFormValues,
      classifications: [
        { text: 'JOAD', value: 'JOAD' },
        { text: 'Adult', value: 'Adult' },
      ],
      locals: [
        { text: 'Indoor', value: 'indoor' },
        { text: 'Outdoor', value: 'outdoor' },
      ],
    };
  },
  computed: {
    ...mapGetters('pin', {
      getDistancesForClassificationLocalBowType:
        'getDistancesForClassificationLocalBowType',
      getTargetSizesForClassificationLocalBowType:
        'getTargetSizesForClassificationLocalBowType',
      getBowTypeDisplayName: 'getBowTypeDisplayName',
    }),
    ...mapGetters('sitewide', ['globalComponentSize']),
    bowTypes: {
      get() {
        let bowTypesList = [
          {
            text: this.getBowTypeDisplayName('barebow'),
            value: 'barebow',
          },
          {
            text: this.getBowTypeDisplayName('compound'),
            value: 'compound',
          },
          {
            text: this.getBowTypeDisplayName('recurve'),
            value: 'recurve',
          },
        ];
        return bowTypesList;
      },
    },
    targetDistances: function () {
      return this.getDistancesForClassificationLocalBowType(
        this.form.classification,
        this.form.local,
        this.form.bowType
      );
    },
    targetSizes: function () {
      return this.getTargetSizesForClassificationLocalBowType(
        this.form.classification,
        this.form.local,
        this.form.bowType
      );
    },
  },
  created() {
    this.$root.$emit('bv::hide::popover');

    this.form.bowType = this.bowType;
    this.form.classification = this.classification;

    console.log(
      'local ' +
        this.local +
        ' distance ' +
        this.distance +
        ' targetsize ' +
        this.targetSize
    );
    if (this.local !== undefined) {
      this.form.local = this.local;
    }
    if (this.distance !== undefined) {
      this.form.distance = this.distance;
    }
    if (this.targetSize !== undefined) {
      this.form.targetSize = this.targetSize;
    }
  },
  props: {
    id: String,
    bowType: String,
    classification: String,
    club: Object,
    modalToggleRef: Object,
    local: String,
    distance: Number,
    targetSize: Number,
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let newScore = Object.assign({}, this.form);

      if (newScore.xCount === '') {
        newScore.xCount = null;
      }
      if (newScore.tenCount === '') {
        newScore.tenCount = null;
      }
      if (newScore.nineCount === '') {
        newScore.nineCount = null;
      }

      newScore.clubId = this.club._id;

      newScore.participant_id = this.id;
      this.$emit('newscore', newScore, this.modalToggleRef);
      this.setDefaultValues();
    },
    onCancel() {
      this.$emit('cancel');
      this.setDefaultValues();
    },
    setDefaultValues() {
      this.form.bowType = this.bowType;
      this.form.classification = this.classification;
      this.form.local = 'outdoor';
      this.form.distance = 30;
      this.form.targetSize = 122;
      this.form.scoredDate = new Date(new Date().toLocaleDateString())
        .toISOString()
        .substring(0, 10);
      this.form.points = null;
      this.form.xCount = null;
      this.form.tenCount = null;
      this.form.nineCount = null;
    },
  },
};
</script>
<style scoped></style>
