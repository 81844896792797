<template>
  <div>
    <b-form :id="'passwordResetForm'" @submit="onSubmit" @reset="onCancel">
      <b-form-group
        id="usernameGroup"
        label="User Name (e-mail)*:"
        label-for="username"
        invalid-feedback="Please Enter a valid e-mail address"
      >
        <b-form-input
          id="_id"
          v-model.number="form._id"
          type="email"
          required
          placeholder="Enter your user name (e-mail)"
          :autofocus="true"
          autocomplete="off"
          :size="globalComponentSize"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="passwordGroup"
        label="Password*:"
        label-for="password"
        description="Must be a minimum of 8 characters long with one upper case, one lower case, and one number"
      >
        <b-form-input
          id="password"
          v-model.number="form.password"
          required
          type="password"
          pattern="(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9]).{8,}"
          placeholder="Enter your desired password"
          autocomplete="off"
          :size="globalComponentSize"
        >
        </b-form-input>
      </b-form-group>

      <hr />
      <div>* indicates required field</div>

      <div>
        <b-button
          class="float-left"
          id="btnCancel"
          type="reset"
          variant="default"
          tabindex="-1"
          :size="globalComponentSize"
          >Cancel</b-button
        >

        <b-button
          class="float-right"
          id="btnSubmitPasswordReset"
          type="submit"
          variant="primary"
          tabindex="-1"
          :size="globalComponentSize"
          >Reset Passwrod</b-button
        >
      </div>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
const defaultFormValues = {
  _id: null,
  password: null,
};
export default {
  name: 'PasswordResetForm',
  data() {
    return {
      form: defaultFormValues,
    };
  },
  created() {
    // TODO, could map username if user requested remember me

    this.$root.$emit('bv::hide::popover');
  },
  computed: {
    ...mapGetters('sitewide', ['globalComponentSize']),
  },
  props: {},
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let passwordResetCredentials = Object.assign({}, this.form);
      this.$emit('attemptPasswordReset', passwordResetCredentials);
      this.setDefaultValues();
    },
    onCancel() {
      this.$emit('cancel');
      this.setDefaultValues();
    },
    setDefaultValues() {
      // todo, don't reset if "remember me" selected
      this.form._id = null;
      this.form.password = null;
    },
  },
};
</script>
<style scoped></style>
