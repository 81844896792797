<template>
  <div class="score">
    All earned pins for currently visible archers.

    <b-button-group vertical>
      <b-button
        disabled
        v-for="pin in getEarnedPinsForDisplay"
        :key="pin._id"
        wordwarp="normal"
        :variant="getListItemVariantColor(pin)"
        :size="globalComponentSize"
      >
        <div class="row,col" v-if="isDate(pin)">
          <PinIcon :pinColor="pin.pinColor" :pinLevel="pin.pinLevel_id" />
          <b> {{ pin.firstName }} {{ pin.lastName }}</b> has earned a
          <b>{{ pin.pinName }}</b> pin for {{ pin.local }}
          {{ pin.classification }} {{ pin.bowType }} on
          {{ pin.scoredDate }}
        </div>
        <div v-else>
          <strong>{{ pin.scoredDate }}</strong>
        </div>
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PinIcon from '../components/PinIcon';

export default {
  name: 'EarnedPinsList',
  props: {},
  components: {
    PinIcon,
  },
  data: () => {
    return {};
  },
  methods: {
    pinLevelInfo(pinLevel) {
      let option = this.getPinLevelInfo(pinLevel);
      if (option != null) {
        return option;
      }
      return null;
    },
    getListItemVariantColor(elm) {
      return elm.type === 'date' ? 'success' : 'outline-dark';
    },
    isDate(pin) {
      return pin.type !== 'date';
    },
  },
  computed: {
    ...mapGetters('score', {
      getAllScoresSorted: 'getAllScoresSorted',
    }),
    ...mapGetters('pin', {
      getPinLevelInfo: 'getPinLevelInfo',
      getEarnedPinForParticipantAndScore: 'getEarnedPinForParticipantAndScore',
    }),
    ...mapGetters('participant', {
      getParticipantById: 'getParticipantById',
      getFilteredParticipants: 'getFilteredParticipants',
    }),
    ...mapGetters('sitewide', ['globalComponentSize']),

    getEarnedPinsForDisplay: {
      get() {
        let scores = this.getAllScoresSorted;
        let scorePinsParticipants = [];
        let visibleParticipants = this.getFilteredParticipants;

        let previousDate = null;
        for (let score of scores) {
          if (visibleParticipants.some((x) => x._id === score.participant_id)) {
            let earnedPin = this.getEarnedPinForParticipantAndScore(
              score.participant_id,
              score._id
            );
            if (earnedPin !== null) {
              if (previousDate !== score.scoredDate) {
                previousDate = score.scoredDate;
                scorePinsParticipants.push({
                  type: 'date',
                  scoredDate: score.scoredDate,
                });
              }

              let participant = this.getParticipantById(score.participant_id);
              let pinLevelInfo = this.pinLevelInfo(earnedPin.pinLevel);
              scorePinsParticipants.push({
                type: 'score',
                _id: score._id,
                pinLevel_id: pinLevelInfo.pinLevel_id,
                pinColor: pinLevelInfo.pinColor,
                pinName: pinLevelInfo.pinName,
                firstName: participant.firstName,
                lastName: participant.lastName,
                scoredDate: score.scoredDate,
                classification: score.classification,
                local: score.local,
                bowType: score.bowType,
              });
            }
          }
        }

        return scorePinsParticipants;
      },
    },
  },
};
</script>
<style scoped></style>
