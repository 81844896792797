<template>
  <div>
    <b-form
      :id="'editScoreForm' + score._id"
      @submit="onSubmit"
      @reset="onCancel"
    >
      <b-form-group>
        <b-input-group
          id="scoredDateGroup"
          prepend="Score Date*"
          description="The date the score was shot."
          :size="globalComponentSize"
        >
          <b-form-datepicker
            id="scoredDate"
            v-model="form.scoredDate"
            required
            today-button
          ></b-form-datepicker>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group
          id="classificationGroup"
          prepend="Classification*"
          :size="globalComponentSize"
        >
          <b-form-radio-group
            id="classification"
            v-model="form.classification"
            :options="classifications"
            buttons
            button-variant="outline-primary"
            name="classification-btn-outline"
            required
          ></b-form-radio-group>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group
          id="bowTypeGroup"
          prepend="Bow Type*"
          :size="globalComponentSize"
        >
          <b-form-radio-group
            id="bowType"
            v-model="form.bowType"
            :options="bowTypes"
            buttons
            button-variant="outline-primary"
            required
            :size="globalComponentSize"
          ></b-form-radio-group>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group
          id="localGroup"
          prepend="Local*"
          :size="globalComponentSize"
        >
          <b-form-radio-group
            id="local"
            v-model="form.local"
            :options="locals"
            buttons
            button-variant="outline-primary"
            name="local-btn-outline"
            required
          ></b-form-radio-group>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group
          id="distanceGroup"
          prepend="Distance*"
          append="meters"
          :size="globalComponentSize"
        >
          <b-form-radio-group
            id="distance"
            v-model.number="form.distance"
            :options="targetDistances"
            buttons
            button-variant="outline-primary"
            required
          ></b-form-radio-group>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group
          id="targetSizeGroup"
          prepend="Target Size*"
          append="cm"
          :size="globalComponentSize"
        >
          <b-form-radio-group
            id="targetSize"
            v-model.number="form.targetSize"
            :options="targetSizes"
            buttons
            button-variant="outline-primary"
            required
          ></b-form-radio-group>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group
          id="pointsGroup"
          prepend="Points*"
          :size="globalComponentSize"
        >
          <b-form-input
            id="points"
            v-model.number="form.points"
            required
            type="number"
            placeholder="Enter total points acquired"
            :autofocus="true"
            :no-wheel="true"
          ></b-form-input>
        </b-input-group>
      </b-form-group>

      <b-form-group id="countsGroupFormGroup">
        <div id="CountsGroup" class="row">
          <b-input-group
            class="col"
            id="XCountsGroup"
            prepend="X"
            :size="globalComponentSize"
          >
            <b-form-input
              id="Xs"
              v-model.number="form.xCount"
              type="number"
              placeholder="#"
              :no-wheel="true"
            ></b-form-input>
          </b-input-group>
          <b-input-group
            class="col"
            id="TenCountsGroup"
            prepend="10"
            :size="globalComponentSize"
          >
            <b-form-input
              id="Tens"
              v-model.number="form.tenCount"
              type="number"
              placeholder="#"
              :no-wheel="true"
            ></b-form-input>
          </b-input-group>
          <b-input-group
            class="col"
            id="NineCountsGroup"
            prepend="9"
            :size="globalComponentSize"
          >
            <b-form-input
              id="Nines"
              v-model.number="form.nineCount"
              type="number"
              placeholder="#"
              :no-wheel="true"
            ></b-form-input>
          </b-input-group>
        </div>
      </b-form-group>

      <hr />
      <div>* indicates required field</div>

      <div>
        <b-button
          class="float-left"
          :id="'toggleDeleteScoreButton-' + score._id"
          variant="danger"
          v-on:click="deleteScore"
          tabindex="-1"
          :size="globalComponentSize"
          >Delete</b-button
        >

        <b-button
          id="btnCancel"
          type="reset"
          variant="default"
          tabindex="-1"
          :size="globalComponentSize"
          >Cancel</b-button
        >

        <b-button
          class="float-right"
          id="btnSaveScore"
          type="submit"
          variant="primary"
          :size="globalComponentSize"
          >Save</b-button
        >
      </div>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

const defaultFormValues = {
  participant_id: '',
  bowType: null,
  classification: null,
  local: null,
  distance: null,
  targetSize: null,
  scoredDate: new Date(new Date().toLocaleDateString())
    .toISOString()
    .substring(0, 10),
  points: null,
  xCount: null,
  tenCount: null,
  nineCount: null,
};
export default {
  name: 'EditScoreForm',
  data() {
    return {
      form: defaultFormValues,
      classifications: [
        { text: 'JOAD', value: 'JOAD' },
        { text: 'Adult', value: 'Adult' },
      ],
      locals: [
        { text: 'Indoor', value: 'indoor' },
        { text: 'Outdoor', value: 'outdoor' },
      ],
      isDeleted: false,
    };
  },
  created() {
    this.$root.$emit('bv::hide::popover');

    this.isDeleted = this.score.isDeleted;

    this.form.bowType = this.score.bowType;
    this.form.classification = this.score.classification;
    this.form.local = this.score.local;
    this.form.distance = this.score.distance;
    this.form.targetSize = this.score.targetSize;
    this.form.scoredDate = this.score.scoredDate;
    this.form.points = this.score.points;
    this.form.xCount = this.score.xCount;
    this.form.tenCount = this.score.tenCount;
    this.form.nineCount = this.score.nineCount;
  },
  props: {
    score: Object,
  },
  components: {},
  computed: {
    ...mapGetters('pin', {
      getDistancesForClassificationLocalBowType:
        'getDistancesForClassificationLocalBowType',
      getTargetSizesForClassificationLocalBowType:
        'getTargetSizesForClassificationLocalBowType',
      getBowTypeDisplayName: 'getBowTypeDisplayName',
    }),
    ...mapGetters('sitewide', ['globalComponentSize']),
    bowTypes: {
      get() {
        let bowTypesList = [
          {
            text: this.getBowTypeDisplayName('barebow'),
            value: 'barebow',
          },
          {
            text: this.getBowTypeDisplayName('compound'),
            value: 'compound',
          },
          {
            text: this.getBowTypeDisplayName('recurve'),
            value: 'recurve',
          },
        ];
        return bowTypesList;
      },
    },
    targetDistances: function () {
      return this.getDistancesForClassificationLocalBowType(
        this.form.classification,
        this.form.local,
        this.form.bowType
      );
    },
    targetSizes: function () {
      return this.getTargetSizesForClassificationLocalBowType(
        this.form.classification,
        this.form.local,
        this.form.bowType
      );
    },
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let updatedScore = Object.assign({}, this.form);

      if (updatedScore.xCount === '') {
        updatedScore.xCount = null;
      }
      if (updatedScore.tenCount === '') {
        updatedScore.tenCount = null;
      }
      if (updatedScore.nineCount === '') {
        updatedScore.nineCount = null;
      }

      updatedScore.participant_id = this.score.participant_id;
      updatedScore._id = this.score._id;
      updatedScore._rev = this.score._rev;
      updatedScore.isDeleted = this.isDeleted;
      updatedScore.clubId = this.score.clubId;
      this.$emit('updateScore', updatedScore);
    },
    onCancel() {
      this.$emit('cancel');
    },
    deleteScore(evt) {
      this.isDeleted = Boolean(!this.isDeleted);
      this.onSubmit(evt);
    },
  },
};
</script>
<style scoped></style>
