var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isBracketStarted)?_c('div',{staticStyle:{"text-align":"left"}},[_vm._m(0),_c('b-form-group',{staticClass:"col",attrs:{"id":"assistantGroup","label":"Participants to include in bracket:"}},[_vm._l((_vm.participants),function(participant){return [_c('div',{key:participant._id + 'inputGrp',staticClass:"input-group"},[_c('b-form-input',{key:participant._id + 'inputForm',attrs:{"type":"email","placeholder":"Enter participant name","size":_vm.globalComponentSize},model:{value:(participant.name),callback:function ($$v) {_vm.$set(participant, "name", _vm._n($$v))},expression:"participant.name"}}),_c('b-button',{key:participant._id + 'btnremove',attrs:{"variant":"warning","tabindex":"-1","size":_vm.globalComponentSize},on:{"click":function($event){return _vm.removeParticipantButtonClick(participant._id)}}},[_vm._v("X")])],1),_c('p',{key:participant._id + 'paragraph'})]}),_c('b-button-group',{staticStyle:{"width":"100%"}},[_c('b-button',{attrs:{"variant":"outline-primary","size":_vm.globalComponentSize},on:{"click":_vm.addParticipantButtonClick}},[_vm._v("Add Archer")]),_c('b-button',{attrs:{"variant":"outline-primary","size":_vm.globalComponentSize,"tabindex":"-1"},on:{"click":_vm.clearParticipantsButtonClick}},[_vm._v("Clear All")]),(_vm.isLoggedIn)?_c('b-button',{attrs:{"variant":"outline-primary","size":_vm.globalComponentSize},on:{"click":_vm.fillWithVisibleParticipantsButtonClick}},[_vm._v("Add Visible Participants")]):_vm._e()],1)],2),_c('b-button-group',{staticClass:"col",staticStyle:{"width":"100%"}},[_c('b-button',{attrs:{"variant":"primary","size":_vm.globalComponentSize},on:{"click":_vm.startBracketButtonClick}},[_vm._v("Start Bracket")])],1)],1):_c('div',{staticClass:"col"},[(_vm.participants.length < 2)?_c('div',[_vm._v(" You need at least two participants to run a bracket ")]):_c('div',{staticClass:"row"},_vm._l((_vm.bracket
        .map(function (item) { return item.level; })
        .filter(function (value, index, self) { return self.indexOf(value) === index; })),function(level){return _c('div',{key:level,staticClass:"col border"},[_vm._v(" Round of "+_vm._s(_vm.bracket.filter(function (x) { return x.level === level; }).length * 2)+" "),_vm._l((_vm.bracket.filter(function (x) { return x.level === level; })),function(battle){return _c('b-button-group',{key:battle.challengerA._id,staticClass:"row m-2",staticStyle:{"width":"100%"},attrs:{"vertical":""}},[_c('b-button',{attrs:{"id":battle.level + '_' + battle.sequence,"variant":battle.winner !== null &&
            battle.winner.name === battle.challengerA.name
              ? 'success'
              : battle.challengerA.name === 'TBD' ||
                battle.challengerB.name === 'TBD'
              ? 'outline-info'
              : 'info',"disabled":battle.challengerA.name === 'TBD' ||
            battle.challengerB.name === 'TBD' ||
            battle.challengerA.name === 'BYE'},on:{"click":function($event){return _vm.toggleWinnerButtonClick(battle, battle.challengerA)}}},[_vm._v(" "+_vm._s(battle.challengerA.name)+" ")]),_c('b-button',{attrs:{"variant":"info","disabled":""}},[_vm._v("VS.")]),_c('b-button',{attrs:{"id":battle.level + '_' + battle.sequence,"variant":battle.winner !== null &&
            battle.winner.name === battle.challengerB.name
              ? 'success'
              : battle.challengerA.name === 'TBD' ||
                battle.challengerB.name === 'TBD'
              ? 'outline-info'
              : 'info',"disabled":battle.challengerA.name === 'TBD' ||
            battle.challengerB.name === 'TBD' ||
            battle.challengerB.name === 'BYE'},on:{"click":function($event){return _vm.toggleWinnerButtonClick(battle, battle.challengerB)}}},[_vm._v(" "+_vm._s(battle.challengerB.name)+" ")])],1)}),_c('br')],2)}),0),_c('b-button-group',{staticClass:"row m-3",staticStyle:{"width":"100%"}},[_c('b-button',{attrs:{"variant":"warning","tabindex":"-1","size":_vm.globalComponentSize},on:{"click":_vm.resetBracketButtonClick}},[_vm._v("Reset (back to participant list)")])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ol',[_c('li',[_vm._v("List everyone you want in this bracket.")]),_c('li',[_vm._v("Select 'Start Bracket' to generate bracket.")]),_c('dt',[_vm._v("Note")]),_vm._v(" Brackets are not saved and are only visible on the device you start them on (on the same tab). ")])}]

export { render, staticRenderFns }