import axios from 'axios';

export default {
  /*
  Expected Request, optional loginTokenAssembly
  `{
    "type": "object",
    "properties": {
      "loginTokenAssembly": {
        "type": "object",
        "properties": {
          "_id": {"type": "string"},
          "type": {"type": "string"},
          "expiration": {"type": "string"},
          "isAdmin": {"type": "boolean"},
          "token": {"type": "string"}
        },
        "required": [
          "_id",
          "type",
          "token",
          "isAdmin",
          "expiration"
        ]  
      }
    },
    "required": [ ]
  }`

  Expected Response
  {
    responseCode: string,
    subscriptionOptions: [
      {
        _id: string,
        displayName: string,
        displayDurationMonths: integer,
        price: integer, // USD cents
        isActive: boolean,
        subscriptionOptionsTokenAssembly: { // optional, only returned to admins
          _id: string,
          type: string,
          expiration: string, // optional date
          token: string
        }
      }
    ]
  }
  */
  getAllSubscriptionOptions: (loginTokenAssembly) =>
    new Promise((resolve, reject) => {
      let credentials = {
        loginTokenAssembly: loginTokenAssembly,
      };
      let path = '/api/subscription/option/';
      axios
        .post(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .post(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
  saveSubscriptionOption: (subscriptionOption, loginTokenAssembly) =>
    new Promise((resolve, reject) => {
      let credentials = {
        loginTokenAssembly: loginTokenAssembly,
        subscriptionOptionsToSave: [subscriptionOption],
      };
      let path = '/api/subscription/option/';
      axios
        .put(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .put(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
  generateSubscriptionCode: (subscriptionOptionId, loginTokenAssembly) =>
    new Promise((resolve, reject) => {
      let credentials = {
        loginTokenAssembly: loginTokenAssembly,
        subscriptionOptionId: subscriptionOptionId,
      };
      let path = '/api/subscription/generate/';
      axios
        .post(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .post(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
  claimSubscriptionCode: (
    subscriptionId,
    subscriptionIdType,
    loginTokenAssembly,
    clubTokenAssembly
  ) =>
    new Promise((resolve, reject) => {
      let credentials = {
        loginTokenAssembly: loginTokenAssembly,
        subscriptionId: subscriptionId,
        subscriptionIdType: subscriptionIdType,
        clubTokenAssembly: clubTokenAssembly,
      };
      let path = '/api/subscription/claim/';
      axios
        .post(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .post(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
  getLatestSubscriptionForClubId: (loginTokenAssembly, clubTokenAssembly) =>
    new Promise((resolve, reject) => {
      let credentials = {
        loginTokenAssembly: loginTokenAssembly,
        clubTokenAssembly: clubTokenAssembly,
      };
      let path = '/api/subscription/status/';
      axios
        .post(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .post(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
};
