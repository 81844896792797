<template>
  <div>
    <b-table
      striped
      hover
      :items="participantsForTableView"
      :fields="participantTableFields"
      :sort-compare="sortCompare"
      head-variant="light"
      small
      sticky-header="80vh"
      style="width: 100%"
    >
      <template #cell(buttons)="row">
        <b-button-group v-if="visibleParticipantFields['buttons']">
          <b-button
            :id="'toggleAddScoreFormButton-' + row.value.participant._id"
            variant="outline-info"
            v-on:click="toggleAddScoreForm(row.value)"
            :size="globalComponentSize"
          >
            <TargetIcon style="width: 25px" />
          </b-button>
          <b-button
            :id="'toggleEditParticipantFormButton-' + row.value.participant._id"
            variant="outline-info"
            v-on:click="toggleEditParticipantForm(row.value)"
            :size="globalComponentSize"
          >
            <PencilIcon style="width: 25px" />
          </b-button>
        </b-button-group>
        <b-modal
          :id="'bv-modal-add-score' + row.value.participant._id"
          hide-footer
          :static="true"
          v-if="row.value.showAddForm"
          v-model="row.value.showAddForm"
        >
          <template v-slot:modal-title
            >Enter a Score for
            {{
              row.value.participant.firstName +
              ' ' +
              row.value.participant.lastName
            }}</template
          >
          <!-- this could be updated to pass the participant object to the component which would make the component more dynamic to changing needs -->
          <AddScoreForm
            v-if="row.value.showAddForm"
            :id="row.value.participant._id"
            :bowType="row.value.participant.bowType"
            :classification="row.value.participant.classification"
            :club="getSelectedClub"
            :modalToggleRef="row.value"
            v-on:newscore="onNewScore"
            v-on:cancel="toggleAddScoreForm(row.value)"
          />
        </b-modal>
        <b-modal
          :id="'bv-modal-edit-participant' + row.value.participant._id"
          hide-footer
          :static="true"
          v-if="row.value.showEditParticipantForm"
          v-model="row.value.showEditParticipantForm"
        >
          <template v-slot:modal-title
            >Edit
            {{
              row.value.participant.firstName +
              ' ' +
              row.value.participant.lastName
            }}</template
          >
          <EditParticipantForm
            v-if="row.value.showEditParticipantForm"
            :participant="row.value.participant"
            :modalToggleRef="row.value"
            v-on:updateParticipant="onUpdateParticipant"
            v-on:cancel="toggleEditParticipantForm(row.value)"
          />
        </b-modal>
      </template>

      <template #cell(highestEarnedPins_indoor)="row">
        <div
          class="row"
          v-if="
            visibleParticipantFields['highestEarnedPins'] &&
            visibleParticipantFields['indoor']
          "
        >
          <div class="col" :id="'highestEarnedIndoorPin'">
            <div v-if="!areEarnedPinsLoaded">
              <b-spinner small /> Checking Pins
            </div>
            <div
              v-else-if="
                row.value !== null &&
                row.value !== undefined &&
                row.value.pinLevel !== undefined
              "
            >
              {{ row.value.pinLevel }}
              <PinIcon
                :pinColor="pinColor(row.value.pinLevel)"
                :pinLevel="row.value.pinLevel"
              />
            </div>
          </div>
        </div>
      </template>

      <template #cell(highestEarnedPins_outdoor)="row">
        <div
          class="row"
          v-if="
            visibleParticipantFields['highestEarnedPins'] &&
            visibleParticipantFields['outdoor']
          "
        >
          <div class="col" :id="'highestEarnedOutdoorPin'">
            <div v-if="!areEarnedPinsLoaded">
              <b-spinner small /> Checking Pins
            </div>
            <div
              v-else-if="
                row.value !== null &&
                row.value !== undefined &&
                row.value.pinLevel !== undefined
              "
            >
              {{ row.value.pinLevel }}
              <PinIcon
                :pinColor="pinColor(row.value.pinLevel)"
                :pinLevel="row.value.pinLevel"
              />
            </div>
          </div>
        </div>
      </template>

      <template #head(nextPin_indoor)="data">
        <table style="width: 100%">
          <th colspan="4">
            {{ data.label }}
            <b-avatar
              id="participantSortingInTableHeaderHelpIconIndoor"
              size="sm"
              icon="question"
              button
              variant="muted"
              tabindex="-1"
            />
            <b-popover
              id="participantSortingHelpInTableHeaderIconPopoverIndoor"
              target="participantSortingInTableHeaderHelpIconIndoor"
              triggers="click"
              placement="auto"
              variant="info"
            >
              <template #title> Sorting </template>
              <b-list-group style="height: auto">
                <b-list-group-item
                  >Sort will be by the option with the shortest
                  <strong>distance</strong> for that
                  participant.</b-list-group-item
                >
              </b-list-group>
            </b-popover>
          </th>
          <tr>
            <td style="width: 25%">Distance (m)</td>
            <td style="width: 25%">Target (cm)</td>
            <td style="width: 25%">Score</td>
            <td style="width: 25%">Action</td>
          </tr>
        </table>
      </template>

      <template class="p-0" #cell(nextPin_indoor)="row">
        <div v-if="visibleParticipantFields['nextPins']">
          <div v-if="!areEarnedPinsLoaded">
            <b-spinner small /> Checking Pins
          </div>

          <table v-else-if="row.value.length > 0" style="width: 100%">
            <tr v-for="(option, index) in row.value.filter(option => option.pinOption.shouldDisplayUI)" :key="index">
              <td style="width: 25%">{{ option.pinOption.distance_meters }}</td>
              <td style="width: 25%">{{ option.pinOption.targetSize_cm }}</td>
              <td style="width: 25%">{{ option.pinOption.score }}</td>
              <td style="width: 25%">
                <b-button
                  :id="'toggleAddScoreFormButton'"
                  variant="outline-info"
                  v-on:click="toggleAddScoreForm(option)"
                  :size="globalComponentSize"
                >
                  <TargetIcon style="width: 25px" />
                </b-button>
              </td>
              <b-modal
                :id="
                  'bv-modal-add-score-from-option-' +
                  option.pinOption.distance_meters +
                  '-' +
                  option.pinOption.targetSize_cm +
                  '-' +
                  option.pinOption.score +
                  '-' +
                  option.participant._id
                "
                hide-footer
                :static="true"
                v-if="option.showAddForm"
                v-model="option.showAddForm"
              >
                <template v-slot:modal-title
                  >Enter a Score for
                  {{
                    option.participant.firstName +
                    ' ' +
                    option.participant.lastName
                  }}</template
                >
                <AddScoreForm
                  v-if="option.showAddForm"
                  :id="option.participant._id"
                  :bowType="option.participant.bowType"
                  :classification="option.participant.classification"
                  :club="getSelectedClub"
                  local="indoor"
                  :distance="option.pinOption.distance_meters"
                  :targetSize="option.pinOption.targetSize_cm"
                  :modalToggleRef="option"
                  v-on:newscore="onNewScore"
                  v-on:cancel="toggleAddScoreForm(option)"
                />
              </b-modal>
            </tr>
          </table>
        </div>
      </template>

      <template #head(nextPin_outdoor)="data">
        <table style="width: 100%">
          <th colspan="4">
            {{ data.label }}
            <b-avatar
              id="participantSortingInTableHeaderHelpIconOutdoor"
              size="sm"
              icon="question"
              button
              variant="muted"
              tabindex="-1"
            />
            <b-popover
              id="participantSortingHelpInTableHeaderIconPopoverOutdoor"
              target="participantSortingInTableHeaderHelpIconOutdoor"
              triggers="click"
              placement="auto"
              variant="info"
            >
              <template #title> Sorting </template>
              <b-list-group style="height: auto">
                <b-list-group-item
                  >Sort will be by the option with the shortest
                  <strong>distance</strong> for that
                  participant.</b-list-group-item
                >
              </b-list-group>
            </b-popover>
          </th>
          <tr>
            <td style="width: 25%">Distance (m)</td>
            <td style="width: 25%">Target (cm)</td>
            <td style="width: 25%">Score</td>
            <td style="width: 25%">Action</td>
          </tr>
        </table>
      </template>

      <template class="p-0" #cell(nextPin_outdoor)="row">
        <div v-if="visibleParticipantFields['nextPins']">
          <div v-if="!areEarnedPinsLoaded">
            <b-spinner small /> Checking Pins
          </div>

          <table v-else-if="row.value.length > 0" style="width: 100%">
            <tr v-for="(option, index) in row.value.filter(option => option.pinOption.shouldDisplayUI)" :key="index">
              <td style="width: 25%">{{ option.pinOption.distance_meters }}</td>
              <td style="width: 25%">{{ option.pinOption.targetSize_cm }}</td>
              <td style="width: 25%">{{ option.pinOption.score }}</td>
              <td style="width: 25%">
                <b-button
                  :id="'toggleAddScoreFormButton'"
                  variant="outline-info"
                  v-on:click="toggleAddScoreForm(option)"
                  :size="globalComponentSize"
                >
                  <TargetIcon style="width: 25px" />
                </b-button>
              </td>
              <b-modal
                :id="
                  'bv-modal-add-score-from-option-' +
                  option.pinOption.distance_meters +
                  '-' +
                  option.pinOption.targetSize_cm +
                  '-' +
                  option.pinOption.score +
                  '-' +
                  option.participant._id
                "
                hide-footer
                :static="true"
                v-if="option.showAddForm"
                v-model="option.showAddForm"
              >
                <template v-slot:modal-title
                  >Enter a Score for
                  {{
                    option.participant.firstName +
                    ' ' +
                    option.participant.lastName
                  }}</template
                >
                <AddScoreForm
                  v-if="option.showAddForm"
                  :id="option.participant._id"
                  :bowType="option.participant.bowType"
                  :classification="option.participant.classification"
                  :club="getSelectedClub"
                  local="outdoor"
                  :distance="option.pinOption.distance_meters"
                  :targetSize="option.pinOption.targetSize_cm"
                  :modalToggleRef="option"
                  v-on:newscore="onNewScore"
                  v-on:cancel="toggleAddScoreForm(option)"
                />
              </b-modal>
            </tr>
          </table>
        </div>
      </template>

      <template #cell(scores)="row">
        <div class="row,col" v-if="visibleParticipantFields['scoreList']">
          <div v-if="!areScoresLoaded" class="score-group">
            <b-spinner small /> Loading Scores
          </div>
          <b-list-group v-else-if="row.value.length > 0" class="score-group">
            <span v-for="score in row.value" :key="score._id">
              <b-list-group-item
                v-if="
                  (score.local === 'indoor' &&
                    visibleParticipantFields['indoor']) ||
                  (score.local === 'outdoor' &&
                    visibleParticipantFields['outdoor'])
                "
              >
                <ScoreCard :score="score" />
              </b-list-group-item>
            </span>
          </b-list-group>
          <div v-else class="score-group">
            <em>There are no scores entered yet.</em>
          </div>
        </div>
      </template>

      <template #cell(hasUSAAMembership)="row">
        <div v-if="visibleParticipantFields['hasUSAAMembership']">
          <BIconCheck v-if="row.value" variant="success" />
          <BIconXCircle v-else variant="danger" />
        </div>
      </template>

      <template #cell(accessAccounts)="row">
        <div class="row,col" v-if="visibleParticipantFields['accessAccounts']">
          <span v-if="row.value.length > 0" class="accessAccounts-group">
            <span v-for="(baa, index) in row.value" :key="baa.loginId">
              {{ baa.loginId
              }}<span v-if="index != Object.keys(row.value).length - 1"
                >,
              </span>
            </span>
          </span>
          <div v-else class="accessAccounts-group">
            <em>There are no access accounts entered yet.</em>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EditParticipantForm from './EditParticipantForm';
import ScoreCard from './ScoreCard';
import AddScoreForm from './AddScoreForm';
import TargetIcon from './TargetIcon';
import PencilIcon from './PencilIcon';
import _ from 'lodash';
import PinIcon from '../components/PinIcon';
import { BIconCheck, BIconXCircle } from 'bootstrap-vue';

export default {
  name: 'ParticipantCard',
  props: {},
  components: {
    EditParticipantForm,
    ScoreCard,
    AddScoreForm,
    TargetIcon,
    PencilIcon,
    PinIcon,
    BIconCheck,
    BIconXCircle,
  },
  data: () => {
    return {
      participant: {
        note:
          'this is here while playing with setting up table so the card objects will work properly',
        firstName: 'junk',
        lastName: 'text',
      },
    };
  },
  methods: {
    ...mapActions('score', {
      saveScore: 'saveScore',
    }),
    ...mapActions('participant', {
      saveParticipant: 'saveParticipant',
    }),
    ...mapActions('sitewide', {
      toastError: 'toastError',
    }),
    ageText(birthdate) {
      if (birthdate !== null && birthdate !== undefined && birthdate !== '') {
        var dateOfBirth = new Date(birthdate);
        var dateDiff = Date.now() - dateOfBirth.getTime();
        var calculatedDate = new Date(dateDiff);
        var calculatedAge = Math.abs(calculatedDate.getUTCFullYear() - 1970);

        return calculatedAge + ' years old';
      } else {
        return '';
      }
    },
    async toggleAddScoreForm(value) {
      let club = await this.getSelectedClub;
      let isSubscriptionActive = await this.isSubscriptionActive(club._id);

      if (club && isSubscriptionActive) {
        value.showAddForm = !value.showAddForm;
      } else if (!isSubscriptionActive) {
        this.toastError(
          'You cannot add scores to participants in an incative club.'
        );
      }
    },
    async toggleEditParticipantForm(value) {
      let club = await this.getSelectedClub;
      if (club && (await this.isSubscriptionActive(club._id))) {
        value.showEditParticipantForm = !value.showEditParticipantForm;
      } else {
        this.toastError('You cannot edit participants in an incative club.');
      }
    },
    async onNewScore(score, modalToggleRef) {
      this.toggleAddScoreForm(modalToggleRef);
      let club = await this.getSelectedClub;
      score.clubId = club._id;

      this.saveScore({
        scoreToSave: score,
        loginTokenAssembly: await this.getLoginTokenAssembly,
        clubTokenAssembly: await club.clubTokenAssembly,
        subscriptionTokenAssembly: await this
          .getLatestSubscriptionTokenAssembly,
      });
    },
    async onUpdateParticipant(updatedParticipant, modalToggleRef) {
      this.toggleEditParticipantForm(modalToggleRef);
      let club = await this.getSelectedClub;
      updatedParticipant.clubId = club._id;
      this.saveParticipant({
        participantToSave: updatedParticipant,
        loginTokenAssembly: await this.getLoginTokenAssembly,
        clubTokenAssembly: await club.clubTokenAssembly,
        subscriptionTokenAssembly: await this
          .getLatestSubscriptionTokenAssembly,
      });
    },
    pinColor(pinLevel) {
      let option = this.getPinLevelInfo(pinLevel);
      if (option != null) {
        return option.pinColor;
      }
      return null;
    },
    sortCompare(
      aRow,
      bRow,
      key,
      sortDesc,
      formatter,
      compareOptions,
      compareLocale
    ) {
      let a = aRow[key];
      let b = bRow[key];
      if (
        key === 'highestEarnedPins_indoor' ||
        key === 'highestEarnedPins_outdoor'
      ) {
        a = a !== undefined && a !== null ? a.pinLevel : -1;
        b = b !== undefined && b !== null ? b.pinLevel : -1;
      } else if (key === 'nextPin_indoor' || key === 'nextPin_outdoor') {
        a =
          a !== undefined && a !== null && a.length > 0
            ? a[0].pinOption.distance_meters
            : -1;
        b =
          b !== undefined && b !== null && b.length > 0
            ? b[0].pinOption.distance_meters
            : -1;
      }

      if (
        (typeof a === 'number' && typeof b === 'number') ||
        (a instanceof Date && b instanceof Date)
      ) {
        // If both compared fields are native numbers or both are native dates
        return a < b ? -1 : a > b ? 1 : 0;
      } else {
        // Otherwise stringify the field data and use String.prototype.localeCompare
        return this.toString(a).localeCompare(
          this.toString(b),
          compareLocale,
          compareOptions
        );
      }
    },
    // Helper function to stringify the values of an Object
    toString(value) {
      if (value === null || typeof value === 'undefined') {
        return '';
      } else if (value instanceof Object) {
        return Object.keys(value)
          .sort()
          .map((key) => toString(value[key]))
          .join(' ');
      } else {
        return String(value);
      }
    },
  },
  computed: {
    ...mapGetters('participant', {
      getFilteredParticipants: 'getFilteredParticipants',
      showAddForm: 'showAddForm',
      visibleParticipantFields: 'visibleParticipantFields',
    }),
    ...mapGetters('score', {
      getScoresByParticipantId: 'getScoresByParticipantId',
      areScoresLoaded: 'areScoresLoaded',
    }),
    ...mapGetters('pin', {
      getHighestPinForParticipantBowClass:
        'getHighestPinForParticipantBowClass',
      getPinLevelInfo: 'getPinLevelInfo',
      areEarnedPinsLoaded: 'areEarnedPinsLoaded',
      getPinOptionsForLevel: 'getPinOptionsForLevel',
      getBowTypeDisplayName: 'getBowTypeDisplayName',
    }),
    ...mapGetters('login', {
      getLoginTokenAssembly: 'getLoginTokenAssembly',
    }),
    ...mapGetters('club', {
      getSelectedClub: 'getSelectedClub',
      getParticipantGroups: 'getParticipantGroups',
    }),
    ...mapGetters('subscription', {
      getLatestSubscriptionTokenAssembly: 'getLatestSubscriptionTokenAssembly',
      isSubscriptionActive: 'isSubscriptionActive',
    }),
    ...mapGetters('sitewide', ['globalComponentSize']),
    participantTableFields: {
      get() {
        let fields = [];
        fields.push({
          key: 'firstName',
          sortable: true,
          label: 'First',
          stickyColumn: true,
        });

        fields.push({
          key: 'lastName',
          sortable: true,
          label: 'Last',
        });

        if (this.visibleParticipantFields['buttons']) {
          fields.push({
            key: 'buttons',
            sortable: false,
            label: 'Actions',
          });
        }

        if (this.visibleParticipantFields['classification']) {
          fields.push({
            key: 'classification',
            sortable: true,
            label: 'Classification',
          });
        }

        if (this.visibleParticipantFields['bowType']) {
          fields.push({
            key: 'bowType',
            sortable: true,
            label: 'Bow Type',
          });
        }

        if (this.visibleParticipantFields['indoor']) {
          if (this.visibleParticipantFields['highestEarnedPins']) {
            fields.push({
              key: 'highestEarnedPins_indoor',
              sortable: true,
              'sort-compare': this.sortCompareForHighestPin,
              label: 'Earned Indoor',
            });
          }
          if (this.visibleParticipantFields['nextPins']) {
            fields.push({
              key: 'nextPin_indoor',
              sortable: true,
              label: 'Next Indoor Pin',
            });
          }
        }

        if (this.visibleParticipantFields['outdoor']) {
          if (this.visibleParticipantFields['highestEarnedPins']) {
            fields.push({
              key: 'highestEarnedPins_outdoor',
              sortable: true,
              label: 'Earned Outdoor',
            });
          }
          if (this.visibleParticipantFields['nextPins']) {
            fields.push({
              key: 'nextPin_outdoor',
              sortable: true,
              label: 'Next Outdoor Pin',
            });
          }
        }

        if (this.visibleParticipantFields['scoreList']) {
          fields.push({
            key: 'scores',
            sortable: false,
            label: 'Scores',
          });
        }

        if (this.visibleParticipantFields['emergencyContact']) {
          fields.push({
            key: 'emergencyContact',
            sortable: false,
            label: 'Emergency Contact',
          });
        }

        if (this.visibleParticipantFields['equipmentNote']) {
          fields.push({
            key: 'equipmentNote',
            sortable: false,
            label: 'Equipment Note',
          });
        }

        if (this.visibleParticipantFields['note']) {
          fields.push({
            key: 'notes',
            sortable: false,
            label: 'General Note',
          });
        }

        if (this.visibleParticipantFields['hasUSAAMembership']) {
          fields.push({
            key: 'hasUSAAMembership',
            sortable: false,
            label: 'USAA Member',
          });
        }

        if (this.visibleParticipantFields['participantGroups']) {
          fields.push({
            key: 'participantGroups',
            sortable: false,
            label: 'Groups',
          });
        }

        if (this.visibleParticipantFields['accessAccounts']) {
          fields.push({
            key: 'accessAccounts',
            sortable: false,
            label: 'Access Accounts',
          });
        }

        return fields;
      },
    },
    participantsForTableView: {
      get() {
        let filteredParticipants = this.getFilteredParticipants;
        let resultParticipants = [];
        for (let participant of filteredParticipants) {
          let classificationText =
            participant.classification +
            ' ' +
            this.ageText(participant.birthdate);
          let highestEarnedIndoor = this.getHighestPinForParticipantBowClass(
            participant._id,
            'Indoor',
            participant.bowType,
            participant.classification
          );
          let highestEarnedOutdoor = this.getHighestPinForParticipantBowClass(
            participant._id,
            'Outdoor',
            participant.bowType,
            participant.classification
          );

          let nextIndoorPin = this.getPinOptionsForLevel(
            (highestEarnedIndoor !== null ? highestEarnedIndoor.pinLevel : 0) +
              1,
            participant.bowType,
            'Indoor',
            participant.classification
          );
          let nextIndoorPinsForTable = [];
          for (let pinOption of nextIndoorPin) {
            let remappedPinOption = {
              pinOption: pinOption,
              participant: participant,
              showAddForm: false,
            };
            nextIndoorPinsForTable.push(remappedPinOption);
          }

          let nextOutdoorPin = this.getPinOptionsForLevel(
            (highestEarnedOutdoor !== null
              ? highestEarnedOutdoor.pinLevel
              : 0) + 1,
            participant.bowType,
            'Outdoor',
            participant.classification
          );
          let nextOutdoorPinsForTable = [];
          for (let pinOption of nextOutdoorPin) {
            let remappedPinOption = {
              pinOption: pinOption,
              participant: participant,
              showAddForm: false,
            };
            nextOutdoorPinsForTable.push(remappedPinOption);
          }

          let participantGroupNames = this.getParticipantGroups
            .filter((x) => participant.participantGroups.includes(x._id))
            .map((x) => x.name)
            .join(', ');
          let scores = _.orderBy(
            this.getScoresByParticipantId(participant._id),
            ['scoredDate', 'createdDateUTC'],
            ['desc', 'desc']
          );

          let bowTypeDisplay = this.getBowTypeDisplayName(participant.bowType);
          resultParticipants.push({
            firstName: participant.firstName,
            lastName: participant.lastName,
            classification: classificationText,
            bowType: bowTypeDisplay,
            buttons: {
              participant: participant,
              showAddForm: false,
              showEditParticipantForm: false,
            },
            highestEarnedPins_indoor: highestEarnedIndoor,
            highestEarnedPins_outdoor: highestEarnedOutdoor,
            nextPin_indoor: nextIndoorPinsForTable,
            nextPin_outdoor: nextOutdoorPinsForTable,
            emergencyContact: participant.emergencyContact,
            equipmentNote: participant.equipmentNote,
            notes: participant.notes,
            hasUSAAMembership: participant.hasUSAAMembership,
            participantGroups: participantGroupNames,
            scores: scores,
            accessAccounts: participant.accessAccounts,
          });
        }

        return resultParticipants;
      },
    },
  },
};
</script>
<style scoped>
.score-group {
  max-height: 225px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-color: aqua;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  min-width: 300px;
  width: 100%;
}
</style>
