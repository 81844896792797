import Vue from 'vue';
import VueRouter from 'vue-router';
import ParticipantX from '../views/ParticipantX.vue';
import AdminPortal from '../views/AdminPortal.vue';
import ConfirmCreateLogin from '../views/ConfirmCreateLogin.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: ParticipantX,
  },
  {
    path: '/participant',
    name: 'home',
    component: ParticipantX,
  },
  {
    path: '/adminPortal',
    name: 'adminPortal',
    component: AdminPortal,
  },
  {
    path: '/confirmcreatelogin',
    name: 'confirmcreatelogin',
    component: ConfirmCreateLogin,
  },
  {
    path: '/confirmresetpassword',
    name: 'confirmresetpassword',
    component: ConfirmCreateLogin,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
