<template>
  <div>
    <b-form id="editSubscriptionForm" @submit="onSubmit" @reset="onCancel">
      <b-form-group
        id="displayNameGroup"
        label="Display Name*:"
        label-for="DisplayName"
      >
        <b-form-input
          id="displayName"
          v-model="form.displayName"
          required
          placeholder="Enter Display Name"
          :autofocus="true"
          :size="globalComponentSize"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="displayDurationMonthsGroup"
        label="Display Duration Months*:"
        label-for="DisplayDurationMonths"
      >
        <b-form-input
          id="displayDurationMonths"
          v-model.number="form.displayDurationMonths"
          required
          placeholder="Enter Display Duration Months"
          :size="globalComponentSize"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="priceGroup" label="Price*:" label-for="Price">
        <b-form-input
          id="price"
          v-model.number="form.price"
          required
          placeholder="Enter Price"
          :size="globalComponentSize"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="isActiveGroup" label="Is Active*:" label-for="IsActive">
        <b-form-checkbox
          id="isActive"
          switch
          v-model="form.isActive"
          :size="globalComponentSize"
        ></b-form-checkbox>
      </b-form-group>

      <hr />
      <div>* indicates required field</div>

      <div>
        <b-button
          class="float-left"
          id="btnCancel"
          type="reset"
          variant="default"
          tabindex="-1"
          :size="globalComponentSize"
          >Cancel</b-button
        >

        <b-button
          class="float-right"
          id="btnSubmitSubscriptionOption"
          type="submit"
          variant="primary"
          :size="globalComponentSize"
          >Save</b-button
        >
      </div>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

const defaultFormValues = {
  displayName: '',
  displayDurationMonths: 1,
  price: 0,
  isActive: false,
};
export default {
  name: 'EditSubscriptionOptionForm',
  data() {
    return {
      form: defaultFormValues,
    };
  },
  computed: {
    ...mapGetters('sitewide', ['globalComponentSize']),
  },
  created() {
    this.$root.$emit('bv::hide::popover');

    this.form.displayName = this.subscriptionOption.displayName;
    this.form.displayDurationMonths = this.subscriptionOption.displayDurationMonths;
    this.form.price = this.subscriptionOption.price;
    this.form.isActive = this.subscriptionOption.isActive;
  },
  props: {
    subscriptionOption: Object,
  },
  components: {},
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let updatedSubscriptionOption = Object.assign({}, this.form);
      updatedSubscriptionOption._id = this.subscriptionOption._id; // map values not bound to the form.
      updatedSubscriptionOption._rev = this.subscriptionOption._rev;
      updatedSubscriptionOption.subscriptionOptionTokenAssembly = this.subscriptionOption.subscriptionOptionTokenAssembly;
      this.$emit('updateSubscriptionOption', updatedSubscriptionOption);
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
<style scoped></style>
