import adminapi from '../../api/admin';

const state = () => ({
  updateScoresRemoveTimeResult: { text: 'never called' },
  isupdateScoresRemoveTimeProcessing: false,
});

const getters = {
  getupdateScoresRemoveTimeResult: (state) => {
    return JSON.stringify(state.updateScoresRemoveTimeResult);
  },
  isupdateScoresRemoveTimeProcessing: (state) => {
    return state.isupdateScoresRemoveTimeProcessing;
  },
};

const actions = {
  clearAdminData: ({ commit }) => {
    commit('clearData');
  },
  updateScoresRemoveTime: async ({ commit }, loginTokenAssembly) => {
    try {
      console.time('admin.module.actions.updateScoresRemoveTime');
      commit('setupdateScoresRemoveTimeProcessing', true);
      let result = await adminapi.updateScoresRemoveTime(loginTokenAssembly);
      console.log('processing admin result', JSON.stringify(result));
      commit('setupdateScoresRemoveTimeResult', result);
      commit('setupdateScoresRemoveTimeProcessing', false);
    } catch (err) {
      commit('setupdateScoresRemoveTimeError', err);
      commit('setupdateScoresRemoveTimeProcessing', false);
    } finally {
      console.timeEnd('admin.module.actions.updateScoresRemoveTime');
    }
  },
};

const mutations = {
  clearData: (state) => {
    state.updateScoresRemoveTimeResult = { text: 'never called' };
    state.isupdateScoresRemoveTimeProcessing = false;
  },
  setupdateScoresRemoveTimeProcessing: (state, value) => {
    state.isupdateScoresRemoveTimeProcessing = value;
  },
  setupdateScoresRemoveTimeResult: (state, result) => {
    state.updateScoresRemoveTimeResult = result;
  },
  setupdateScoresRemoveTimeError: (state, err) => {
    state.error = err;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
