import scoreapi from '../../api/scoreapi';
import Vue from 'vue';
import _ from 'lodash';

// If we need to break PersistedState up more we can break up the arrays into multiple, one for each expected first character in the key (aka, partition the data).
const state = () => ({
  participantScores: {},
  scoreLoadError: null,
  scoreSummariesLoadError: null,
  areScoresLoaded: false,
  areScoreSummariesLoaded: false,
});
const getters = {
  getScoresByParticipantId: (state) => (participantId) => {
    if (participantId in state.participantScores) {
      return state.participantScores[participantId];
    } else {
      return [];
    }
  },
  areScoresLoaded: (state) => {
    return state.areScoresLoaded;
  },
  areScoreSummariesLoaded: (state) => {
    return state.areScoreSummariesLoaded;
  },
  topScoreForParticipant: (state) => (participantId, locality) => {
    if (!(participantId in state.participantScores)) {
      return null;
    }

    let initialValue = {
      points: -1,
      local: locality,
    };

    let topScore = state.participantScores[participantId].reduce(
      (result, score) => {
        if (score.local === locality && score.points >= result.points) {
          result = score;
        }
        return result;
      },
      initialValue
    );

    return topScore;
  },
  getAllScoresSorted: (state) => {
    let allScores = [];
    for (let participantScores of Object.values(state.participantScores)) {
      allScores = allScores.concat(participantScores);
    }
    allScores = _.orderBy(
      allScores,
      ['scoredDate', 'createdDateUTC'],
      ['desc', 'desc']
    );
    return allScores;
  },
};

const actions = {
  clearScoreData: ({ commit }) => {
    commit('clearData');
  },
  loadScoresForSummaries: async ({ dispatch, commit }, loadScoresRequest) => {
    try {
      console.time('score.module.actions.loadScoresForSummaries');

      let loginTokenAssembly = loadScoresRequest.loginTokenAssembly;
      let participantSummaryTokenAssemblies =
        loadScoresRequest.participantSummaryTokenAssemblies;

      commit('setScoreSummariesLoaded', false);

      let result = await scoreapi.getAllScoresForParticipantSummaries(
        loginTokenAssembly,
        participantSummaryTokenAssemblies
      );

      if (result.responseCode === 'normal') {
        if (Array.isArray(result.scores) && result.scores.length > 0) {
          dispatch('pin/loadEarnedPins', { scores: result.scores }, { root: true });
          let scoresGroupedByParticipant = _.groupBy(
            result.scores,
            (x) => x.participant_id
          );
          for (let participantKey in scoresGroupedByParticipant) {
            let scoreData = scoresGroupedByParticipant[participantKey];
            commit('setScoresForParticipant', scoreData);
          }
        } else {
          console.log('no scores to set' + JSON.stringify(result));
        }
      } else {
        console.log('There was a problem loading the scores.');
        dispatch(
          'toast',
          {
            type: 'error',
            displayText:
              'There was a problem loading the scores. Please refresh the page and log out and in.',
          },
          { root: true }
        );
      }

      commit('setScoreSummariesLoaded', true);
      
    } catch (err) {
      dispatch(
        'toast',
        {
          type: 'error',
          displayText:
            'There was a problem loading the scores. Please refresh the page and log out and in.',
        },
        { root: true }
      );
      commit('setScoreSummariesLoaded', true);
      commit('setScoreSummariesLoadError', err);
    } finally {
      console.timeEnd('score.module.actions.loadScoresForSummaries');
    }
  },

  loadScores: async ({ dispatch, commit }, loadScoresRequest) => {
    try {
      console.time('score.module.actions.loadScores');

      let loginTokenAssembly = loadScoresRequest.loginTokenAssembly;
      let clubTokenAssembly = loadScoresRequest.clubTokenAssembly;

      await commit('clearData');

      let result = await scoreapi.getAllScoresForClub(
        loginTokenAssembly,
        clubTokenAssembly
      );

      if (result.responseCode === 'normal') {
        if (Array.isArray(result.scores) && result.scores.length > 0) {
          dispatch('pin/loadEarnedPins', { scores: result.scores }, { root: true });
          let scoresGroupedByParticipant = _.groupBy(
            result.scores,
            (x) => x.participant_id
          );
          for (let participantKey in scoresGroupedByParticipant) {
            let scoreData = scoresGroupedByParticipant[participantKey];
            commit('setScoresForParticipant', scoreData);
          }
        } else {
          console.log('no scores to set' + JSON.stringify(result));
        }
      } else {
        console.log('the response was: ' + JSON.stringify(result));
        console.log('There was a problem loading the scores.');
        dispatch(
          'toast',
          {
            type: 'error',
            displayText:
              'There was a problem loading the scores. Please refresh the page and log out and in.',
          },
          { root: true }
        );
      }

      commit('setScoresLoaded', true);
    } catch (err) {
      dispatch(
        'toast',
        {
          type: 'error',
          displayText:
            'There was a problem loading the scores. Please refresh the page and log out and in.',
        },
        { root: true }
      );
      commit('setScoresLoaded', true);
      commit('setScoreLoadError', err);
    } finally {
      console.timeEnd('score.module.actions.loadScores');
    }
  },
  saveScore: async ({ dispatch, commit, state }, saveScoreRequest) => {
    try {
      console.time('score.modules.actions.saveScore');

      let score = saveScoreRequest.scoreToSave;
      let loginTokenAssembly = saveScoreRequest.loginTokenAssembly;
      let clubTokenAssembly = saveScoreRequest.clubTokenAssembly;
      let subscriptionTokenAssembly =
        saveScoreRequest.subscriptionTokenAssembly;

      dispatch(
        'toast',
        {
          type: 'info',
          displayText: `Saving score.`,
        },
        { root: true }
      );

      let results = await scoreapi.saveScore(
        score,
        loginTokenAssembly,
        clubTokenAssembly,
        subscriptionTokenAssembly
      );

      if (results.responseCode === 'normal') {
        for (let result of results.scores) {
          await commit('pushScore', result.score);

          let scoresToDispatch = [];
          if (state.participantScores[result.score.participant_id].length > 0) {
            scoresToDispatch =
              state.participantScores[result.score.participant_id];
          } else {
            // if we're down to zero it means we just deleted the last score, need to send that deleted score data on to pin so it can clear that participants earned pins.
            scoresToDispatch.push(result.score);
          }

          await dispatch(
            'pin/loadEarnedPins',
            {
              scores: scoresToDispatch,
            },
            { root: true }
          );
          dispatch(
            'pin/checkIfScoreEarnedPin',
            {
              participant_id: result.score.participant_id,
              score_id: result.score._id,
            },
            { root: true }
          );

          let alertText = '';
          let logText = '';
          if (result.responseCode == 'conflicted') {
            if (result.score.isDeleted) {
              // TODO, get the participant details so we can notify clearly of which participant had a problem with their score entry/modification
              alertText =
                'The score has been deleted by another coach and cannot be modified unless un-deleted.';
            } else {
              alertText =
                'Another coach edited the score before you. The score has been updated with the newer data. Please reapply your edits.';
            }
            logText = alertText;
          } else if (
            result.responseCode == 'conflictedError' ||
            result.responseCode == 'error'
          ) {
            alertText =
              'Error saving score. Please reload the page and make your edits again.';
            logText =
              alertText +
              ' Response Code: ' +
              result.responseCode +
              ' Error: ' +
              result.error;
          } else if (
            result.responseCode != undefined &&
            result.responseCode != 'normal'
          ) {
            alertText =
              'An unknown response was returned when saving score. Please reload the page.';
            logText = 'Unknown response code.' + JSON.stringify(result);
          }

          if (alertText !== '') {
            console.log(logText);
            dispatch(
              'toast',
              {
                type: 'error',
                displayText: alertText,
              },
              { root: true }
            );
          } else {
            dispatch(
              'toast',
              {
                type: 'success',
                displayText: `Score saved.`,
              },
              { root: true }
            );
          }
        }
      } else {
        console.log(
          'there was a problem saving the score ' + JSON.stringify(results)
        );
        dispatch(
          'toast',
          {
            type: 'error',
            displayText: `Error saving score, please try logging out and back in.`,
          },
          { root: true }
        );
      }
    } catch (err) {
      console.log('there was a problem saving the score');
      dispatch(
        'toast',
        {
          type: 'error',
          displayText: `Error saving score, please try logging out and back in.`,
        },
        { root: true }
      );
      commit('setScoreLoadError', err);
    } finally {
      console.timeEnd('score.modules.actions.saveScore');
    }
  },
};

const mutations = {
  clearData: (state) => {
    state.participantScores = {};
    state.scoreLoadError = null;
    state.scoreSummariesLoadError = null;
    state.areScoresLoaded = false;
    state.areScoreSummariesLoaded = false;
  },
  setScoresLoaded: (state, value) => {
    state.areScoresLoaded = value;
  },
  setScoreSummariesLoaded: (state, value) => {
    state.areScoreSummariesLoaded = value;
  },

  setScoresForParticipant: (state, scoreData) => {
    let idToInsert = scoreData[0].participant_id;
    Vue.set(
      state.participantScores,
      idToInsert,
      scoreData.filter((s) => !s.isDeleted)
    );
  },
  setScoreLoadError: (state, err) => {
    console.log('Error loading scores: ' + err);
    state.scoreLoadError = err;
  },
  setScoreSummariesLoadError: (state, err) => {
    console.log('Error loading score summaries: ' + err);
    state.scoreSummariesLoadError = err;
  },
  addScoreForParticipant: (state, newScore) => {
    if (newScore.participant_id in state.participantScores) {
      state.participantScores[newScore.participant_id].push(newScore);
    } else {
      Vue.set(state.participantScores, newScore.participant_id, [newScore]); // TODO: can't this just be a set, no need for the if else? https://vuejs.org/v2/api/#Vue-set
    }
  },
  pushScore: (state, score) => {
    if (score.participant_id in state.participantScores) {
      let indexOfExisting = state.participantScores[
        score.participant_id
      ].findIndex((x) => x._id === score._id);

      if (score.isDeleted && indexOfExisting != -1) {
        // if marked as deleted then we want to make sure they're not in our local list of scores.
        state.participantScores[score.participant_id].splice(
          indexOfExisting,
          1
        );
      } else if (indexOfExisting === -1) {
        state.participantScores[score.participant_id].push(score);
      } else {
        state.participantScores[score.participant_id].splice(
          indexOfExisting,
          1,
          score
        );
      }
    } else {
      Vue.set(state.participantScores, score.participant_id, [score]);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
