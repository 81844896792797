import axios from 'axios';

export default {
  loadFeedback: (loginTokenAssembly) =>
    new Promise((resolve, reject) => {
      let credentials = {
        loginTokenAssembly: loginTokenAssembly,
      };
      let path = '/api/feedback/';
      axios
        .post(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .post(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
  saveFeedback: (rating, comment, loginTokenAssembly) =>
    new Promise((resolve, reject) => {
      let credentials = {
        loginTokenAssembly: loginTokenAssembly,
        feedbackToSave: [
          {
            rating: rating,
            comment: comment,
          },
        ],
      };
      let path = '/api/feedback/';
      axios
        .put(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .put(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
};
