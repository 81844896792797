import clubapi from '../../api/club';

const state = () => ({
  clubs: [],
  clubsLoaded: false,
  isClubLoadProcessing: false,
  isClubSaveProcessing: false,
  clubsLoadError: '',
  showAddClubForm: false,
  selectedClubId: '',
});
const getters = {
  showAddClubForm: (state) => {
    return state.showAddClubForm;
  },
  getClubs: (state) => {
    return state.clubs;
  },
  getSelectedClub: (state) => {
    let indexOfExisting = state.clubs.findIndex(
      (x) => x._id === state.selectedClubId
    );

    if (indexOfExisting === -1) {
      return undefined;
    } else {
      return state.clubs[indexOfExisting];
    }
  },
  areClubsLoaded: (state) => {
    return state.clubsLoaded;
  },
  isClubLoadProcessing: (state) => {
    return state.isClubLoadProcessing;
  },
  isClubSaveProcessing: (state) => {
    return state.isClubSaveProcessing;
  },
  getParticipantGroups: (state) => {
    let indexOfExisting = state.clubs.findIndex(
      (x) => x._id === state.selectedClubId
    );

    if (indexOfExisting === -1) {
      return undefined;
    } else {
      let groups = state.clubs[indexOfExisting].participantGroups;
      if (groups === undefined) {
        groups = [];
      }
      return groups;
    }
  },
};

const actions = {
  clearClubData: ({ commit }) => {
    commit('clearData');
  },
  toggleAddClubForm: ({ commit }) => {
    commit('toggleAddClubForm');
  },
  loadClubs: async ({ commit, dispatch }, loginTokenAssembly) => {
    try {
      console.time('club.module.actions.loadClubs');
      commit('setClubLoadProcessing', true);
      let result = await clubapi.getAllClubsForLoginSession(loginTokenAssembly);
      if (result.responseCode === 'normal') {
        commit('setClubs', result.clubs);
      } else {
        dispatch(
          'toast',
          {
            type: 'error',
            displayText: `Failed to load clubs. Please refresh the page and log out and in.`,
          },
          { root: true }
        );
        commit(
          'setClubsLoadError',
          'Failed to load club data with loginTokenAssembly'
        );
      }
      commit('setClubLoadProcessing', false);
    } catch (err) {
      dispatch(
        'toast',
        {
          type: 'error',
          displayText: `Failed to load clubs. Please refresh the page and log out and in.`,
        },
        { root: true }
      );
      commit('setClubsLoadError', err);
      commit('setClubLoadProcessing', false);
    } finally {
      console.timeEnd('club.module.actions.loadClubs');
    }
  },
  saveClub: async ({ commit, dispatch }, saveClubRequest) => {
    try {
      console.time('club.module.actions.saveClub');
      commit('setClubSaveProcessing', true);
      let loginTokenAssembly = saveClubRequest.loginTokenAssembly;
      let club = saveClubRequest.club;

      let results = await clubapi.saveClub(loginTokenAssembly, club);
      if (results.responseCode === 'normal') {
        for (let result of results.clubs) {
          let alertText = '';
          let logText = '';
          if (result.responseCode === 'normal') {
            commit('pushClub', result.club);
            dispatch(
              'toast',
              {
                type: 'success',
                displayText: `${result.club.clubName} saved successfully.`,
              },
              { root: true }
            );
          } else if (result.responseCode === 'conflicted') {
            commit('pushClub', result.club);
            alertText =
              'Another coach edited the club before you. The club has been updated with the newer data. Please reapply your edits.';
            logText = alertText;
          } else if (
            result.responseCode === 'conflictedError' ||
            result.responseCode === 'error'
          ) {
            alertText =
              'Error saving club. Please reload the page and make your edits again.';
            logText =
              alertText +
              ' Response Code: ' +
              result.responseCode +
              ' Error: ' +
              result.error;
          } else if (
            result.responseCode !== undefined &&
            result.responseCode !== 'normal'
          ) {
            alertText =
              'An unknown response was returned when saving club. Please reload the page.';
            logText = 'Unknown response code.' + JSON.stringify(result);
          }

          if (alertText !== '') {
            console.log(logText);
            dispatch(
              'toast',
              { type: 'error', displayText: alertText },
              { root: true }
            );
          }
        }
      } else {
        console.log(
          'There was an error trying to save the club. Please try again. ResponseCode: ' +
            results.responseCode
        );
        dispatch(
          'toast',
          {
            type: 'error',
            displayText:
              'There was an error trying to save the club. Please try again.',
          },
          { root: true }
        );
      }
      commit('setClubSaveProcessing', false);
    } catch (err) {
      commit('setClubsLoadError', err);
      commit('setClubSaveProcessing', false);
      dispatch(
        'toast',
        {
          type: 'error',
          displayText:
            'There was an error. Please try logging out and back in.',
        },
        { root: true }
      );
    } finally {
      console.timeEnd('club.module.actions.saveClub');
    }
  },
  setSelectedClubById: ({ commit, state }, clubId) => {
    let indexOfExisting = state.clubs.findIndex((x) => x._id === clubId);

    if (indexOfExisting >= -1 || clubId === '') {
      commit('setSelectedClubById', clubId);
    } else {
      // didn't find it, yell at them
      console.log(
        'somehow we were asked to set the selected club to a club we do not have in the list of clubs'
      );
    }
  },
};

const mutations = {
  clearData: (state) => {
    state.clubs = [];
    state.clubsLoaded = false;
    state.clubsLoadError = '';
    state.showAddClubForm = false;
    state.selectedClubId = '';
    state.isClubLoadProcessing = false;
    state.isClubSaveProcessing = false;
  },
  setClubLoadProcessing: (state, value) => {
    state.isClubLoadProcessing = value;
  },
  setClubSaveProcessing: (state, value) => {
    state.isClubSaveProcessing = value;
  },
  toggleAddClubForm: (state) => {
    state.showAddClubForm = !state.showAddClubForm;
  },
  setSelectedClubById: (state, clubId) => {
    state.selectedClubId = clubId;
  },
  setClubs: (state, clubData) => {
    if (Array.isArray(clubData)) {
      state.clubs = clubData;
    } else {
      console.log('setClubs mutation payload must be an array, but it is not');
    }
    // we got in here because it was a "normal" load, so we should set the loaded flag.
    if (state.clubsLoaded === false) {
      state.clubsLoaded = true;
    }
  },
  setClubsLoadError: (state, err) => {
    console.log('Error loading clubs: ' + err);
    state.clubsLoadError = err;
  },
  pushClub: (state, clubToPush) => {
    // check for existing with same ID and replace
    let indexOfExisting = state.clubs.findIndex(
      (x) => x._id === clubToPush._id
    );

    if (indexOfExisting === -1) {
      state.clubs.push(clubToPush);
    } else {
      state.clubs.splice(indexOfExisting, 1, clubToPush);
    }
    state.selectedClubId = clubToPush._id;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
