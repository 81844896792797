<template>
  <div class="container">
    <!-- shoul display a spinner and text indicating we're confirming the login credentials until they're confirmed and then say they are done and can log in. -->
    <b-spinner v-if="!getIsConfirmed" />
    <br />
    {{ confirmingLoginMessage }}
    <svg
      v-if="getIsConfirmed"
      class="checkmark"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
    >
      <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
      <path
        class="checkmark__check"
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
      />
    </svg>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ConfirmCreateLogin',
  components: {},
  computed: {
    ...mapGetters('login', {
      confirmingLoginMessage: 'getConfirmingLoginMessage',
      getIsConfirmed: 'getIsConfirmed',
    }),
  },
  created() {
    // Get the params from the query string
    const queryString = this.$route.query;
    const urlParams = new URLSearchParams(queryString);
    const _id = urlParams.get('username');
    const verificationCode = urlParams.get('verificationcode');
    const redirectDelay = 2500;

    this.confirmCreateAccount({ _id, verificationCode, redirectDelay });
  },
  methods: {
    ...mapActions('login', {
      confirmCreateAccount: 'confirmCreateAccount',
    }),
  },
};
</script>
<style scoped>
/* https://stackoverflow.com/questions/41078478/css-animated-checkmark */
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
</style>
