<template>
  <div />
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'logout',
  data: () => {
    return {};
  },
  created() {
    this.$store.subscribeAction((action) => {
      if (action.type === 'logout') {
        // I don't think these are doing anything because their backing properties are bound to variables in various places
        // this.$root.$emit('bv::hide::popover');
        // this.$root.$emit('bv::hide::modal');
        // this.$root.$emit('bv::hide::sidebar');

        this.clearClubData();
        this.clearParticipantData();
        this.clearScoreData();
        this.clearPinData();
        this.clearSubscriptionData();
        this.clearAdminData();
      }
    });
  },
  methods: {
    ...mapActions('club', ['clearClubData']),
    ...mapActions('participant', ['clearParticipantData']),
    ...mapActions('score', ['clearScoreData']),
    ...mapActions('pin', ['clearPinData']),
    ...mapActions('subscription', ['clearSubscriptionData']),
    ...mapActions('admin', ['clearAdminData']),
  },
};
</script>
<style scoped></style>
