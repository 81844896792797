import Vue from 'vue';
import Vuex from 'vuex';
import pinmodule from './modules/pinmodule';
import participantmodule from './modules/participants';
import scoremodule from './modules/scoremodule';
import loginmodule from './modules/login';
import clubmodule from './modules/club';
import subscriptionmodule from './modules/subscription';
import sitewide from './modules/sitewide';
import feedback from './modules/feedback';
import admin from './modules/admin';
import quickBracket from './modules/quickBracket';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    pin: pinmodule,
    participant: participantmodule,
    score: scoremodule,
    login: loginmodule,
    club: clubmodule,
    subscription: subscriptionmodule,
    sitewide: sitewide,
    feedback: feedback,
    admin: admin,
    quickBracket: quickBracket,
  },
  plugins: [
    // createPersistedState({
    //   storage: window.localStorage,
    // }),
    createPersistedState({
      storage: window.localStorage,
      paths: [ 'This Purges the old vuex state if they still have it' ]
    }),
    createPersistedState({
      key: 'pin',
      storage: window.localStorage,
      paths: [
        'pin.pinGroupsLoaded',
        'pin.pinGroups',
        'pin.notes',
        'pin.pinLevels',
        'pin.pinScoreOptionsByClassificationLocalBowType',
        'pin.distancesByClassificationLocalBowType',
        'pin.targetSizesByClassificationLocalBowType',
        'pin.earnedPinsLoaded'
      ]
    }),
    createPersistedState({
      key: 'pin.earnedPins',
      storage: window.sessionStorage,
      paths: [
        'pin.earnedPins'
      ]
    }),
    createPersistedState({
      key: 'participant',
      storage: window.localStorage,
      paths: [
        'participant.participantsLoaded',
        'participant.participantSummariesLoaded',
        'participant.showAddForm',
        'participant.participantLoadError',
        'participant.participantSummariesLoadError',
        'participant.isParticipantLoadProcessing',
        'participant.isParticipantSummariesLoadProcessing',
        'participant.selectedParticipantSummaryId',
        'participant.visibleClassifications',
        'participant.visibleUSAAMemberships',
        'participant.visibleArchiveFlags',
        'participant.visibleParticipantGroups',
        'participantGroupFilterType',
        'participant.visibleTableParticipantFields',
        'participant.visibleCardParticipantFields',
        'participant.displayParticipantType'
      ]
    }),
    createPersistedState({
      key: 'participant.participants',
      storage: window.sessionStorage,
      paths: [
        'participant.participants'
      ]
    }),
    createPersistedState({
      key: 'participant.participantSummaries',
      storage: window.sessionStorage,
      paths: [
        'participant.participantSummaries'
      ]
    }),
    createPersistedState({
      key: 'participant.filteredParticipants',
      storage: window.sessionStorage,
      paths: [
        'participant.filteredParticipants'
      ]
    }),
    createPersistedState({
      key: 'score',
      storage: window.sessionStorage,
      paths: [
        'score'
      ]
    }),
    createPersistedState({
      key: 'login',
      storage: window.localStorage,
      paths: [
        'login'
      ]
    }),
    createPersistedState({
      key: 'club',
      storage: window.localStorage,
      paths: [
        'club'
      ]
    }),
    createPersistedState({
      key: 'subscription',
      storage: window.sessionStorage,
      paths: [
        'subscription'
      ]
    }),
    createPersistedState({
      key: 'sitewide',
      storage: window.localStorage,
      paths: [
        'sitewide'
      ]
    }),
    createPersistedState({
      key: 'feedback',
      storage: window.sessionStorage,
      paths: [
        'feedback'
      ]
    }),
    createPersistedState({
      key: 'admin',
      storage: window.localStorage,
      paths: [
        'admin'
      ]
    }),
    createPersistedState({
      key: 'quickBracket',
      storage: window.sessionStorage,
      paths: [
        'quickBracket'
      ]
    })
  ],
});
