<template>
  <div class="participant">
    <b-card
      :id="
        'participant-card-' + participant.firstName + '-' + participant.lastName
      "
    >
      <b-card-title :id="'participant-card-title' + participant.firstName._id">
        {{ participantName }}
      </b-card-title>
      <b-button-group v-if="visibleParticipantFields['buttons']">
        <b-button
          :id="'toggleAddScoreFormButton-' + participant._id"
          variant="outline-info"
          v-on:click="toggleAddScoreForm"
          :size="globalComponentSize"
        >
          <TargetIcon style="width: 25px" /> Add Score
        </b-button>
        <b-button
          :id="'toggleEditParticipantFormButton-' + participant._id"
          variant="outline-info"
          v-on:click="toggleEditParticipantForm"
          :size="globalComponentSize"
        >
          <PencilIcon style="width: 25px" /> Edit Participant
        </b-button>
      </b-button-group>
      <b-card-text>
        <br />
        <div
          v-if="visibleParticipantFields['classification']"
          class="row,col"
          :id="'classification-' + participant._id"
        >
          <label>Classification:</label>
          {{ participant.classification }} {{ ageText }}
        </div>

        <div
          v-if="visibleParticipantFields['bowType']"
          class="row,col"
          :id="'bowType-' + participant._id"
        >
          <label>Bow Type:</label>
          {{ this.getBowTypeDisplayName(participant.bowType) }}
        </div>

        <div class="row" v-if="visibleParticipantFields['highestEarnedPins']">
          <div
            class="col"
            :id="'highestEarnedIndoorPin-' + participant._id"
            v-if="this.visibleParticipantFields['indoor']"
          >
            <label>Earned Indoor:</label>
            <br />
            <div v-if="!areEarnedPinsLoaded">
              <b-spinner small /> Checking Pins
            </div>
            <PinIcon
              v-else-if="indoorPin"
              :pinColor="pinColor(indoorPin)"
              :pinLevel="indoorPin"
            />
            {{ indoorPin }}
          </div>
          <div
            class="col"
            :id="'bestOutdoor-' + participant._id"
            v-if="this.visibleParticipantFields['outdoor']"
          >
            <label>Earned Outdoor:</label>
            <br />
            <div v-if="!areEarnedPinsLoaded">
              <b-spinner small /> Checking Pins
            </div>
            <PinIcon
              v-else-if="outdoorPin"
              :pinColor="pinColor(outdoorPin)"
              :pinLevel="outdoorPin"
            />
            {{ outdoorPin }}
          </div>
        </div>
        <div
          class="row"
          style="padding: 0px 0px 5px 0px"
          v-if="visibleParticipantFields['nextPins']"
        >
          <div
            class="col"
            style="padding: 0px 1px 0px 0px"
            :id="'nextIndoorPin-' + participant._id"
            v-if="this.visibleParticipantFields['indoor']"
          >
            <label>Next Indoor Pin:</label>
            <div v-if="!areEarnedPinsLoaded">
              <b-spinner small /> Checking Pins
            </div>

            <b-table
              v-else-if="nextIndoorPinOptions.filter(opt => opt.option.shouldDisplayUI).length > 0"
              :items="nextIndoorPinOptions.filter(opt => opt.option.shouldDisplayUI)"
              striped
              small
              :fields="[
                { key: 'option.distance_meters', label: 'Dist (m)' },
                { key: 'option.targetSize_cm', label: 'Target (cm)' },
                { key: 'option.score', label: 'Score' },
                { key: 'pinOption', label: 'Add' },
              ]"
            >
              <template #cell(pinOption)="indoorOption">
                <b-button
                  :id="'toggleAddScoreFormButton'"
                  variant="outline-info"
                  v-on:click="toggleAddScoreForm(indoorOption.item)"
                  :size="globalComponentSize"
                >
                  <TargetIcon style="width: 25px" />
                </b-button>

                <b-modal
                  :id="
                    'bv-modal-add-score-from-option-indoor-' +
                    indoorOption.item.option.distance_meters +
                    '-' +
                    indoorOption.item.option.targetSize_cm +
                    '-' +
                    indoorOption.item.option.score +
                    '-' +
                    indoorOption.item.participant._id
                  "
                  hide-footer
                  :static="true"
                  v-if="indoorOption.item.showAddForm"
                  v-model="indoorOption.item.showAddForm"
                >
                  <template v-slot:modal-title
                    >Enter a Score for
                    {{
                      indoorOption.item.participant.firstName +
                      ' ' +
                      indoorOption.item.participant.lastName
                    }}</template
                  >
                  <AddScoreForm
                    v-if="indoorOption.item.showAddForm"
                    :id="indoorOption.item.participant._id"
                    :bowType="indoorOption.item.participant.bowType"
                    :classification="
                      indoorOption.item.participant.classification
                    "
                    :club="getSelectedClub"
                    local="indoor"
                    :distance="indoorOption.item.option.distance_meters"
                    :targetSize="indoorOption.item.option.targetSize_cm"
                    :modalToggleRef="indoorOption.item"
                    v-on:newscore="onNewScore"
                    v-on:cancel="toggleAddScoreForm(indoorOption.item)"
                  />
                </b-modal>
              </template>
            </b-table>
          </div>

          <div
            class="col"
            style="padding: 0px 0px 0px 1px"
            :id="'nextOutdoorPin-' + participant._id"
            v-if="this.visibleParticipantFields['outdoor']"
          >
            <label>Next Outdoor Pin:</label>
            <div v-if="!areEarnedPinsLoaded">
              <b-spinner small /> Checking Pins
            </div>

            <b-table
              v-else-if="nextOutdoorPinOptions.filter(opt => opt.option.shouldDisplayUI).length > 0"
              :items="nextOutdoorPinOptions.filter(opt => opt.option.shouldDisplayUI)"
              striped
              small
              :fields="[
                { key: 'option.distance_meters', label: 'Dist (m)' },
                { key: 'option.targetSize_cm', label: 'Target (cm)' },
                { key: 'option.score', label: 'Score' },
                { key: 'pinOption', label: 'Add' },
              ]"
            >
              <template #cell(pinOption)="outdoorOption">
                <b-button
                  :id="'toggleAddScoreFormButton'"
                  variant="outline-info"
                  v-on:click="toggleAddScoreForm(outdoorOption.item)"
                  :size="globalComponentSize"
                >
                  <TargetIcon style="width: 25px" />
                </b-button>

                <b-modal
                  :id="
                    'bv-modal-add-score-from-option-outdoor-' +
                    outdoorOption.item.option.distance_meters +
                    '-' +
                    outdoorOption.item.option.targetSize_cm +
                    '-' +
                    outdoorOption.item.option.score +
                    '-' +
                    outdoorOption.item.participant._id
                  "
                  hide-footer
                  :static="true"
                  v-if="outdoorOption.item.showAddForm"
                  v-model="outdoorOption.item.showAddForm"
                >
                  <template v-slot:modal-title
                    >Enter a Score for
                    {{
                      outdoorOption.item.participant.firstName +
                      ' ' +
                      outdoorOption.item.participant.lastName
                    }}</template
                  >
                  <AddScoreForm
                    v-if="outdoorOption.item.showAddForm"
                    :id="outdoorOption.item.participant._id"
                    :bowType="outdoorOption.item.participant.bowType"
                    :classification="
                      outdoorOption.item.participant.classification
                    "
                    :club="getSelectedClub"
                    local="outdoor"
                    :distance="outdoorOption.item.option.distance_meters"
                    :targetSize="outdoorOption.item.option.targetSize_cm"
                    :modalToggleRef="outdoorOption.item"
                    v-on:newscore="onNewScore"
                    v-on:cancel="toggleAddScoreForm(outdoorOption.item)"
                  />
                </b-modal>
              </template>
            </b-table>
          </div>
        </div>

        <div class="row,col" v-if="visibleParticipantFields['scoreList']">
          <div v-if="!areScoresLoaded" class="score-group">
            <b-spinner small /> Loading Scores
          </div>
          <b-list-group
            v-else-if="participantScores.length > 0"
            class="score-group"
          >
            <b-list-group-item
              v-for="score in participantScores"
              :key="score._id"
            >
              <ScoreCard
                v-if="
                  (score.local === 'indoor' &&
                    visibleParticipantFields['indoor']) ||
                  (score.local === 'outdoor' &&
                    visibleParticipantFields['outdoor'])
                "
                :score="score"
              />
            </b-list-group-item>
          </b-list-group>
          <div v-else class="score-group">
            <em>There are no scores entered yet.</em>
          </div>
        </div>
        <br />

        <div
          v-if="visibleParticipantFields['emergencyContact']"
          class="row"
          :id="'notes-' + participant._id"
        >
          <label>Emergency Contact:</label>
          <div
            style="margin-left: 10px; text-align: left; white-space: pre-line"
          >
            {{ participant.emergencyContact }}
          </div>
        </div>

        <div
          v-if="visibleParticipantFields['equipmentNote']"
          class="row"
          :id="'notes-' + participant._id"
        >
          <label>Equipment Note:</label>
          <div
            style="margin-left: 10px; text-align: left; white-space: pre-line"
          >
            {{ participant.equipmentNote }}
          </div>
        </div>

        <div
          v-if="visibleParticipantFields['note']"
          class="row"
          :id="'notes-' + participant._id"
        >
          <label>General Notes:</label>
          <div
            style="margin-left: 10px; text-align: left; white-space: pre-line"
          >
            {{ participant.notes }}
          </div>
        </div>

        <div
          v-if="visibleParticipantFields['hasUSAAMembership']"
          class="row"
          :id="'hasUSAAMembership-' + participant._id"
        >
          <label>USAA Memberships:</label>
          <div
            style="margin-left: 10px; text-align: left; white-space: pre-line"
          >
            <BIconCheck
              v-if="participant.hasUSAAMembership"
              variant="success"
            />
            <BIconXCircle v-else variant="danger" />
          </div>
        </div>

        <div
          v-if="
            visibleParticipantFields['participantGroups'] &&
            participant.participantGroups !== undefined
          "
          class="row,col"
          :id="'groups-' + participant._id"
        >
          <hr />
          <label>Group(s):</label>
          <div
            v-for="participantGroup in getParticipantGroups"
            :key="participantGroup._id"
          >
            <div
              :key="participantGroup._id"
              v-if="
                participant.participantGroups.indexOf(participantGroup._id) > -1
              "
            >
              {{ participantGroup.name }}
            </div>
          </div>
        </div>

        <div
          v-if="
            visibleParticipantFields['accessAccounts'] &&
            participant.accessAccounts !== undefined
          "
          class="row,col"
          :id="'accessAccounts-' + participant._id"
        >
          <hr />
          <label>Access Account(s):</label>
          <li
            style="list-style-type: none"
            v-for="accessAccount in participant.accessAccounts"
            :key="accessAccount.loginId"
          >
            {{ accessAccount.loginId }}
          </li>
        </div>
      </b-card-text>
    </b-card>
    <b-modal
      :id="'bv-modal-add-score' + participant._id"
      hide-footer
      :static="true"
      v-if="showAddForm"
      v-model="showAddForm"
    >
      <template v-slot:modal-title
        >Enter a Score for
        {{ participant.firstName + ' ' + participant.lastName }}</template
      >
      <AddScoreForm
        v-if="showAddForm"
        :id="participant._id"
        :bowType="participant.bowType"
        :classification="participant.classification"
        :club="getSelectedClub"
        v-on:newscore="onNewScore"
        v-on:cancel="toggleAddScoreForm()"
      />
    </b-modal>
    <b-modal
      :id="'bv-modal-edit-participant' + participant._id"
      hide-footer
      :static="true"
      v-if="showEditParticipantForm"
      v-model="showEditParticipantForm"
    >
      <template v-slot:modal-title
        >Edit {{ participant.firstName + ' ' + participant.lastName }}</template
      >
      <EditParticipantForm
        v-if="showEditParticipantForm"
        :participant="participant"
        v-on:updateParticipant="onUpdateParticipant"
        v-on:cancel="toggleEditParticipantForm()"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EditParticipantForm from './EditParticipantForm';
import ScoreCard from './ScoreCard';
import AddScoreForm from './AddScoreForm';
import TargetIcon from './TargetIcon';
import PencilIcon from './PencilIcon';
import _ from 'lodash';
import PinIcon from '../components/PinIcon';
import { BIconCheck, BIconXCircle } from 'bootstrap-vue';

export default {
  name: 'ParticipantCard',
  props: {
    participant: Object,
  },
  components: {
    EditParticipantForm,
    ScoreCard,
    AddScoreForm,
    TargetIcon,
    PencilIcon,
    PinIcon,
    BIconCheck,
    BIconXCircle,
  },
  data: () => {
    return {
      showAddForm: false,
      showEditParticipantForm: false,
      nextIndoorPinOptionsStatic: new Map(),
      nextOutdoorPinOptionsStatic: new Map(),
    };
  },
  methods: {
    ...mapActions('score', {
      saveScore: 'saveScore',
    }),
    ...mapActions('participant', {
      saveParticipant: 'saveParticipant',
    }),
    ...mapActions('sitewide', {
      toastError: 'toastError',
    }),
    async toggleAddScoreForm(value) {
      let club = await this.getSelectedClub;
      let isSubscriptionActive = await this.isSubscriptionActive(club._id);

      if (club && isSubscriptionActive) {
        if (value === undefined || value.showAddForm === undefined) {
          this.showAddForm = !this.showAddForm;
        } else {
          value.showAddForm = !value.showAddForm;
        }
      } else if (!isSubscriptionActive) {
        this.toastError(
          'You cannot add scores to participants in an incative club.'
        );
      }
    },

    async toggleEditParticipantForm() {
      let club = await this.getSelectedClub;
      if (club && (await this.isSubscriptionActive(club._id))) {
        this.showEditParticipantForm = !this.showEditParticipantForm;
      } else {
        this.toastError('You cannot edit participants in an incative club.');
      }
    },
    async onNewScore(score, modalToggleRef) {
      this.toggleAddScoreForm(modalToggleRef);
      let club = await this.getSelectedClub;
      score.clubId = club._id;

      this.saveScore({
        scoreToSave: score,
        loginTokenAssembly: await this.getLoginTokenAssembly,
        clubTokenAssembly: await club.clubTokenAssembly,
        subscriptionTokenAssembly: await this
          .getLatestSubscriptionTokenAssembly,
      });
    },
    async onUpdateParticipant(updatedParticipant) {
      this.toggleEditParticipantForm();
      let club = await this.getSelectedClub;
      updatedParticipant.clubId = club._id;
      this.saveParticipant({
        participantToSave: updatedParticipant,
        loginTokenAssembly: await this.getLoginTokenAssembly,
        clubTokenAssembly: await club.clubTokenAssembly,
        subscriptionTokenAssembly: await this
          .getLatestSubscriptionTokenAssembly,
      });
      // TODO, if participant has this login as a access account then refresh the participant summaries list
    },
    pinColor(pinLevel) {
      let option = this.getPinLevelInfo(pinLevel);
      if (option != null) {
        return option.pinColor;
      }
      return null;
    },
  },
  computed: {
    ...mapGetters('participant', {
      visibleParticipantFields: 'visibleParticipantFields',
    }),
    ...mapGetters('score', {
      getScoresByParticipantId: 'getScoresByParticipantId',
      areScoresLoaded: 'areScoresLoaded',
    }),
    ...mapGetters('pin', {
      getHighestPinForParticipantBowClass:
        'getHighestPinForParticipantBowClass',
      getPinLevelInfo: 'getPinLevelInfo',
      getEarnedPinForParticipantAndScore: 'getEarnedPinForParticipantAndScore',
      areEarnedPinsLoaded: 'areEarnedPinsLoaded',
      getPinOptionsForLevel: 'getPinOptionsForLevel',
      getBowTypeDisplayName: 'getBowTypeDisplayName',
    }),
    ...mapGetters('login', {
      getLoginTokenAssembly: 'getLoginTokenAssembly',
    }),
    ...mapGetters('club', {
      getSelectedClub: 'getSelectedClub',
      getParticipantGroups: 'getParticipantGroups',
    }),
    ...mapGetters('subscription', {
      getLatestSubscriptionTokenAssembly: 'getLatestSubscriptionTokenAssembly',
      isSubscriptionActive: 'isSubscriptionActive',
    }),
    ...mapGetters('sitewide', ['globalComponentSize']),
    ageText: function () {
      if (
        this.participant.birthdate !== null &&
        this.participant.birthdate !== undefined &&
        this.participant.birthdate !== ''
      ) {
        var dateOfBirth = new Date(this.participant.birthdate);
        var dateDiff = Date.now() - dateOfBirth.getTime();
        var calculatedDate = new Date(dateDiff);
        var calculatedAge = Math.abs(calculatedDate.getUTCFullYear() - 1970);

        return calculatedAge + ' years old';
      } else {
        return '';
      }
    },
    indoorPin: function () {
      let topPin = this.getHighestPinForParticipantBowClass(
        this.participant._id,
        'Indoor',
        this.participant.bowType,
        this.participant.classification
      );
      if (topPin != null) {
        return topPin.pinLevel;
      }
      return null;
    },
    nextIndoorPinOptions: {
      get() {
        let pinOptions = this.getPinOptionsForLevel(
          this.indoorPin + 1,
          this.participant.bowType,
          'Indoor',
          this.participant.classification
        );
        let staticKey =
          'pinNum:' +
          this.indoorPin +
          1 +
          ':bowtype:' +
          this.participant.bowType +
          ':Indoor:classification:' +
          this.participant.classification;
        let pinOptionsStatic = this.nextIndoorPinOptionsStatic.get(staticKey);
        if (pinOptionsStatic === undefined) {
          let remappedPinsOptions = [];
          for (let option of pinOptions) {
            let remappedOption = {
              option: option,
              showAddForm: false,
              participant: this.participant,
            };
            remappedPinsOptions.push(remappedOption);
          }
          this.nextIndoorPinOptionsStatic.set(staticKey, remappedPinsOptions);
          pinOptionsStatic = remappedPinsOptions;
        }

        return pinOptionsStatic;
      },
    },
    outdoorPin: function () {
      let topPin = this.getHighestPinForParticipantBowClass(
        this.participant._id,
        'Outdoor',
        this.participant.bowType,
        this.participant.classification
      );
      if (topPin != null) {
        return topPin.pinLevel;
      }
      return null;
    },
    nextOutdoorPinOptions: {
      get() {
        let pinOptions = this.getPinOptionsForLevel(
          this.outdoorPin + 1,
          this.participant.bowType,
          'Outdoor',
          this.participant.classification
        );
        let staticKey =
          'pinNum:' +
          this.outdoorPin +
          1 +
          ':bowtype:' +
          this.participant.bowType +
          ':Outdoor:classification:' +
          this.participant.classification;
        let pinOptionsStatic = this.nextOutdoorPinOptionsStatic.get(staticKey);
        if (pinOptionsStatic === undefined) {
          let remappedPinsOptions = [];
          for (let option of pinOptions) {
            let remappedOption = {
              option: option,
              showAddForm: false,
              participant: this.participant,
            };
            remappedPinsOptions.push(remappedOption);
          }
          this.nextOutdoorPinOptionsStatic.set(staticKey, remappedPinsOptions);
          pinOptionsStatic = remappedPinsOptions;
        }

        return pinOptionsStatic;
      },
    },
    participantScores: function () {
      let scores = this.getScoresByParticipantId(this.participant._id);
      return _.orderBy(
        scores,
        ['scoredDate', 'createdDateUTC'],
        ['desc', 'desc']
      );
    },
    participantName: function () {
      let name = this.participant.firstName + ' ' + this.participant.lastName;
      if (this.participant.isArchived == true) {
        name = name + ' (Archived)';
      }
      return name;
    },
  },
};
</script>
<style scoped>
.score-group {
  max-height: 225px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-color: aqua;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  min-width: 300px;
  width: 100%;
}
</style>
