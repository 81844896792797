<template>
  <!-- sourced from https://www.svgrepo.com/svg/13972/target -->
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 510.998 510.998"
    style="enable-background: new 0 0 510.998 510.998"
    xml:space="preserve"
  >
    <path
      d="M494.742,44.374l-28.118-28.118C456.142,5.773,442.205,0,427.38,0s-28.762,5.773-39.245,16.256L57.412,346.98
	c-5.537,5.537-9.767,12.051-12.572,19.362L1.517,479.247c-0.012,0.031-0.024,0.063-0.036,0.094
	c-3.206,8.703-1.124,18.183,5.434,24.741c4.522,4.522,10.432,6.916,16.52,6.916c2.741,0,5.52-0.486,8.222-1.481
	c0.031-0.012,0.063-0.023,0.094-0.036l112.905-43.323c7.311-2.805,13.825-7.035,19.362-12.572l330.724-330.724
	c10.483-10.483,16.256-24.42,16.256-39.245S505.225,54.856,494.742,44.374z M375.498,50.106L392.892,67.5L83.498,376.894
	l-17.323-17.323c0.593-0.677,1.201-1.343,1.843-1.984L375.498,50.106z M94.104,387.5L403.498,78.106l29.394,29.394L123.498,416.894
	L94.104,387.5z M58.362,372.971l79.665,79.665l-54.048,20.739l-46.355-46.355L58.362,372.971z M26.431,495.457
	c-3.184,1.155-6.51,0.417-8.908-1.981c-2.398-2.397-3.137-5.725-1.981-8.908l16.2-42.219l36.908,36.907L26.431,495.457z
	 M153.412,442.98c-0.641,0.641-1.307,1.25-1.984,1.843L134.104,427.5l309.394-309.394l17.394,17.394L153.412,442.98z
	 M484.136,112.256l-12.638,12.638L386.104,39.5l12.638-12.638C406.392,19.213,416.562,15,427.38,15s20.988,4.213,28.638,11.862
	l28.118,28.118c7.649,7.649,11.862,17.82,11.862,28.638S491.785,104.606,484.136,112.256z"
    />
  </svg>
</template>

<script>
export default {};
</script>
