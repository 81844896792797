<template>
  <svg width="244.93544" height="234.44653" viewBox="0 0 250 250">
    <defs id="defs1014" />
    <sodipodi:namedview
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1"
      objecttolerance="10"
      gridtolerance="10"
      guidetolerance="10"
      inkscape:pageopacity="0"
      inkscape:pageshadow="2"
      inkscape:window-width="640"
      inkscape:window-height="480"
      id="namedview1012"
    />
    <inkscape:clipboard
      style="
        font-variation-settings: normal;
        opacity: 1;
        vector-effect: none;
        fill: #ff0000;
        fill-opacity: 0.00581695;
        stroke: #000000;
        stroke-width: 1.0015748;
        stroke-linecap: butt;
        stroke-linejoin: miter;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-dashoffset: 0;
        stroke-opacity: 1;
        stop-color: #000000;
        stop-opacity: 1;
      "
      min="41.07786,16.950324"
      max="286.01329,251.39686"
    />
    <ellipse
      style="
        opacity: 1;
        fill: #ff0000;
        fill-opacity: 0.00581695;
        fill-rule: evenodd;
        stroke: #000000;
        stroke-width: 1.00157;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-opacity: 1;
      "
      id="path10-3-5"
      cx="120.38842"
      cy="72.047249"
      rx="71.374512"
      ry="71.546455"
    />
    <ellipse
      style="
        opacity: 1;
        fill: #ff0000;
        fill-opacity: 0.00581695;
        fill-rule: evenodd;
        stroke: #000000;
        stroke-width: 1.00157;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-opacity: 1;
      "
      id="path10-3-5-7"
      cx="71.875298"
      cy="149.98088"
      rx="71.374512"
      ry="71.546455"
    />
    <ellipse
      style="
        opacity: 1;
        fill: #ff0000;
        fill-opacity: 0.00581695;
        fill-rule: evenodd;
        stroke: #000000;
        stroke-width: 1.00157;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-opacity: 1;
      "
      id="path10-3-5-3"
      cx="173.06013"
      cy="149.90031"
      rx="71.374512"
      ry="71.546455"
    />
    <path
      style="
        opacity: 1;
        fill: #0000ff;
        fill-opacity: 0.64654;
        fill-rule: evenodd;
        stroke: #000000;
        stroke-width: 0.357705;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-opacity: 1;
      "
      d="m 120.51464,197.14072 c -11.97592,-12.93541 -18.93475,-32.31638 -18.04006,-50.24314 0.12661,-2.53976 0.26834,-4.65337 0.31521,-4.69691 0.0465,-0.0435 1.40447,0.20855 3.01757,0.5602 9.92376,2.16328 22.76644,1.81722 32.87989,-0.88599 1.71368,-0.45804 3.17322,-0.77596 3.24352,-0.70649 0.4532,0.44799 0.72253,9.20246 0.4117,13.38266 -0.77907,10.47796 -3.06871,18.83188 -7.55543,27.56659 -2.98038,5.80223 -8.05383,13.04202 -11.28945,16.11 l -1.05256,0.99802 z"
      id="path962"
    />
    <path
      style="
        opacity: 1;
        fill: #0000ff;
        fill-opacity: 0.64654;
        fill-rule: evenodd;
        stroke: #000000;
        stroke-width: 0.357705;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-opacity: 1;
      "
      d="m 111.62428,142.39682 c -3.63998,-0.50127 -8.00299,-1.43554 -8.36719,-1.79171 -0.51855,-0.50715 1.05706,-7.78927 2.84444,-13.14626 3.17676,-9.5212 9.47013,-20.11025 15.27964,-25.70916 l 1.23912,-1.19421 2.56018,2.97992 c 3.58654,4.1746 7.92793,10.75543 10.22868,15.50497 3.0093,6.2122 6.75103,18.59909 6.11721,20.25081 -0.20334,0.53018 -4.22729,1.6682 -9.36635,2.64899 -3.36461,0.64215 -16.98879,0.94511 -20.53573,0.45665 z"
      id="path964"
    />
    <text
      xml:space="preserve"
      style="
        font-style: normal;
        font-weight: normal;
        font-size: 39.9999px;
        line-height: 1.25;
        font-family: sans-serif;
        fill: #000000;
        fill-opacity: 1;
        stroke: none;
        stroke-width: 0.999999;
      "
      x="78.987976"
      y="234.44653"
      id="text968"
    >
      <tspan
        sodipodi:role="line"
        x="78.987976"
        y="234.44653"
        style="stroke-width: 0.999999"
        id="tspan970"
      >
        AND
      </tspan>
    </text>
  </svg>
</template>

<script>
export default {};
</script>
