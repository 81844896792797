import axios from 'axios';

export default {
  getAllParticipantsForClub: (loginTokenAssembly, clubTokenAssembly) =>
    new Promise((resolve, reject) => {
      let credentials = {
        loginTokenAssembly: loginTokenAssembly,
        clubTokenAssembly: clubTokenAssembly,
      };
      let path = '/api/participant/';
      axios
        .post(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .post(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
  getParticipantSummariesForLogin: (loginTokenAssembly) =>
    new Promise((resolve, reject) => {
      let credentials = {
        loginTokenAssembly: loginTokenAssembly,
      };
      let path = '/api/participant/summary/';
      axios
        .post(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .post(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
  saveParticipant: (
    participant,
    loginTokenAssembly,
    clubTokenAssembly,
    subscriptionTokenAssembly
  ) =>
    new Promise((resolve, reject) => {
      let credentials = {
        loginTokenAssembly: loginTokenAssembly,
        subscriptionTokenAssembly: subscriptionTokenAssembly,
        clubTokenAssembly: clubTokenAssembly,
        participantsToSave: [participant],
      };
      let path = '/api/participant/';
      axios
        .put(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .put(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
};
