<template>
  <div id="app" v-on:click="globalHidePopups">
    <b-navbar style="justify-content: center" sticky variant="dark">
      <div class="container">
        <b-navbar-brand :size="globalComponentSize" :to="{ name: 'home' }">
          <img
            class="d-none d-sm-block"
            style="height: 40px"
            :src="require('./assets/ArcheryCoachNotes_Logo_Rectangle_Long.svg')"
            :alt="pageName"
          />
          <img
            class="d-block d-sm-none"
            style="height: 40px"
            :src="
              require('./assets/ArcheryCoachNotes_Logo_Rectangle_Stack.svg')
            "
            :alt="pageName"
          />
        </b-navbar-brand>
        <div>
          <b-avatar
            style="margin: 5px"
            icon="clipboard-check"
            id="sidebarsIcon"
            button
            variant="info"
            :size="globalComponentSize"
          />
          <b-avatar
            id="loginTargetIcon"
            button
            :variant="isLoggedIn ? 'success' : 'primary'"
            :size="globalComponentSize"
          >
            <b-icon
              class="p-0"
              :animation="isLoggedIn ? '' : 'throb'"
              icon="person-fill"
              :scale="
                globalComponentSize === 'sm'
                  ? '.75'
                  : globalComponentSize === 'lg'
                  ? '1.25'
                  : '1'
              "
              style="animation-iteration-count: 7"
            ></b-icon>
          </b-avatar>
        </div>
      </div>
    </b-navbar>

    <b-popover
      id="sidebarsIconPopover"
      target="sidebarsIcon"
      triggers="click"
      placement="bottom"
    >
      <div>
        <b-button-group style="width: 100%" vertical>
          <b-button
            variant="outline-info"
            :size="globalComponentSize"
            v-b-toggle.sidebar-pinMatrix
            >Pin Matrix</b-button
          >
          <b-button
            v-if="isLoggedIn"
            variant="outline-info"
            :size="globalComponentSize"
            v-b-toggle.sidebar-earnedPinHistory
            >Earned Pins History</b-button
          >
          <b-button
            variant="outline-info"
            :size="globalComponentSize"
            v-b-toggle.sidebar-quickBracket
          >
            <svg width="25" height="25">
              <path
                style="
                  fill: none;
                  stroke: currentColor;
                  stroke-width: 2px;
                  stroke-linecap: butt;
                  stroke-linejoin: miter;
                  stroke-opacity: 1;
                "
                d="M3 3 H13 V8 H23 H13 V13 H3"
              />
            </svg>
            Bracket</b-button
          >
        </b-button-group>
        <hr />
        <div>
          <b-button-group size="lg">
            <b-button
              :variant="globalComponentSize === 'sm' ? 'info' : 'outline-info'"
              v-on:click="changeGlobalComponentSize('sm')"
            >
              <b-icon icon="arrows-angle-expand" scale=".5" />
            </b-button>

            <b-button
              :variant="globalComponentSize === 'md' ? 'info' : 'outline-info'"
              v-on:click="changeGlobalComponentSize('md')"
            >
              <b-icon icon="arrows-angle-expand" scale="1" />
            </b-button>

            <b-button
              :variant="globalComponentSize === 'lg' ? 'info' : 'outline-info'"
              v-on:click="changeGlobalComponentSize('lg')"
            >
              <b-icon icon="arrows-angle-expand" scale="1.5" />
            </b-button>
          </b-button-group>
        </div>
      </div>
    </b-popover>
    <div v-if="isLoginProcessing"><b-spinner /> Working...</div>
    <b-popover
      id="loginTargetIconPopover"
      target="loginTargetIcon"
      triggers="click"
      placement="bottom"
    >
      <div v-if="isLoginProcessing"><b-spinner /> Working...</div>

      <div v-if="isLoggedIn">
        <b-button
          style="width: 100%"
          variant="success"
          :size="globalComponentSize"
          disabled
          >Logged In: {{ getLoggedInUsername }}</b-button
        >

        <hr />

        <b-button-group vertical style="width: 100%">
          <b-button
            variant="outline-info"
            :size="globalComponentSize"
            v-on:click="toggleFeedbackForm"
            >Rate / Feedback</b-button
          >
          <b-button
            variant="outline-info"
            :size="globalComponentSize"
            v-on:click="togglePasswordResetForm"
            >Reset Password</b-button
          >
          <b-button
            variant="outline-info"
            :size="globalComponentSize"
            v-on:click="tryLogOut"
            >Log Out</b-button
          >
        </b-button-group>
      </div>
      <b-button-group vertical v-else>
        <b-button
          variant="outline-info"
          :size="globalComponentSize"
          v-on:click="toggleLogin"
          >Log In</b-button
        >
        <b-button
          variant="outline-info"
          :size="globalComponentSize"
          v-on:click="togglePasswordResetForm"
          >Reset Password</b-button
        >
        <b-button
          variant="outline-info"
          :size="globalComponentSize"
          v-on:click="toggleCreateLogin"
          >Create Account</b-button
        >
      </b-button-group>
    </b-popover>

    <b-modal
      id="modal-feedbackForm"
      shadow
      hide-footer
      :static="true"
      v-if="showFeedbackForm"
      v-model="showFeedbackForm"
    >
      <template v-slot:modal-title>Feedback</template>
      <FeedbackForm
        v-on:newFeedback="newFeedback"
        v-on:cancel="toggleFeedbackForm"
      />
    </b-modal>

    <b-modal
      id="modal-loginForm"
      right
      shadow
      hide-footer
      :static="true"
      v-if="showLoginForm"
      v-model="showLoginForm"
    >
      <template v-slot:modal-title>Enter Login Credentails</template>
      <LoginForm v-on:attemptLogin="onAttemptLogin" v-on:cancel="toggleLogin" />
    </b-modal>

    <b-modal
      id="modal-loginPasswordResetForm"
      right
      shadow
      hide-footer
      :static="true"
      v-if="showPasswordResetForm"
      v-model="showPasswordResetForm"
    >
      <template v-slot:modal-title>Enter Password Reset Credentails</template>
      <PasswordResetForm
        v-on:attemptPasswordReset="onAttemptPasswordReset"
        v-on:cancel="togglePasswordResetForm"
      />
    </b-modal>

    <b-modal
      id="modal-createLoginForm"
      right
      shadow
      hide-footer
      :static="true"
      v-if="showCreateLoginForm"
      v-model="showCreateLoginForm"
    >
      <template v-slot:modal-title>Create New Login</template>
      <CreateLoginForm
        v-on:attemptCreateLogin="onAttemptCreateLogin"
        v-on:cancel="toggleCreateLogin"
      />
    </b-modal>

    <b-sidebar
      id="sidebar-pinMatrix"
      title="Pin Matrix"
      shadow
      v-on:shown="hideSidebarsIconPopover"
      width="400px"
    >
      <Coach />
    </b-sidebar>

    <b-sidebar
      id="sidebar-quickBracket"
      left
      shadow
      title="Quick Bracket"
      v-on:shown="hideSidebarsIconPopover"
      width="370px"
    >
      <QuickBracket />
    </b-sidebar>

    <b-sidebar
      id="sidebar-earnedPinHistory"
      title="Earned Pins History"
      right
      shadow
      v-on:shown="hideSidebarsIconPopover"
      width="400px"
    >
      <div class="px-3 py-2">
        <EarnedPinsList />
      </div>
    </b-sidebar>
    <Toaster />
    <LogoutManager />
    <router-view />

    <!-- <b-navbar style="justify-content: center" sticky type="dark" variant="info">
      <div class="container">
        Owned and Opperated by CornerstoneChaos LLC
      </div>
    </b-navbar> -->
  </div>
</template>
<script>
import Coach from './views/CoachX';
import Toaster from './components/Toaster';
import LogoutManager from './components/LogoutManager';
import { mapActions, mapGetters } from 'vuex';
import LoginForm from './components/LoginForm';
import PasswordResetForm from './components/PasswordResetForm';
import CreateLoginForm from './components/CreateLoginForm';
import EarnedPinsList from './components/EarnedPinsList';
import FeedbackForm from './components/FeedbackForm';
import QuickBracket from './components/QuickBracket';

export default {
  components: {
    Coach,
    Toaster,
    LogoutManager,
    LoginForm,
    CreateLoginForm,
    EarnedPinsList,
    PasswordResetForm,
    FeedbackForm,
    QuickBracket,
  },
  data: () => {
    return {
      showLoginForm: false,
      showPasswordResetForm: false,
      showCreateLoginForm: false,
      showFeedbackForm: false,
    };
  },
  async created() {
    document.title = this.pageName;

    this.startLoginManager();

    this.createLoginKeys();

    if (!this.pinGroupsLoaded) {
      this.initializePins();
    }

    if (this.isLoggedIn) {
      let loginTokenAssembly = await this.getLoginTokenAssembly;

      // Path A
      await this.loadClubs(loginTokenAssembly);

      // Path B
      await this.loadParticipantSummaries({
        loginTokenAssembly: loginTokenAssembly,
      });

      await this.updateDefaultSelection();

      this.changeGlobalComponentSize(this.globalComponentSize);
    }
  },
  methods: {
    ...mapActions('pin', ['initializePins']),
    ...mapActions('login', [
      'startLoginManager',
      'createLoginKeys',
      'tryLogin',
      'logOut',
      'tryCreateLogin',
      'tryResetPassword',
    ]),
    ...mapActions('participant', [
      'loadParticipants',
      'loadParticipantSummaries',
      'setSelectedParticipantSummaryById',
    ]),
    ...mapActions('score', ['loadScores', 'loadScoresForSummaries']),
    ...mapActions('club', ['loadClubs', 'setSelectedClubById']),
    ...mapActions('subscription', ['loadLatestSubscriptionForClub']),
    ...mapActions('feedback', ['saveFeedback']),
    ...mapActions('sitewide', ['changeGlobalComponentSize']),
    toggleLogin() {
      this.$root.$emit('bv::hide::popover');
      this.showLoginForm = !this.showLoginForm;
    },
    togglePasswordResetForm() {
      this.$root.$emit('bv::hide::popover');
      this.showPasswordResetForm = !this.showPasswordResetForm;
    },
    toggleCreateLogin() {
      this.$root.$emit('bv::hide::popover');
      this.showCreateLoginForm = !this.showCreateLoginForm;
    },
    toggleFeedbackForm() {
      this.$root.$emit('bv::hide::popover');
      this.showFeedbackForm = !this.showFeedbackForm;
    },
    async onAttemptPasswordReset(passwordResetCredentials) {
      this.$root.$emit('bv::hide::popover');
      this.togglePasswordResetForm();
      this.tryResetPassword(passwordResetCredentials);
    },
    async onAttemptLogin(loginCredentials) {
      this.$root.$emit('bv::hide::popover');
      this.toggleLogin();
      await this.tryLogin(loginCredentials);
      if (this.isLoggedIn) {
        let loginTokenAssembly = await this.getLoginTokenAssembly;

        // Path A
        await this.loadClubs(this.getLoginTokenAssembly);

        // Path B
        await this.loadParticipantSummaries({
          loginTokenAssembly: loginTokenAssembly,
        });

        this.updateDefaultSelection();
      }
    },
    async updateDefaultSelection() {
      let loginTokenAssembly = await this.getLoginTokenAssembly;

      if (
        this.getSelectedClub === undefined &&
        this.getSelectedParticipantSummary === undefined
      ) {
        if (this.getClubs.length > 0) {
          // set selected club
          await this.setSelectedClubById(this.getClubs[0]._id);

          if (this.getClubs.length > 0 && this.getSelectedClub !== undefined) {
            let selectedClub = this.getSelectedClub;
            this.loadScores({
              loginTokenAssembly: loginTokenAssembly,
              clubTokenAssembly: selectedClub.clubTokenAssembly,
            });
            this.loadParticipants({
              loginTokenAssembly: loginTokenAssembly,
              club: selectedClub,
            });
            this.loadLatestSubscriptionForClub({
              loginTokenAssembly: loginTokenAssembly,
              clubTokenAssembly: selectedClub.clubTokenAssembly,
            });
          }
        } else if (this.getParticipantSummaries.length > 0) {
          // set selected participant summary
          await this.setSelectedParticipantSummaryById(
            this.getParticipantSummaries[0]._id
          );

          let participantSummary = this.getSelectedParticipantSummary;
          this.loadScoresForSummaries({
            loginTokenAssembly: loginTokenAssembly,
            participantSummaryTokenAssemblies: [
              participantSummary.participantTokenAssembly,
            ],
          });
        }
      }
    },
    async newFeedback(newFeedback) {
      this.$root.$emit('bv::hide::popover');
      this.toggleFeedbackForm();
      this.saveFeedback(newFeedback);
    },
    async tryLogOut() {
      this.$root.$emit('bv::hide::popover');

      // // TODO: THIS needs to be callable from a single action module so it can be done from within a module.
      // this.clearClubData();
      // this.clearParticipantData();
      // this.clearScoreData();
      // this.clearPinData();
      // this.clearSubscriptionData();
      // this.clearAdminData();

      await this.logOut();
    },
    onAttemptCreateLogin(createLoginData) {
      this.$root.$emit('bv::hide::popover');
      this.toggleCreateLogin();
      this.tryCreateLogin(createLoginData);
    },
    hideSidebarsIconPopover() {
      this.$root.$emit('bv::hide::popover');
    },
    globalHidePopups() {
      // TODO: this may be the only popover hide that is needed, will require some through testing so leaving it as an addition to existing hide features.
      this.$root.$emit('bv::hide::popover');
    },
  },
  computed: {
    ...mapGetters('login', {
      isLoggedIn: 'isLoggedIn',
      getLoginTokenAssembly: 'getLoginTokenAssembly',
      isLoginProcessing: 'isLoginProcessing',
      getLoggedInUsername: 'getLoggedInUsername',
    }),
    ...mapGetters('club', {
      getClubs: 'getClubs',
      getSelectedClub: 'getSelectedClub',
    }),
    ...mapGetters('sitewide', {
      globalComponentSize: 'globalComponentSize',
    }),
    ...mapGetters('participant', {
      getParticipantSummaries: 'getParticipantSummaries',
      getSelectedParticipantSummary: 'getSelectedParticipantSummary',
    }),
    pageName: {
      get() {
        let base_url = window.location.hostname.toLowerCase();
        if (
          base_url === 'archerycoachnotes.com' ||
          base_url === 'www.archerycoachnotes.com'
        ) {
          return 'Archery Coach Notes';
        } else if (
          base_url === 'pinmentortesting.com' ||
          base_url === 'www.pinmentortesting.com'
        ) {
          return '*test: pin mentor**';
        } else {
          return '*test: branch******';
        }
      },
      set(value) {
        console.log('unexpected attempt to set value pageName to: ' + value);
      },
    },
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: var(--light);
  height: auto;
  min-height: 100vh;
}
</style>
