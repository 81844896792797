import axios from 'axios';

export default {
  getPinData: () =>
    new Promise((resolve, reject) => {
      let path = '/api/pin/lookup/';
      axios
        .get(path)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .get(path)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
  getEarnedPinsForScores: (scores) =>
    new Promise((resolve, reject) => {
      let credentials = scores;
      let path = '/api/pin/earned/';
      axios
        .post(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .post(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
};
