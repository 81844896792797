<template>
  <div>
    <b-form :id="'loginForm'" @submit="onSubmit" @reset="onCancel">
      <div v-if="loginKeysLoaded && !isGettingLoginKeys">
        <b-form-group
          id="usernameGroup"
          label="User Name (e-mail)*:"
          label-for="username"
          invalid-feedback="Please Enter a valid e-mail address"
        >
          <b-form-input
            id="_id"
            v-model.number="form._id"
            type="email"
            required
            placeholder="Enter your user name (e-mail)"
            :autofocus="true"
            autocomplete="off"
            :size="globalComponentSize"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="passwordGroup"
          label="Password*:"
          label-for="password"
        >
          <b-form-input
            id="password"
            v-model.number="form.password"
            required
            type="password"
            placeholder="Enter your password"
            autocomplete="off"
            :size="globalComponentSize"
          ></b-form-input>
        </b-form-group>

        <div>* indicates required field</div>
      </div>
      <div v-else-if="isGettingLoginKeys">
        <b-spinner />
        Getting security keys. Please Wait.
      </div>
      <div v-else>
        <h3>System Error.</h3>
        Login <em>Not Available</em> at this time. Please reload the page.
      </div>

      <hr />

      <div>
        On successful login you will be logged in on this device for 30 days or
        until you explicitly logout.
      </div>

      <div>
        <b-button
          class="float-left"
          id="btnCancel"
          type="reset"
          variant="default"
          tabindex="-1"
          :size="globalComponentSize"
          >Cancel</b-button
        >

        <b-button
          v-if="loginKeysLoaded"
          class="float-right"
          id="btnSubmitLogin"
          type="submit"
          variant="primary"
          :size="globalComponentSize"
          >Log In</b-button
        >
      </div>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
const defaultFormValues = {
  _id: null,
  password: null,
};
export default {
  name: 'LoginForm',
  data() {
    return {
      form: defaultFormValues,
    };
  },
  created() {
    // TODO, could map username if user requested remember me

    this.$root.$emit('bv::hide::popover');
  },
  props: {
    // TODO, props could provide "remember me" user name
  },
  computed: {
    ...mapGetters('login', {
      loginKeysLoaded: 'loginKeysLoaded',
      isGettingLoginKeys: 'isGettingLoginKeys',
    }),
    ...mapGetters('sitewide', ['globalComponentSize']),
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let loginCredentials = Object.assign({}, this.form);
      this.$emit('attemptLogin', loginCredentials);
      this.setDefaultValues();
    },
    onCancel() {
      this.$emit('cancel');
      this.setDefaultValues();
    },
    setDefaultValues() {
      // todo, don't reset if "remember me" selected
      this.form._id = null;
      this.form.password = null;
    },
  },
};
</script>
<style scoped></style>
