<template>
  <div class="container" style="display: flex; justify-content: center">
    <!-- If not logged in then display landing page -->
    <!-- TODO: convert into landing page conponent -->
    <div v-if="!isLoggedIn" class="col-lg-10">
      <p>
        This site is available for use by invite only. If you are interested in
        participating please contact support at
        <u><em>CornerstoneChaos@gmail.com</em></u
        >.
      </p>

      <!-- images in carousel are from pixabay.com -->
      <b-carousel
        id="carousel-1"
        :interval="8000"
        controls
        indicators
        background="#ababab"
        style="text-shadow: 2px 2px 2px #333"
      >
        <b-carousel-slide
          caption-tag="h2"
          caption="The Best Part"
          text-tag="p"
          text="Stay focused on the archery, not on the record keeping"
          :img-src="require('../assets/arrows.jpg')"
        >
        </b-carousel-slide>
        <b-carousel-slide
          caption-tag="h2"
          caption="Achievement Tracking"
          text-tag="p"
          text="We'll tell you what USAA Pins the participant has earned"
          :img-src="require('../assets/medals.jpg')"
        >
        </b-carousel-slide>
        <b-carousel-slide
          caption-tag="h2"
          caption="Coach Coordination"
          text-tag="p"
          text="Every coach has access to the participants score history & equipment notes"
          :img-src="require('../assets/concept.jpg')"
        >
        </b-carousel-slide>
      </b-carousel>
    </div>

    <!-- If logged in then create controls and display content and indicators -->
    <div v-else style="width: 100%">
      <b-modal
        id="bv-modal-add-participant"
        v-if="showAddForm"
        v-model="showAddForm"
        hide-footer
        :static="true"
        centered
      >
        <template v-slot:modal-title>Enter a New Archer</template>

        <AddParticipantForm
          v-on:newparticipant="onNewParticipant"
          v-on:cancel="toggleAddParticipantForm"
        />
      </b-modal>
      <b-modal
        id="bv-modal-add-club"
        v-if="showAddClubForm"
        v-model="showAddClubForm"
        hide-footer
        :static="true"
        centered
      >
        <template v-slot:modal-title>Enter a New Club Name</template>

        <AddClubForm v-on:newclub="onNewClub" v-on:cancel="toggleAddClubForm" />
      </b-modal>
      <b-modal
        :id="'bv-modal-edit-club' + getSelectedClub._id"
        hide-footer
        :static="true"
        v-if="showEditClubForm && !isLatestSubscriptionTokenAssemblyLoading"
        v-model="showEditClubForm"
      >
        <template v-slot:modal-title
          >Manage {{ getSelectedClub.clubName }}</template
        >
        <EditClubForm
          v-if="showEditClubForm"
          :club="getSelectedClub"
          v-on:updateClub="onUpdateClub"
          v-on:cancel="toggleEditClubForm()"
        />
      </b-modal>
      <div class="row-center">
        <!-- If we are still loading base data then show a spinner -->
        <div
          v-if="
            isGettingLoginKeys ||
            (!areClubsLoaded && isClubLoadProcessing) ||
            (!areParticipantSummariesLoaded &&
              isParticipantSummariesLoadProcessing)
          "
        >
          <b-spinner :size="globalComponentSize" /> Loading ...
        </div>
        <!-- If we are done loading then show selector -->
        <div v-else class="row">
          <!-- if club is saving then display saving icon -->
          <div v-if="isClubSaveProcessing"><b-spinner /> Savinging Club...</div>

          <!-- If we have nothing selected then display -->
          <div
            class="col"
            v-if="
              getSelectedClub === undefined &&
              getSelectedParticipantSummary === undefined
            "
          >
            <h3>
              <em>Coaches:</em>
              <b-button
                :size="globalComponentSize"
                variant="outline-info"
                v-on:click="toggleAddClubFormButton"
                class="m-2"
                >Create New Club</b-button
              >
            </h3>
            <hr />
            <h3>
              <em>Archers / Guardians:</em> Have your Coach add your user name (e-mail) to your Archers data so you can
              view it here.
            </h3>
          </div>

          <!-- Only show if we have something selectable -->
          <b-button
            id="loadManageIcon"
            class="col"
            variant="outline-info"
            v-if="getClubs.length > 0 || getParticipantSummaries.length > 0"
            :size="globalComponentSize"
          >
            <b-avatar
              class="m-0"
              variant="inherit but not really i just have to have something here or it gets a default that looks terrible"
              icon="chevron-down"
              :size="globalComponentSize"
            />
            {{ getSelectedClubOrParticipantButtonDisplay }}
          </b-button>
          <b-popover
            v-if="getClubs.length > 0 || getParticipantSummaries.length > 0"
            id="loadManageIconPopover"
            target="loadManageIcon"
            triggers="click"
            placement="bottom"
          >
            <b-button-group style="width: 100%">
              <b-button
                block
                :size="globalComponentSize"
                variant="outline-info"
                v-on:click="toggleAddClubFormButton"
                >Create New Club</b-button
              >
            </b-button-group>
            <hr v-if="getSelectedClub !== undefined" />
            <b-button-group
              vertical
              style="width: 100%"
              v-if="getClubs.length > 0"
            >
              <b-button
                v-if="
                  !isLatestSubscriptionTokenAssemblyLoading &&
                  getSelectedClub !== undefined
                "
                variant="outline-info"
                :size="globalComponentSize"
                v-on:click="toggleEditClubForm"
                >Manage Selected Club</b-button
              >
            </b-button-group>
            <hr v-if="getClubs.length > 0" />
            <b-button-group
              vertical
              style="width: 100%"
              v-if="getClubs.length > 0"
            >
              <b-button
                :variant="
                  getSelectedClub !== undefined && a._id === getSelectedClub._id
                    ? 'info'
                    : 'outline-info'
                "
                :size="globalComponentSize"
                v-for="a in getClubs"
                :key="a._id"
                v-on:click="viewClubButton(a)"
                >{{ 'Club: ' + a.clubName }}</b-button
              >
            </b-button-group>

            <hr v-if="getParticipantSummaries.length > 0" />
            <b-button-group
              vertical
              style="width: 100%"
              v-if="getParticipantSummaries.length > 0"
            >
              <b-button
                :variant="
                  getSelectedParticipantSummary !== undefined &&
                  a._id === getSelectedParticipantSummary._id
                    ? 'info'
                    : 'outline-info'
                "
                :size="globalComponentSize"
                v-for="a in getParticipantSummaries"
                :key="a._id"
                v-on:click="viewParticipantSummaryButton(a)"
              >
                {{ 'Archer: ' + a.firstName + ' ' + a.lastName }}
              </b-button>
            </b-button-group>
          </b-popover>
        </div>
      </div>

      <!-- If we have a club selected then display the club view controls and create the filter sidebar control -->
      <div
        class="row-center"
        v-if="
          getSelectedClub !== undefined &&
          !isParticipantSummariesLoadProcessing &&
          areClubsLoaded &&
          !isClubLoadProcessing
        "
      >
        <div class="col-center">
          <b-button-toolbar
            v-if="
              !isGettingLoginKeys &&
              !isClubLoadProcessing &&
              areClubsLoaded &&
              !isLatestSubscriptionTokenAssemblyLoading
            "
          >
            <b-button-group class="mx-auto" style="margin: 5px">
              <b-button
                id="viewParticipantTableButton"
                :size="globalComponentSize"
                v-on:click="setDisplayParticipantType('table')"
                :variant="
                  displayParticipantType === 'table' ? 'info' : 'outline-info'
                "
                ><BIconTable
              /></b-button>

              <b-button
                id="viewParticipantCardButton"
                :size="globalComponentSize"
                v-on:click="setDisplayParticipantType('card')"
                :variant="
                  displayParticipantType === 'card' ? 'info' : 'outline-info'
                "
              >
                <BIconGrid />
              </b-button>

              <b-button
                id="viewParticipantSummariesButton"
                :size="globalComponentSize"
                v-on:click="setDisplayParticipantType('summary')"
                :variant="
                  displayParticipantType === 'summary' ? 'info' : 'outline-info'
                "
              >
                <BIconCardText />
              </b-button>
            </b-button-group>
            <b-button-group class="mx-auto" style="margin: 5px">
              <b-button
                id="addParticipantButton"
                v-on:click="toggleAddParticipantFormButtonClicked"
                variant="outline-info"
                :size="globalComponentSize"
                >Add Archer</b-button
              >

              <b-button
                id="filterControl"
                variant="outline-info"
                :size="globalComponentSize"
                v-on:click="toggleSidebarFilterControlButtonClicked"
                v-b-toggle.sidebar-filterControl
              >
                <BIconFilter /> Filter / Visibility{{
                  displayParticipantType === 'card' ? ' / Sort' : ''
                }}
              </b-button>
            </b-button-group>

            <!-- TODO: make the sidebar content its own component and import it here -->
            <!-- TODO: also it should only exist if we are on club views -->
            <b-sidebar id="sidebar-filterControl" right shadow width="370px">
              <template #title>
                Filter, Visibility, Sort
                <b-avatar
                  id="participantFilterVisibilitySortSidebarHelpIcon"
                  size="sm"
                  icon="question"
                  button
                  variant="info"
                  tabindex="-1"
                />
                <b-popover
                  id="participantFilterVisibilitySortSidebarHelpIconPopover"
                  target="participantFilterVisibilitySortSidebarHelpIcon"
                  triggers="click"
                  placement="auto"
                  variant="info"
                >
                  <template #title>
                    <b-button class="close" aria-label="Close">
                      <span class="d-inline-block" aria-hidden="true"
                        >&times;</span
                      >
                    </b-button>
                    Visibility
                  </template>
                  <b-list-group style="height: auto">
                    <b-list-group-item
                      >Your settings here will be retained on this
                      device.</b-list-group-item
                    >
                    <b-list-group-item
                      >It is not reccomended to use multiple tabs of your
                      browser as the settings of each tab will overwrite each
                      other.</b-list-group-item
                    >
                  </b-list-group>
                </b-popover>
              </template>
              <b-form-group
                :label-size="globalComponentSize"
                id="visibility-group"
              >
                <template #label>
                  <h5>
                    Visibility
                    <b-avatar
                      id="participantVisibilityHelpIcon"
                      size="sm"
                      icon="question"
                      button
                      variant="info"
                      tabindex="-1"
                    />
                  </h5>
                  <b-popover
                    id="participantVisibilityHelpIconPopover"
                    target="participantVisibilityHelpIcon"
                    triggers="click"
                    placement="auto"
                    variant="info"
                  >
                    <template #title>
                      <b-button class="close" aria-label="Close">
                        <span class="d-inline-block" aria-hidden="true"
                          >&times;</span
                        >
                      </b-button>
                      <h4>Visibility</h4>
                    </template>
                    <b-list-group style="height: auto">
                      <b-list-group-item
                        >Your Visibility selection will be retained separately
                        for table vs card views.</b-list-group-item
                      >
                      <b-list-group-item
                        >The <em>Indoor</em> and <em>Outdoor</em> selectors
                        affect <em>Highest Earned Pin</em>, <em>Next Pin</em>,
                        and <em>Score List</em> selectors. At minimum you must
                        select one from each pair for the display of data to
                        change.</b-list-group-item
                      >
                      <b-list-group-item
                        ><em>Classification</em> will also display
                        <em>Age</em> if the archer has a known
                        birthdate.</b-list-group-item
                      >
                      <b-list-group-item
                        >Visibility options are not available when viewing
                        Archer Summaries
                      </b-list-group-item>
                    </b-list-group>
                  </b-popover>
                </template>
                <span v-if="displayParticipantType !== 'summary'">
                  <b-form-checkbox
                    :size="globalComponentSize"
                    switch
                    :checked="visibleParticipantFields['buttons']"
                    @change="toggleVisibleParticipantField('buttons')"
                  >
                    Actions
                  </b-form-checkbox>
                  <b-form-checkbox
                    :size="globalComponentSize"
                    switch
                    :checked="visibleParticipantFields['classification']"
                    @change="toggleVisibleParticipantField('classification')"
                  >
                    Classification
                  </b-form-checkbox>
                  <b-form-checkbox
                    :size="globalComponentSize"
                    switch
                    :checked="visibleParticipantFields['bowType']"
                    @change="toggleVisibleParticipantField('bowType')"
                  >
                    Bow Type
                  </b-form-checkbox>
                  <div class="row" style="margin-left: 0px">
                    <b-form-checkbox
                      class="col"
                      :size="globalComponentSize"
                      switch
                      :checked="visibleParticipantFields['indoor']"
                      @change="toggleVisibleParticipantField('indoor')"
                    >
                      Indoor
                    </b-form-checkbox>
                    <b-form-checkbox
                      class="col"
                      :size="globalComponentSize"
                      switch
                      :checked="visibleParticipantFields['outdoor']"
                      @change="toggleVisibleParticipantField('outdoor')"
                    >
                      Outdoor
                    </b-form-checkbox>
                  </div>
                  <b-form-checkbox
                    style="margin-left: 30px"
                    :size="globalComponentSize"
                    switch
                    :checked="visibleParticipantFields['highestEarnedPins']"
                    @change="toggleVisibleParticipantField('highestEarnedPins')"
                  >
                    Highest Earned Pins
                  </b-form-checkbox>
                  <b-form-checkbox
                    style="margin-left: 30px"
                    :size="globalComponentSize"
                    switch
                    :checked="visibleParticipantFields['nextPins']"
                    @change="toggleVisibleParticipantField('nextPins')"
                  >
                    Next Pins
                  </b-form-checkbox>
                  <b-form-checkbox
                    style="margin-left: 30px"
                    :size="globalComponentSize"
                    switch
                    :checked="visibleParticipantFields['scoreList']"
                    @change="toggleVisibleParticipantField('scoreList')"
                  >
                    Score List
                  </b-form-checkbox>
                  <b-form-checkbox
                    :size="globalComponentSize"
                    switch
                    :checked="visibleParticipantFields['emergencyContact']"
                    @change="toggleVisibleParticipantField('emergencyContact')"
                  >
                    Emergency Contact
                  </b-form-checkbox>
                  <b-form-checkbox
                    :size="globalComponentSize"
                    switch
                    :checked="visibleParticipantFields['equipmentNote']"
                    @change="toggleVisibleParticipantField('equipmentNote')"
                  >
                    Equipment Note
                  </b-form-checkbox>
                  <b-form-checkbox
                    :size="globalComponentSize"
                    switch
                    :checked="visibleParticipantFields['note']"
                    @change="toggleVisibleParticipantField('note')"
                  >
                    General Note
                  </b-form-checkbox>
                  <b-form-checkbox
                    :size="globalComponentSize"
                    switch
                    :checked="visibleParticipantFields['hasUSAAMembership']"
                    @change="toggleVisibleParticipantField('hasUSAAMembership')"
                  >
                    USAA Membership
                  </b-form-checkbox>
                  <b-form-checkbox
                    :size="globalComponentSize"
                    switch
                    :checked="visibleParticipantFields['participantGroups']"
                    @change="toggleVisibleParticipantField('participantGroups')"
                  >
                    Groups
                  </b-form-checkbox>
                  <b-form-checkbox
                    :size="globalComponentSize"
                    switch
                    :checked="visibleParticipantFields['accessAccounts']"
                    @change="toggleVisibleParticipantField('accessAccounts')"
                  >
                    Access Accounts
                  </b-form-checkbox>
                </span>
              </b-form-group>

              <b-form-group
                :label-size="globalComponentSize"
                id="sort-options-group"
              >
                <template #label>
                  <h5>
                    Sort Options
                    <b-avatar
                      id="participantSortingHelpIcon"
                      size="sm"
                      icon="question"
                      button
                      variant="info"
                      tabindex="-1"
                    />
                  </h5>
                  <b-popover
                    id="participantSortingHelpIconPopover"
                    target="participantSortingHelpIcon"
                    triggers="click"
                    placement="auto"
                    variant="info"
                  >
                    <template #title>
                      <b-button class="close" aria-label="Close">
                        <span class="d-inline-block" aria-hidden="true"
                          >&times;</span
                        >
                      </b-button>
                      <h4>Sorting</h4>
                    </template>
                    <b-list-group style="height: auto">
                      <b-list-group-item
                        >These Sort options are not available when using Table
                        view.</b-list-group-item
                      >
                      <b-list-group-item
                        >In Table view you can sort by clicking on the
                        headers.</b-list-group-item
                      >
                      <b-list-group-item
                        >The <em>Next Pin</em> columns will sort by the option
                        with the shortest distance for that
                        archer.</b-list-group-item
                      >
                    </b-list-group>
                  </b-popover>
                </template>
                <span v-if="displayParticipantType !== 'table'">
                  <b-button-group
                    :size="globalComponentSize"
                    style="margin: 5px"
                  >
                    <b-button
                      :variant="
                        orderNameToggle[0] === 'firstName'
                          ? 'info'
                          : 'outline-info'
                      "
                      v-on:click="orderByFirstName"
                    >
                      First Name
                    </b-button>
                    <b-button
                      :variant="
                        orderNameToggle[0] === 'lastName'
                          ? 'info'
                          : 'outline-info'
                      "
                      v-on:click="orderByLastName"
                      >Last Name</b-button
                    >
                  </b-button-group>
                  <b-button-group
                    :size="globalComponentSize"
                    style="margin: 5px"
                  >
                    <b-button
                      :variant="
                        sortToggle[0] === 'asc' ? 'info' : 'outline-info'
                      "
                      v-on:click="sortAtoZ"
                    >
                      <b-icon icon="sort-alpha-down" />
                    </b-button>
                    <b-button
                      :variant="
                        sortToggle[0] === 'desc' ? 'info' : 'outline-info'
                      "
                      v-on:click="sortZtoA"
                    >
                      <b-icon icon="sort-alpha-down-alt" />
                    </b-button>
                  </b-button-group>
                </span>
              </b-form-group>

              <hr />

              <legend :class="'col-form-label-' + globalComponentSize">
                Filters ({{ participants.length }} archers visible)
                <b-avatar
                  id="participantCountsHelpIcon"
                  size="sm"
                  icon="question"
                  button
                  variant="info"
                  tabindex="-1"
                />
                <b-popover
                  id="participantCountsHelpIconPopover"
                  target="participantCountsHelpIcon"
                  triggers="click"
                  placement="auto"
                  variant="info"
                >
                  <template #title>
                    <b-button class="close" aria-label="Close">
                      <span class="d-inline-block" aria-hidden="true"
                        >&times;</span
                      >
                    </b-button>
                    <h4>Filter Counts</h4>
                  </template>
                  <b-list-group style="height: auto">
                    <b-list-group-item
                      >In the filter section anywhere you see a number inside
                      parenthesis it reflects the number of archers in that
                      group.</b-list-group-item
                    >
                    <b-list-group-item
                      >The number at the top is the result of all selected
                      filters.</b-list-group-item
                    >
                    <b-list-group-item
                      >The <em>Group</em>, <em>Classification</em>, and
                      <em>USAA Membership</em> sections also reflect the current
                      setting of the <em>Archived</em> section. This way the
                      counts will make a little more sense as you generally will
                      have archived archers hidden.</b-list-group-item
                    >
                  </b-list-group>
                </b-popover>
              </legend>
              <b-form-group
                :label-size="globalComponentSize"
                id="filter-groups"
              >
                <template #label>
                  <h5>
                    Group
                    <b-avatar
                      id="participantGroupsHelpIcon"
                      size="sm"
                      icon="question"
                      button
                      variant="info"
                      tabindex="-1"
                    />
                  </h5>
                  <b-popover
                    id="participantGroupsHelpIconPopover"
                    target="participantGroupsHelpIcon"
                    triggers="click"
                    placement="auto"
                    variant="info"
                  >
                    <template #title>
                      <b-button class="close" aria-label="Close">
                        <span class="d-inline-block" aria-hidden="true"
                          >&times;</span
                        >
                      </b-button>
                      <h4>Filtering By Archer Groups</h4>
                    </template>
                    <b-list-group style="overflow-y: scroll; height: 60vh">
                      <b-list-group-item
                        >Archers can be in one or many
                        groups.</b-list-group-item
                      >
                      <b-list-group-item
                        >You can filter the visible archers by one or
                        multiple groups. This filtering can show archers in
                        either of the selected groups using the "OR" filter while the
                        "AND" filter will only show archers that are in both
                        groups.
                        <VinDiagramORIcon style="width: 100%" />
                        <VinDiagramANDIcon style="width: 100%" />
                      </b-list-group-item>
                    </b-list-group>
                  </b-popover>
                </template>
                <b-button-group>
                  <b-button
                    style="margin: 5px"
                    variant="outline-info"
                    v-on:click="deselectAllParticipantGroups"
                    :size="globalComponentSize"
                  >
                    Deselect All
                  </b-button>
                  <b-button
                    style="margin: 5px"
                    variant="outline-info"
                    v-on:click="selectAllParticipantGroups"
                    :size="globalComponentSize"
                  >
                    Select All
                  </b-button>
                </b-button-group>
                <b-button-group style="margin: 5px">
                  <b-button
                    :variant="
                      participantGroupFilterType === 'AND'
                        ? 'info'
                        : 'outline-info'
                    "
                    v-on:click="setParticipantGroupFilterType('AND')"
                    :size="globalComponentSize"
                  >
                    AND
                  </b-button>
                  <b-button
                    :variant="
                      participantGroupFilterType === 'OR'
                        ? 'info'
                        : 'outline-info'
                    "
                    v-on:click="setParticipantGroupFilterType('OR')"
                    :size="globalComponentSize"
                  >
                    OR
                  </b-button>
                </b-button-group>

                <b-form-checkbox
                  v-for="availableGroup in getParticipantGroupsFilters"
                  v-model="currentVisibleParticipantGroups"
                  :key="availableGroup._id"
                  :value="availableGroup._id"
                  switch
                  :id="'filter-group-switch-' + availableGroup._id"
                  :size="globalComponentSize"
                >
                  {{ availableGroup.name }} ({{
                    countByParticipantGroup(availableGroup._id)
                  }})
                </b-form-checkbox>
              </b-form-group>

              <b-form-group
                id="filter-classification"
                :label-size="globalComponentSize"
              >
                <template #label>
                  <h5>Classification</h5>
                </template>
                <b-form-checkbox
                  v-for="availableClassification in getAvailableClassifications"
                  v-model="currentVisibleClassifications"
                  :key="availableClassification"
                  :value="availableClassification"
                  switch
                  :id="'filter-classification-' + availableClassification"
                  :size="globalComponentSize"
                >
                  {{ availableClassification }} ({{
                    countByClassification(availableClassification)
                  }})
                </b-form-checkbox>
              </b-form-group>

              <b-form-group
                id="filter-usaaMembership"
                :label-size="globalComponentSize"
              >
                <template #label>
                  <h5>USAA Membership</h5>
                </template>
                <b-form-checkbox
                  v-for="availableUSAAMembership in getAvailableUSAAMemberships"
                  v-model="currentVisibleUSAAMemberships"
                  :key="availableUSAAMembership"
                  :value="availableUSAAMembership"
                  switch
                  :size="globalComponentSize"
                >
                  {{ availableUSAAMembership }} ({{
                    countByUSAAMembership(availableUSAAMembership)
                  }})
                </b-form-checkbox>
              </b-form-group>

              <b-form-group
                id="filter-archived"
                :label-size="globalComponentSize"
              >
                <template #label>
                  <h5>Archived</h5>
                </template>
                <b-form-checkbox
                  v-for="availableArchiveFlag in getAvailableArchiveFlags"
                  v-model="currentVisibleArchiveFlags"
                  :key="availableArchiveFlag"
                  :value="availableArchiveFlag"
                  switch
                  :size="globalComponentSize"
                >
                  {{ availableArchiveFlag }} ({{
                    countByArchived(availableArchiveFlag)
                  }})
                </b-form-checkbox>
              </b-form-group>
            </b-sidebar>
          </b-button-toolbar>
        </div>
      </div>
      <br />

      <!-- If we are still loading data then show a loading indicator -->
      <div
        v-if="
          isGettingLoginKeys ||
          (!areParticipantsLoaded && isParticipantLoadProcessing) ||
          (!areParticipantSummariesLoaded &&
            isParticipantSummariesLoadProcessing) ||
          isLatestSubscriptionTokenAssemblyLoading
        "
      >
        <b-spinner :size="globalComponentSize" /> Loading Archer(s)...
      </div>

      <!-- Else If we have a selected club then: this is club view: Display Cards or Tables -->
      <!-- TODO add single view -->
      <div
        v-else-if="
          getSelectedClub !== undefined &&
          getSelectedParticipantSummary === undefined
        "
      >
        <div v-if="participants.length === 0">
          There are no archers to display. Check your filters.
        </div>
        <div
          v-if="displayParticipantType === 'card'"
          class="row"
          id="participant-card-list"
        >
          <ParticipantCard
            v-for="p in participants"
            :key="p._id"
            class="col-lg-4 col-md-6 col-sm-12"
            :participant="p"
          />
        </div>
        <ParticipantTable
          v-else-if="displayParticipantType === 'table'"
          class="row m1"
          id="participant-table"
        />
        <div class="row,col" v-else-if="displayParticipantType === 'summary'">
          This is a read-only view of the archer summary. This is nearly
          identical to what users listed in the archers "Access Accounts"
          will see.

          <ParticipantSummaryCard
            class="m-2"
            v-for="p in participants"
            :key="p._id"
            :participant="p"
            :title="p.firstName + ' ' + p.lastName"
            :isSummaryPreview="true"
          />
        </div>
      </div>

      <!-- Else If we have a selected participant Summary then: this is single archer view: display it -->
      <div
        class="col"
        v-else-if="
          getSelectedClub === undefined &&
          getSelectedParticipantSummary !== undefined
        "
      >
        <ParticipantSummaryCard :participant="getSelectedParticipantSummary" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import ParticipantCard from '../components/ParticipantCard';
import ParticipantSummaryCard from '../components/ParticipantSummaryCard';
import ParticipantTable from '../components/ParticipantTable';
import AddParticipantForm from '../components/AddParticipantForm';
import EditClubForm from '../components/EditClubForm';
import AddClubForm from '../components/AddClubForm';
import VinDiagramORIcon from '../components/VinDiagramORIcon';
import VinDiagramANDIcon from '../components/VinDiagramANDIcon';
import {
  BIconFilter,
  BIconTable,
  BIconGrid,
  BIconCardText,
} from 'bootstrap-vue';
import _ from 'lodash';

export default {
  name: 'ParticipantListX',
  components: {
    ParticipantCard,
    ParticipantSummaryCard,
    ParticipantTable,
    AddParticipantForm,
    AddClubForm,
    EditClubForm,
    BIconFilter,
    BIconTable,
    BIconGrid,
    BIconCardText,
    VinDiagramORIcon,
    VinDiagramANDIcon,
  },
  data: () => {
    return {
      showEditClubForm: false,
      orderNameToggle: ['firstName', 'lastName'],
      sortToggle: ['asc', 'asc'],
    };
  },
  computed: {
    ...mapGetters('participant', {
      getFilteredParticipants: 'getFilteredParticipants',
      getParticipantSummaries: 'getParticipantSummaries',
      showAddForm: 'showAddForm',
      getAvailableClassifications: 'getAvailableClassifications',
      visibleClassifications: 'visibleClassifications',
      getAvailableUSAAMemberships: 'getAvailableUSAAMemberships',
      visibleUSAAMemberships: 'visibleUSAAMemberships',
      getAvailableArchiveFlags: 'getAvailableArchiveFlags',
      visibleArchiveFlags: 'visibleArchiveFlags',
      areParticipantsLoaded: 'areParticipantsLoaded',
      areParticipantSummariesLoaded: 'areParticipantSummariesLoaded',
      isParticipantLoadProcessing: 'isParticipantLoadProcessing',
      isParticipantSummariesLoadProcessing:
        'isParticipantSummariesLoadProcessing',
      visibleParticipantGroups: 'visibleParticipantGroups',
      visibleParticipantFields: 'visibleParticipantFields',
      participantGroupFilterType: 'participantGroupFilterType',
      displayParticipantType: 'displayParticipantType',
      countByClassification: 'countByClassification',
      countByParticipantGroup: 'countByParticipantGroup',
      countByUSAAMembership: 'countByUSAAMembership',
      countByArchived: 'countByArchived',
      getSelectedParticipantSummary: 'getSelectedParticipantSummary',
    }),
    ...mapGetters('login', {
      isLoggedIn: 'isLoggedIn',
      getLoginTokenAssembly: 'getLoginTokenAssembly',
      isGettingLoginKeys: 'isGettingLoginKeys',
    }),
    ...mapGetters('club', {
      getClubs: 'getClubs',
      showAddClubForm: 'showAddClubForm',
      getSelectedClub: 'getSelectedClub',
      areClubsLoaded: 'areClubsLoaded',
      isClubLoadProcessing: 'isClubLoadProcessing',
      isClubSaveProcessing: 'isClubSaveProcessing',
      getParticipantGroups: 'getParticipantGroups',
    }),
    ...mapGetters('subscription', [
      'getDaysRemainingInSubscription',
      'isSubscriptionActive',
      'getLatestSubscriptionTokenAssembly',
      'isLatestSubscriptionTokenAssemblyLoading',
    ]),
    ...mapGetters('sitewide', {
      globalComponentSize: 'globalComponentSize',
    }),
    participants: {
      get() {
        let filteredParticipants = this.getFilteredParticipants;
        return _.orderBy(
          filteredParticipants,
          this.orderNameToggle,
          this.sortToggle
        );
      },
    },
    currentVisibleClassifications: {
      get() {
        return this.visibleClassifications;
      },
      set(value) {
        this.setVisibleClassifications(value);
      },
    },
    currentVisibleParticipantGroups: {
      get() {
        return this.visibleParticipantGroups;
      },
      set(value) {
        this.setVisibleParticipantGroups(value);
      },
    },
    currentVisibleUSAAMemberships: {
      get() {
        return this.visibleUSAAMemberships;
      },
      set(value) {
        this.setVisibleUSAAMemberships(value);
      },
    },
    currentVisibleArchiveFlags: {
      get() {
        return this.visibleArchiveFlags;
      },
      set(value) {
        this.setVisibleArchiveFlags(value);
      },
    },
    isClubActiveText: {
      get() {
        let clubId = this.getSelectedClub._id;
        let days = this.getDaysRemainingInSubscription(clubId);
        let isLoading = this.isLatestSubscriptionTokenAssemblyLoading;
        let displayText = 'Unknown Status. Reload Page.';
        if (isLoading) {
          displayText = 'Loading Club Activation Status';
        } else if (days === 0) {
          displayText = 'Inactive. Read Only.';
        } else if (days === 1) {
          displayText =
            'You have less than a day remaining before the club is Read Only';
        } else if (days < 30 && days >= 2) {
          displayText = `Active for ${days} more days`;
        } else if (days >= 30) {
          displayText = `Active`;
        }

        return displayText;
      },
      set(value) {
        console.log(value);
      },
    },
    getSelectedClubOrParticipantButtonDisplay: {
      get() {
        let displayText = 'Please Select Something';
        if (this.getSelectedClub !== undefined) {
          displayText =
            'Club: ' +
            this.getSelectedClub.clubName +
            ' (' +
            this.isClubActiveText +
            ')';
        } else if (this.getSelectedParticipantSummary !== undefined) {
          displayText =
            'Archer: ' +
            this.getSelectedParticipantSummary.firstName +
            ' ' +
            this.getSelectedParticipantSummary.lastName;
        }
        return displayText;
      },
    },
    getParticipantGroupsFilters: {
      get() {
        return [{ _id: 'No Groups', name: 'No Groups' }].concat(
          this.getParticipantGroups
        );
      },
    },
  },
  created() {
    if (this.getSelectedClub !== undefined) {
      this.viewClubButton(this.getSelectedClub);
    } else if (this.getSelectedParticipantSummary !== undefined) {
      this.viewParticipantSummaryButton(this.getSelectedParticipantSummary);
    }
  },
  methods: {
    ...mapActions('participant', {
      loadParticipants: 'loadParticipants',
      toggleAddParticipantForm: 'toggleAddParticipantForm',
      saveParticipant: 'saveParticipant',
      setVisibleClassifications: 'setVisibleClassifications',
      setVisibleUSAAMemberships: 'setVisibleUSAAMemberships',
      setVisibleArchiveFlags: 'setVisibleArchiveFlags',
      setVisibleParticipantGroups: 'setVisibleParticipantGroups',
      setParticipantGroupFilterType: 'setParticipantGroupFilterType',
      setCachedDisplayParticipantType: 'setCachedDisplayParticipantType',
      toggleVisibleField: 'toggleVisibleField',
      setSelectedParticipantSummaryById: 'setSelectedParticipantSummaryById',
    }),
    ...mapActions('score', {
      loadScores: 'loadScores',
      loadScoresForSummaries: 'loadScoresForSummaries',
    }),
    ...mapActions('sitewide', {
      toastError: 'toastError',
    }),
    ...mapActions('club', {
      loadClubs: 'loadClubs',
      toggleAddClubForm: 'toggleAddClubForm',
      saveClub: 'saveClub',
      setSelectedClubById: 'setSelectedClubById',
    }),
    ...mapActions('subscription', {
      loadLatestSubscriptionForClub: 'loadLatestSubscriptionForClub',
    }),
    ...mapActions('pin', {
      clearPinData: 'clearPinData',
    }),
    async toggleVisibleParticipantField(fieldName) {
      this.toggleVisibleField(fieldName);
    },
    async onNewParticipant(participant) {
      let club = await this.getSelectedClub;
      participant.clubId = club._id;
      this.toggleAddParticipantForm();
      this.saveParticipant({
        participantToSave: participant,
        loginTokenAssembly: await this.getLoginTokenAssembly,
        clubTokenAssembly: await club.clubTokenAssembly,
        subscriptionTokenAssembly: await this
          .getLatestSubscriptionTokenAssembly,
      });
    },
    async onNewClub(club) {
      this.toggleAddClubForm();
      let loginTokenAssembly = await this.getLoginTokenAssembly;
      await this.saveClub({
        loginTokenAssembly: loginTokenAssembly,
        club: club,
      });
      if (this.getClubs.length > 0) {
        await this.clearPinData();
        let selectedClub = await this.getSelectedClub;
        this.loadScores({
          loginTokenAssembly: loginTokenAssembly,
          clubTokenAssembly: selectedClub.clubTokenAssembly,
        });
        this.loadParticipants({
          loginTokenAssembly: loginTokenAssembly,
          club: selectedClub,
        });
        this.loadLatestSubscriptionForClub({
          loginTokenAssembly: loginTokenAssembly,
          clubTokenAssembly: selectedClub.clubTokenAssembly,
        });
      }
    },
    async viewParticipantSummaryButton(participant) {
      this.$root.$emit('bv::hide::popover');
      this.setSelectedClubById('');
      await this.setSelectedParticipantSummaryById(participant._id);
      await this.clearPinData();

      let loginTokenAssembly = await this.getLoginTokenAssembly;
      this.loadScoresForSummaries({
        loginTokenAssembly: loginTokenAssembly,
        participantSummaryTokenAssemblies: [
          participant.participantTokenAssembly,
        ],
      });
    },

    async viewClubButton(club) {
      this.$root.$emit('bv::hide::popover');
      let loginTokenAssembly = await this.getLoginTokenAssembly;
      this.setSelectedParticipantSummaryById('');
      await this.setSelectedClubById(club._id);
      await this.clearPinData();
      let selectedClub = await this.getSelectedClub;
      this.loadScores({
        loginTokenAssembly: loginTokenAssembly,
        clubTokenAssembly: selectedClub.clubTokenAssembly,
      });
      this.loadParticipants({
        loginTokenAssembly: loginTokenAssembly,
        club: selectedClub,
      });
      this.loadLatestSubscriptionForClub({
        loginTokenAssembly: loginTokenAssembly,
        clubTokenAssembly: selectedClub.clubTokenAssembly,
      });
    },
    async selectAllParticipantGroups() {
      let groups = ['No Groups'];
      let selectedClub = await this.getSelectedClub;
      if (selectedClub.participantGroups !== undefined) {
        groups = groups.concat(
          selectedClub.participantGroups.map((x) => x._id)
        );
      }
      this.setVisibleParticipantGroups(groups);
    },
    deselectAllParticipantGroups() {
      this.setVisibleParticipantGroups([]);
    },
    toggleEditClubForm() {
      this.showEditClubForm = !this.showEditClubForm;
    },
    async toggleAddParticipantFormButtonClicked() {
      this.$root.$emit('bv::hide::popover');
      let club = await this.getSelectedClub;
      if (club && (await this.isSubscriptionActive(club._id))) {
        this.toggleAddParticipantForm();
      } else {
        this.toastError('You cannot add archers to an inactive club.');
      }
    },
    async toggleSidebarFilterControlButtonClicked() {
      this.$root.$emit('bv::hide::popover');
    },
    async onUpdateClub(updatedClub) {
      this.toggleEditClubForm();
      let loginTokenAssembly = await this.getLoginTokenAssembly;
      await this.saveClub({
        loginTokenAssembly: loginTokenAssembly,
        club: updatedClub,
      });
      if (this.getClubs.length > 0) {
        await this.clearPinData();
        let selectedClub = await this.getSelectedClub;
        this.loadScores({
          loginTokenAssembly: loginTokenAssembly,
          clubTokenAssembly: selectedClub.clubTokenAssembly,
        });
        this.loadParticipants({
          loginTokenAssembly: loginTokenAssembly,
          club: selectedClub,
        });
        this.loadLatestSubscriptionForClub({
          loginTokenAssembly: loginTokenAssembly,
          clubTokenAssembly: selectedClub.clubTokenAssembly,
        });
      }
    },
    toggleAddClubFormButton() {
      this.$root.$emit('bv::hide::popover');
      this.toggleAddClubForm();
    },
    orderByFirstName() {
      this.orderNameToggle = ['firstName', 'lastName'];
    },
    orderByLastName() {
      this.orderNameToggle = ['lastName', 'firstName'];
    },
    sortAtoZ() {
      this.sortToggle = ['asc', 'asc'];
    },
    sortZtoA() {
      this.sortToggle = ['desc', 'desc'];
    },
    setDisplayParticipantType(value) {
      this.$root.$emit('bv::hide::popover');
      this.setCachedDisplayParticipantType(value);
    },
  },
};
</script>
<style scoped>
fieldset {
  border: 1px solid silver;
  border-radius: 10px;
  padding: 0px 0px 0px 40px;
  margin: 3px 0px 0px 4px;
  display: table;
  width: 95%;
  *display: inline; /* ie6/7 hack for inline block */
  *width: auto; /* ie 6/7 */
  zoom: 1; /* ie6/7 hack for inline block */
  vertical-align: middle;
  text-align: left;
}

legend {
  margin: 0px 0px 0px 10px;
  width: auto;
}
</style>
