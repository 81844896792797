// import Vue from 'vue';
const uuidv4 = require('uuid').v4;

const state = () => ({
  participants: [{ _id: uuidv4(), name: '' }],
  bracket: [],
  isBracketStarted: false,
});

const getters = {
  isBracketStarted: (state) => {
    return state.isBracketStarted;
  },
  participants: (state) => {
    return state.participants;
  },
  bracket: (state) => {
    return state.bracket;
  },
};

const actions = {
  setIsBracketStarted({ commit }, value) {
    commit('setIsBracketStarted', value);
  },
  setBracket({ commit }, value) {
    commit('setBracket', value);
  },
  setParticipants({ commit }, value) {
    commit('setParticipants', value);
  },
  async addParticipant({ commit }, value) {
    commit('addParticipant', value);
  },
  removeParticipantAt({ commit }, value) {
    commit('removeParticipantAt', value);
  },
  setBattleWinner({ commit }, setBattleWinnerRequest) {
    commit('setBattleWinner', setBattleWinnerRequest);
  },
  setBattleChallengerA({ commit }, setBattleChallengerARequest) {
    commit('setBattleChallengerA', setBattleChallengerARequest);
  },
  setBattleChallengerB({ commit }, setBattleChallengerBRequest) {
    commit('setBattleChallengerB', setBattleChallengerBRequest);
  },
  startBracket({ commit }) {
    commit('startBracket');
  },
};

const mutations = {
  setIsBracketStarted(state, value) {
    state.isBracketStarted = value;
  },
  setBracket(state, value) {
    state.bracket = value;
  },
  setParticipants(state, value) {
    state.participants = value;
  },
  addParticipant(state, value) {
    state.participants.push(value);
  },
  removeParticipantAt(state, value) {
    state.participants.splice(value, 1);
  },
  setBattleWinner(state, value) {
    console.log('setBattleWiner: ' + JSON.stringify(value));
    let reloadedBattle = state.bracket.filter(
      (x) =>
        x.level === value.battle.level && x.sequence === value.battle.sequence
    )[0];
    console.log(JSON.stringify(reloadedBattle));
    reloadedBattle.winner = value.winner;
  },
  setBattleChallengerA(state, value) {
    console.log('setBattleChallengerA: ' + JSON.stringify(value));
    let reloadedBattle = state.bracket.filter(
      (x) =>
        x.level === value.battle.level && x.sequence === value.battle.sequence
    )[0];
    console.log(JSON.stringify(reloadedBattle));
    reloadedBattle.challengerA = value.challenger;
  },
  setBattleChallengerB(state, value) {
    console.log('setBattleChallengerB: ' + JSON.stringify(value));
    let reloadedBattle = state.bracket.filter(
      (x) =>
        x.level === value.battle.level && x.sequence === value.battle.sequence
    )[0];
    console.log(JSON.stringify(reloadedBattle));
    reloadedBattle.challengerB = value.challenger;
  },
  startBracket(state) {
    // Remove blanks from participant list
    let goodParticipantsInList = [];
    for (let i = state.participants.length - 1; i >= 0; i--) {
      let part = state.participants[i];
      // TODO: check for blanks or series of blanks without being lazy about it
      if (
        part.name !== undefined &&
        part.name !== null &&
        part.name !== '' &&
        part.name !== ' ' &&
        part.name !== '  '
      ) {
        goodParticipantsInList.push(part);
      } else {
        state.participants.splice(i, 1);
      }
    }

    // Shuffle the good participants list
    for (let i = goodParticipantsInList.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [goodParticipantsInList[i], goodParticipantsInList[j]] = [
        goodParticipantsInList[j],
        goodParticipantsInList[i],
      ];
    }

    let totalCount = goodParticipantsInList.length;
    let emptyLevelCount = 0;
    for (let power = 0; totalCount > Math.pow(2, power); power++) {
      // this number will be correct when we stop iterating.
      emptyLevelCount = power;
      // loop safety
      if (power === 100) {
        break;
      }
    }

    // two people per group into a bracket
    let sequence = 0;
    for (let i = 0; i < goodParticipantsInList.length; i += 2) {
      let a = goodParticipantsInList[i];
      let b = { _id: uuidv4(), name: 'BYE' };
      if (goodParticipantsInList.length >= i + 2) {
        b = goodParticipantsInList[i + 1];
      }

      let battle = {
        level: emptyLevelCount,
        sequence: sequence,
        challengerA: a,
        challengerB: b,
        winner: null,
      };
      state.bracket.push(battle);

      sequence++;
    }

    // for each level loop over each battle and add a battle in the next level, setting TBD or BYE for each
    for (let i = emptyLevelCount + 1; i > 0; i--) {
      let battlesInLevel = state.bracket.filter((x) => x.level === i);
      for (let battle of battlesInLevel) {
        // check for battle of matching sequence in next level
        let nextSequence = Math.floor(battle.sequence / 2);
        let nextLevel = i - 1;
        let existingBattles = state.bracket.filter(
          (x) => x.level === nextLevel && x.sequence === nextSequence
        );
        if (existingBattles.length === 1) {
          existingBattles[0].challengerB = { name: 'TBD' };
        } else {
          state.bracket.push({
            level: nextLevel,
            sequence: nextSequence,
            challengerA: { name: 'TBD' },
            challengerB: { name: 'BYE' },
            winner: null,
          });
        }
      }
    }

    state.isBracketStarted = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
