import participantApi from '../../api/participantapi';

// Remember if you add something here to update the index.js persistedState
const state = () => ({
  participants: [],
  participantSummaries: [],
  participantsLoaded: false,
  participantSummariesLoaded: false,
  showAddForm: false,
  participantLoadError: null,
  participantSummariesLoadError: null,
  isParticipantLoadProcessing: false,
  isParticipantSummariesLoadProcessing: false,
  selectedParticipantSummaryId: null,

  visibleClassifications: ['Adult', 'JOAD'],
  visibleUSAAMemberships: ['Has Membership', 'Missing Membership'],
  visibleArchiveFlags: ['Not Archived'],
  visibleParticipantGroups: [],
  participantGroupFilterType: 'AND',
  filteredParticipants: [],
  visibleTableParticipantFields: {
    highestEarnedPins: true,
    nextPins: true,
    indoor: false,
    outdoor: true,
    buttons: false,
    scoreList: false,
    classification: false,
    bowType: false,
    participantGroups: true,
    hasUSAAMembership: true,
    emergencyContact: false,
    equipmentNote: false,
    note: false,
    accessAccounts: false,
  },
  visibleCardParticipantFields: {
    highestEarnedPins: true,
    nextPins: true,
    indoor: false,
    outdoor: true,
    buttons: true,
    scoreList: false,
    classification: true,
    bowType: true,
    participantGroups: true,
    hasUSAAMembership: true,
    emergencyContact: true,
    equipmentNote: true,
    note: true,
    accessAccounts: false,
  },
  displayParticipantType: 'table',
});

const getters = {
  visibleParticipantFields: (state) => {
    // Caution. this object return binds the values to an object reference. this is dangerous as changes not made through mutations are not persisted and could cause unintended user interactions.
    // initial values correspond to table view
    if (state.displayParticipantType === 'table') {
      return state.visibleTableParticipantFields;
    } else if (state.displayParticipantType === 'card') {
      return state.visibleCardParticipantFields;
    } else {
      return {};
    }
  },
  displayParticipantType: (state) => {
    return state.displayParticipantType;
  },
  areParticipantsLoaded: (state) => {
    return state.participantsLoaded;
  },
  areParticipantSummariesLoaded: (state) => {
    return state.participantSummariesLoaded;
  },
  isParticipantLoadProcessing: (state) => {
    return state.isParticipantLoadProcessing;
  },
  isParticipantSummariesLoadProcessing: (state) => {
    return state.isParticipantSummariesLoadProcessing;
  },
  visibleClassifications: (state) => {
    return state.visibleClassifications;
  },
  visibleUSAAMemberships: (state) => {
    return state.visibleUSAAMemberships;
  },
  visibleArchiveFlags: (state) => {
    return state.visibleArchiveFlags;
  },
  visibleParticipantGroups: (state) => {
    return state.visibleParticipantGroups;
  },
  participantGroupFilterType: (state) => {
    return state.participantGroupFilterType;
  },
  getAllParticipants: (state) => {
    return state.participants;
  },
  getParticipantSummaries: (state) => {
    return state.participantSummaries;
  },
  getSelectedParticipantSummary: (state) => {
    let indexOfExisting = state.participantSummaries.findIndex(
      (x) => x._id === state.selectedParticipantSummaryId
    );

    if (indexOfExisting === -1) {
      return undefined;
    } else {
      return state.participantSummaries[indexOfExisting];
    }
  },
  countByClassification: (state) => (classification) => {
    let baseFilterResult = state.participants.filter(
      (p) => p.classification === classification
    );

    // counts for classification, participantGroup, and USAAMembership also filter for currently visible archived
    if (state.visibleArchiveFlags.length === 2) {
      return baseFilterResult.length;
    } else if (
      state.visibleArchiveFlags.length === 1 &&
      state.visibleArchiveFlags[0] === 'Not Archived'
    ) {
      return baseFilterResult.filter((p) => p.isArchived === false).length;
    } else if (
      state.visibleArchiveFlags.length === 1 &&
      state.visibleArchiveFlags[0] === 'Archived'
    ) {
      return baseFilterResult.filter((p) => p.isArchived === true).length;
    } else {
      return 0;
    }
  },
  countByParticipantGroup: (state) => (participantGroupId) => {
    let baseFilterResult = [];

    if (participantGroupId === 'No Groups') {
      baseFilterResult = state.participants.filter(
        (p) => p.participantGroups.length === 0
      );
    } else {
      baseFilterResult = state.participants.filter((p) =>
        p.participantGroups.includes(participantGroupId)
      );
    }

    // counts for classification, participantGroup, and USAAMembership also filter for currently visible archived
    if (state.visibleArchiveFlags.length === 2) {
      return baseFilterResult.length;
    } else if (
      state.visibleArchiveFlags.length === 1 &&
      state.visibleArchiveFlags[0] === 'Not Archived'
    ) {
      return baseFilterResult.filter((p) => p.isArchived === false).length;
    } else if (
      state.visibleArchiveFlags.length === 1 &&
      state.visibleArchiveFlags[0] === 'Archived'
    ) {
      return baseFilterResult.filter((p) => p.isArchived === true).length;
    } else {
      return 0;
    }
  },
  countByUSAAMembership: (state) => (availableUSAAMembership) => {
    let booleanConversion =
      availableUSAAMembership === 'Has Membership' ? true : false;
    let baseFilterResult = state.participants.filter(
      (p) => p.hasUSAAMembership === booleanConversion
    );

    // counts for classification, participantGroup, and USAAMembership also filter for currently visible archived
    if (state.visibleArchiveFlags.length === 2) {
      return baseFilterResult.length;
    } else if (
      state.visibleArchiveFlags.length === 1 &&
      state.visibleArchiveFlags[0] === 'Not Archived'
    ) {
      return baseFilterResult.filter((p) => p.isArchived === false).length;
    } else if (
      state.visibleArchiveFlags.length === 1 &&
      state.visibleArchiveFlags[0] === 'Archived'
    ) {
      return baseFilterResult.filter((p) => p.isArchived === true).length;
    } else {
      return 0;
    }
  },
  countByArchived: (state) => (availableArchiveFlag) => {
    let booleanConversion = availableArchiveFlag === 'Archived' ? true : false;
    return state.participants.filter((p) => p.isArchived === booleanConversion)
      .length;
  },
  showAddForm: (state) => {
    return state.showAddForm;
  },
  getAvailableClassifications: () => {
    return ['Adult', 'JOAD'];
  },
  getAvailableUSAAMemberships: () => {
    return ['Has Membership', 'Missing Membership'];
  },
  getAvailableArchiveFlags: () => {
    return ['Not Archived', 'Archived'];
  },
  getParticipantById: (state) => (participant_id) => {
    let result = state.participants.filter((p) => p._id === participant_id);
    if (result.length == 0) {
      return null;
    }
    return result[0];
  },
  getFilteredParticipants: (state) => {
    return state.filteredParticipants;
  },
};

const actions = {
  clearParticipantData: ({ commit }) => {
    commit('clearData');
  },
  toggleAddParticipantForm: ({ commit }) => {
    commit('toggleAddParticipantForm');
  },
  setParticipantGroupFilterType: ({ commit }, value) => {
    commit('setParticipantGroupFilterType', value);
    commit('updateFilteredParticipants');
  },

  setCachedDisplayParticipantType: ({ commit }, value) => {
    commit('setDisplayParticipantType', value);
  },

  loadParticipantSummaries: async (
    { commit, dispatch },
    loadParticipantRequest
  ) => {
    try {
      console.time('participant.module.actions.loadParticipantSummaries');
      await commit('clearParticipantSummaryData');
      commit('setParticipantSummariesLoadProcessing', true);

      let loginTokenAssembly = loadParticipantRequest.loginTokenAssembly;

      let result = await participantApi.getParticipantSummariesForLogin(
        loginTokenAssembly
      );
      if (result.responseCode === 'normal') {
        for (let participant of result.participantSummaries || []) {
          commit('pushParticipantSummary', participant);
          commit('setParticipantSummariesLoaded');
        }
      } else {
        console.log(
          'There was a problem loading the participantSummaries. ' +
            JSON.stringify(result)
        );
        dispatch(
          'toast',
          {
            type: 'error',
            displayText:
              'There was a problem loading the participant data. Please refresh the page and log out and in.',
          },
          { root: true }
        );
      }
      commit('setParticipantSummariesLoadProcessing', false);
    } catch (err) {
      commit('setParticipantSummariesLoadError', err);
      dispatch(
        'toast',
        {
          type: 'error',
          displayText:
            'There was a problem loading the participant data. Please refresh the page and log out and in.',
        },
        { root: true }
      );
      commit('setParticipantSummariesLoadProcessing', false);
    } finally {
      console.timeEnd('participant.module.actions.loadParticipantSummaries');
    }
  },
  loadParticipants: async (
    { commit, dispatch, state },
    loadParticipantRequest
  ) => {
    try {
      console.time('participant.module.actions.loadParticipants');
      await commit('clearParticipantData');
      commit('setParticipantLoadProcessing', true);

      let loginTokenAssembly = loadParticipantRequest.loginTokenAssembly;
      let club = loadParticipantRequest.club;
      let clubTokenAssembly = club.clubTokenAssembly;

      // only set the visible participant groups if this is the first time on this device
      // TODO: things may look odd if you are toggling between multiple clubs. make this club specific data in this participant lookup... i guess. TODO
      if (state.visibleParticipantGroups.length <= 1) {
        let participantGroups = ['No Groups'];
        if (club.participantGroups !== undefined) {
          participantGroups = participantGroups.concat(
            club.participantGroups.map((x) => x._id)
          );
        }
        // Stop setting this, we have the "and" option set by default. if no groups are selected then all groups will show. this means if the filter is reset then the user will start with all visible and just need to toggle a single option.
        //commit('setVisibleParticipantGroups', participantGroups);
      }

      let result = await participantApi.getAllParticipantsForClub(
        loginTokenAssembly,
        clubTokenAssembly
      );
      if (result.responseCode === 'normal') {
        for (let participant of result.participants || []) {
          if (participant.participantGroups === undefined) {
            participant.participantGroups = [];
          }
          commit('pushParticipant', participant);
          commit('setParticipantsLoaded');
        }
      } else {
        console.log(
          'There was a problem loading the participants. ' +
            JSON.stringify(result)
        );
        dispatch(
          'toast',
          {
            type: 'error',
            displayText:
              'There was a problem loading the participants. Please refresh the page and log out and in.',
          },
          { root: true }
        );
      }
      commit('setParticipantLoadProcessing', false);
    } catch (err) {
      commit('setParticipantLoadError', err);
      dispatch(
        'toast',
        {
          type: 'error',
          displayText:
            'There was a problem loading the participants. Please refresh the page and log out and in.',
        },
        { root: true }
      );
      commit('setParticipantLoadProcessing', false);
    } finally {
      commit('updateFilteredParticipants');
      console.timeEnd('participant.module.actions.loadParticipants');
    }
  },

  saveParticipant: async ({ commit, dispatch }, saveParticipantRequest) => {
    try {
      console.time('participant.modules.actions.saveParticipant');

      let participant = saveParticipantRequest.participantToSave;
      let loginTokenAssembly = saveParticipantRequest.loginTokenAssembly;
      let clubTokenAssembly = saveParticipantRequest.clubTokenAssembly;
      let subscriptionTokenAssembly =
        saveParticipantRequest.subscriptionTokenAssembly;

      dispatch(
        'toast',
        {
          type: 'info',
          displayText: `Saving ${participant.firstName} ${participant.lastName}.`,
        },
        { root: true }
      );

      let results = await participantApi.saveParticipant(
        participant,
        loginTokenAssembly,
        clubTokenAssembly,
        subscriptionTokenAssembly
      );
      if (results.responseCode === 'normal') {
        for (let result of results.participants) {
          commit('pushParticipant', result.participant);
          let alertText = '';
          let logText = '';
          if (result.responseCode == 'conflicted') {
            alertText =
              `Another coach has edited ${result.participant.firstName} ${result.participant.lastName}` +
              " before you. They've been updated with the newer data. Please reapply your edits.";
            logText = alertText;
          } else if (
            result.responseCode == 'conflictedError' ||
            result.responseCode == 'error'
          ) {
            alertText =
              'Error Saving ' +
              result.participant.firstName +
              ' ' +
              result.participant.lastName +
              '. Please reload the page and make your edits again.';
            logText =
              alertText +
              ' Response Code: ' +
              result.responseCode +
              ' Error: ' +
              result.error;
          } else if (
            result.responseCode != undefined &&
            result.responseCode != 'normal'
          ) {
            alertText =
              'An unknown response was returned when saving participant. Please reload the page.';
            logText = 'Unknown response code.' + JSON.stringify(result);
          }

          if (alertText !== '') {
            console.log(logText);
            dispatch(
              'toast',
              {
                type: 'error',
                displayText: alertText,
              },
              { root: true }
            );
          } else {
            dispatch(
              'toast',
              {
                type: 'success',
                displayText: `${participant.firstName} ${participant.lastName} saved successfully.`,
              },
              { root: true }
            );
          }
        }
      } else {
        console.log(
          'there was a problem saving the participant ' +
            JSON.stringify(results)
        );
        dispatch(
          'toast',
          {
            type: 'error',
            displayText: `Error saving ${participant.firstName} ${participant.lastName}, please try logging out and back in.`,
          },
          { root: true }
        );
      }
    } catch (err) {
      commit('setParticipantLoadError', err);
      dispatch(
        'toast',
        {
          type: 'error',
          displayText: `Error saving participant, please try logging out and back in..`,
        },
        { root: true }
      );
    } finally {
      commit('updateFilteredParticipants');
      console.timeEnd('participant.modules.actions.saveParticipant');
    }
  },
  setVisibleClassifications: ({ commit }, visibleClassifications) => {
    // Short Circuit some jerky vuex logic
    if (visibleClassifications != state.visibleClassifications) {
      commit('setVisibleClassifications', visibleClassifications);
      commit('updateFilteredParticipants');
    }
  },
  setVisibleUSAAMemberships: ({ commit }, visibleUSAAMemberships) => {
    // Short Circuit some jerky vuex logic
    if (visibleUSAAMemberships != state.visibleUSAAMemberships) {
      commit('setVisibleUSAAMemberships', visibleUSAAMemberships);
      commit('updateFilteredParticipants');
    }
  },
  setVisibleArchiveFlags: ({ commit }, visibleArchiveFlags) => {
    // Short Circuit some jerky vuex logic
    if (visibleArchiveFlags != state.visibleArchiveFlags) {
      commit('setVisibleArchiveFlags', visibleArchiveFlags);
      commit('updateFilteredParticipants');
    }
  },
  setVisibleParticipantGroups: ({ commit, state }, visibleParticipantGroups) => {
    // Short Circuit some jerky vuex logic
    if (visibleParticipantGroups != state.visibleParticipantGroups) {
      commit('setVisibleParticipantGroups', visibleParticipantGroups);
      commit('updateFilteredParticipants');
    }
  },

  setSelectedParticipantSummaryById: (
    { commit, state },
    participantSummaryId
  ) => {
    let indexOfExisting = state.participantSummaries.findIndex(
      (x) => x._id === participantSummaryId
    );

    if (indexOfExisting >= -1 || participantSummaryId === '') {
      commit('setSelectedParticipantSummaryById', participantSummaryId);
    } else {
      // didn't find it, yell at them
      console.log(
        'somehow we were asked to set the selected participant summary to a participant summary we do not have in the list of participant summaries'
      );
    }
  },

  toggleVisibleField: async ({ commit }, fieldName) => {
    commit('toggleVisibleField', fieldName);
  },
};

const mutations = {
  clearData: (state) => {
    state.participants = [];
    state.participantSummaries = [];
    state.participantsLoaded = false;
    state.participantSummariesLoaded = false;
    state.isParticipantLoadProcessing = false;
    state.isParticipantSummariesLoadProcessing = false;
    state.selectedParticipantSummaryId = '';
    state.showAddForm = false;
    state.participantLoadError = null;
    state.participantSummariesLoadError = null;
    state.filteredParticipants = [];
  },

  clearParticipantData: (state) => {
    state.participants = [];
    state.participantsLoaded = false;
    state.isParticipantLoadProcessing = false;
    state.showAddForm = false;
    state.participantLoadError = null;
    state.filteredParticipants = [];
  },

  clearParticipantSummaryData: (state) => {
    state.participantSummaries = [];
    state.participantSummariesLoaded = false;
    state.isParticipantSummariesLoadProcessing = false;
    state.participantSummariesLoadError = null;
  },

  setParticipantLoadProcessing: (state, value) => {
    state.isParticipantLoadProcessing = value;
  },

  setParticipantSummariesLoadProcessing: (state, value) => {
    state.isParticipantSummariesLoadProcessing = value;
  },

  toggleAddParticipantForm: (state) => {
    state.showAddForm = !state.showAddForm;
  },

  setSelectedParticipantSummaryById: (state, participantSummaryId) => {
    state.selectedParticipantSummaryId = participantSummaryId;
  },

  toggleVisibleField: (state, fieldName) => {
    if (state.displayParticipantType === 'table') {
      state.visibleTableParticipantFields[fieldName] = !state
        .visibleTableParticipantFields[fieldName];
    } else if (state.displayParticipantType === 'card') {
      state.visibleCardParticipantFields[fieldName] = !state
        .visibleCardParticipantFields[fieldName];
    } else {
      // There are no visibility toggles for display types other than 'table' or 'card'
    }
  },

  setParticipantsLoaded: (state) => {
    if (state.participantsLoaded === false) {
      state.participantsLoaded = true;
    }
  },

  setParticipantSummariesLoaded: (state) => {
    if (state.participantSummariesLoaded === false) {
      state.participantSummariesLoaded = true;
    }
  },

  setDisplayParticipantType: (state, value) => {
    state.displayParticipantType = value;
  },

  pushParticipant: (state, participantToPush) => {
    // check for existing with same ID and replace
    let indexOfExisting = state.participants.findIndex(
      (x) => x._id === participantToPush._id
    );

    if (indexOfExisting === -1) {
      state.participants.push(participantToPush);
    } else {
      state.participants.splice(indexOfExisting, 1, participantToPush);
    }
  },

  pushParticipantSummary: (state, participantSummaryToPush) => {
    // check for existing with same ID and replace
    let indexOfExisting = state.participantSummaries.findIndex(
      (x) => x._id === participantSummaryToPush._id
    );

    if (indexOfExisting === -1) {
      state.participantSummaries.push(participantSummaryToPush);
    } else {
      state.participantSummaries.splice(
        indexOfExisting,
        1,
        participantSummaryToPush
      );
    }
  },

  setParticipantLoadError: (state, loadError) => {
    state.participantLoadError = loadError;
  },

  setParticipantSummariesLoadError: (state, loadError) => {
    state.participantSummariesLoadError = loadError;
  },

  setParticipantGroupFilterType: (state, value) => {
    state.participantGroupFilterType = value;
  },

  setVisibleClassifications: (state, payload) => {
    if (Array.isArray(payload)) {
      state.visibleClassifications = payload;
    } else {
      throw new Error(
        'setVisibleClassifications mutation payload must be an array, but it is not'
      );
    }
  },

  setVisibleUSAAMemberships: (state, payload) => {
    if (Array.isArray(payload)) {
      state.visibleUSAAMemberships = payload;
    } else {
      throw new Error(
        'setVisibleUSAAMemberships mutation payload must be an array, but it is not'
      );
    }
  },

  setVisibleArchiveFlags: (state, payload) => {
    if (Array.isArray(payload)) {
      state.visibleArchiveFlags = payload;
    } else {
      throw new Error(
        'setVisibleArchiveFlags mutation payload must be an array, but it is not'
      );
    }
  },

  setVisibleParticipantGroups: (state, payload) => {
    if (Array.isArray(payload)) {
      state.visibleParticipantGroups = payload;
    } else {
      throw new Error(
        'setVisibleParticipantGroups mutation payload must be an array, but it is not'
      );
    }
  },

  updateFilteredParticipants: (state) => {
    console.time('participant.module.mutations.updateFilteredParticipants');
    let filteredParticipants = state.participants;

    // filter by archived
    if (state.visibleArchiveFlags.indexOf('Not Archived') === -1) {
      filteredParticipants = filteredParticipants.filter((p) => p.isArchived);
    }
    if (state.visibleArchiveFlags.indexOf('Archived') === -1) {
      filteredParticipants = filteredParticipants.filter((p) => !p.isArchived);
    }

    // filter usaa memberships
    if (state.visibleUSAAMemberships.indexOf('Has Membership') === -1) {
      filteredParticipants = filteredParticipants.filter(
        (p) => !p.hasUSAAMembership
      );
    }
    if (state.visibleUSAAMemberships.indexOf('Missing Membership') === -1) {
      filteredParticipants = filteredParticipants.filter(
        (p) => p.hasUSAAMembership
      );
    }

    // filter by classification
    filteredParticipants = filteredParticipants.filter((p) =>
      state.visibleClassifications.includes(p.classification)
    );

    // Filter Participant Groups
    let remainder = [];
    if (state.participantGroupFilterType === 'AND') {
      for (let participant of filteredParticipants) {
        if (
          participant.participantGroups.filter((x) =>
            state.visibleParticipantGroups.includes(x)
          ).length === state.visibleParticipantGroups.length ||
          (state.visibleParticipantGroups.includes('No Groups') &&
            participant.participantGroups.length === 0 &&
            state.visibleParticipantGroups.length === 1)
        ) {
          remainder.push(participant);
        }
      }
    } else {
      for (let participant of filteredParticipants) {
        for (let visibleParticipantGroup of state.visibleParticipantGroups ||
          []) {
          if (participant.participantGroups.includes(visibleParticipantGroup)) {
            remainder.push(participant);
            break;
          } else if (
            visibleParticipantGroup === 'No Groups' &&
            (participant.participantGroups === undefined ||
              participant.participantGroups.length === 0)
          ) {
            remainder.push(participant);
            break;
          }
        }
      }
    }
    filteredParticipants = distinct(remainder);

    // TODO: store the participant IDs and have the getter return the participants with those ids. and then we can also put filteredParticipants back in the general participant PersistedState bucket
    state.filteredParticipants = filteredParticipants;
    console.timeEnd('participant.module.mutations.updateFilteredParticipants');
  },
};

function distinct(items, mapper) {
  if (!mapper) mapper = (item) => item;
  return items.map(mapper).reduce((acc, item) => {
    if (acc.indexOf(item) === -1) acc.push(item);
    return acc;
  }, []);
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
