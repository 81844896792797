import axios from 'axios';

export default {
  tryLogin: (_id, encryptedPassword, publicSigningKey) =>
    new Promise((resolve, reject) => {
      let credentials = {
        _id: _id,
        password: encryptedPassword,
        publicSigningKey: publicSigningKey,
      };
      let path = '/api/login/';
      axios
        .post(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .post(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
  tryCreateLogin: (
    _id,
    encryptedPassword,
    firstName,
    lastName,
    publicSigningKey
  ) =>
    new Promise((resolve, reject) => {
      let credentials = {
        _id: _id,
        password: encryptedPassword,
        firstName: firstName,
        lastName: lastName,
        publicSigningKey: publicSigningKey,
      };
      let path = '/api/login/create/';
      axios
        .post(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .post(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
  tryResetPassword: (_id, encryptedPassword, publicSigningKey) =>
    new Promise((resolve, reject) => {
      let credentials = {
        _id: _id,
        password: encryptedPassword,
        publicSigningKey: publicSigningKey,
      };
      let path = '/api/login/resetpassword/';
      axios
        .post(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .post(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
  getEncryptionKey: () =>
    new Promise((resolve, reject) => {
      let path = '/api/login/publicKey/';
      axios
        .post(path)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .post(path)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
  loadLoginAttempts: (loginTokenAssembly) =>
    new Promise((resolve, reject) => {
      let credentials = {
        loginTokenAssembly: loginTokenAssembly,
      };
      let path = '/api/login/attempts/';
      axios
        .post(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .post(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
  confirmLogin: (_id, verificationCode) =>
    new Promise((resolve, reject) => {
      let credentials = {
        _id: _id,
        verificationCode,
      };
      let path = '/api/login/confirmCreateLogin/';
      axios
        .post(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .post(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
};
