<template>
  <div class="score">
    <div class="row">
      <div class="col">
        {{ score.bowType }} <br />
        {{ score.scoredDate }} | {{ score.classification }} | {{ score.local }}
        <br />
        dist: {{ score.distance }} | {{ score.targetSize }} cm |
        {{ score.points }} pts
        <PinIcon
          v-if="
            getEarnedPinForParticipantAndScore(score.participant_id, score._id)
          "
          :pinColor="
            pinColor(
              getEarnedPinForParticipantAndScore(
                score.participant_id,
                score._id
              ).pinLevel
            )
          "
          :pinLevel="
            getEarnedPinForParticipantAndScore(score.participant_id, score._id)
              .pinLevel
          "
        />
        <span
          v-if="
            (score.xCount !== undefined && score.xCount !== null) ||
            (score.tenCount !== undefined && score.tenCount !== null) ||
            (score.nineCount !== undefined && score.nineCount !== null)
          "
        >
          <br />
          Xs: {{ score.xCount }} | 10s: {{ score.tenCount }} | 9s:
          {{ score.nineCount }}
        </span>
        <b-button
          variant="outline-info"
          v-on:click="toggleEditScoreForm"
          :size="globalComponentSize"
        >
          <PencilIcon style="width: 25px" />
        </b-button>
      </div>
    </div>
    <b-modal
      :id="'bv-modal-edit-score' + score._id"
      hide-footer
      :static="true"
      v-if="showEditScoreForm"
      v-model="showEditScoreForm"
    >
      <template v-slot:modal-title>Edit Score</template>
      <EditScoreForm
        v-if="showEditScoreForm"
        :score="score"
        v-on:updateScore="onUpdateScore"
        v-on:cancel="toggleEditScoreForm()"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EditScoreForm from './EditScoreForm';
import PencilIcon from './PencilIcon';
import _ from 'lodash';
import PinIcon from '../components/PinIcon';

export default {
  name: 'ScoreCard',
  props: {
    score: Object,
  },
  components: {
    EditScoreForm,
    PencilIcon,
    PinIcon,
  },
  data: () => {
    return {
      showEditScoreForm: false,
    };
  },
  methods: {
    ...mapActions('score', {
      saveScore: 'saveScore',
    }),
    ...mapActions('sitewide', {
      toastError: 'toastError',
    }),
    async toggleEditScoreForm() {
      let club = await this.getSelectedClub;
      if (club && (await this.isSubscriptionActive(club._id))) {
        this.showEditScoreForm = !this.showEditScoreForm;
      } else {
        this.toastError(
          'You cannot edit scores of participants in an incative club.'
        );
      }
    },
    async onUpdateScore(updatedScore) {
      this.toggleEditScoreForm();
      let club = await this.getSelectedClub;
      updatedScore.clubId = club._id;

      this.saveScore({
        scoreToSave: updatedScore,
        loginTokenAssembly: await this.getLoginTokenAssembly,
        clubTokenAssembly: await club.clubTokenAssembly,
        subscriptionTokenAssembly: await this
          .getLatestSubscriptionTokenAssembly,
      });
    },
    pinColor(pinLevel) {
      let option = this.getPinLevelInfo(pinLevel);
      if (option != null) {
        return option.pinColor;
      }
      return null;
    },
  },
  computed: {
    ...mapGetters('score', {
      getScoresByParticipantId: 'getScoresByParticipantId',
    }),
    ...mapGetters('club', {
      getSelectedClub: 'getSelectedClub',
    }),
    ...mapGetters('login', {
      getLoginTokenAssembly: 'getLoginTokenAssembly',
    }),
    ...mapGetters('pin', {
      getPinLevelInfo: 'getPinLevelInfo',
      getEarnedPinForParticipantAndScore: 'getEarnedPinForParticipantAndScore',
    }),
    ...mapGetters('subscription', {
      getLatestSubscriptionTokenAssembly: 'getLatestSubscriptionTokenAssembly',
      isSubscriptionActive: 'isSubscriptionActive',
    }),
    ...mapGetters('sitewide', ['globalComponentSize']),
    participantScores: function () {
      let scores = this.getScoresByParticipantId(this.participant._id);
      return _.orderBy(
        scores,
        ['scoredDate', 'createdDateUTC'],
        ['desc', 'desc']
      );
    },
  },
};
</script>
<style scoped></style>
