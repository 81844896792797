import feedbackApi from '../../api/feedback';
import _ from 'lodash';

const state = () => ({
  feedback: [],
  isFeedbackLoadProcessing: false,
  feedbackLoadError: '',
});

const getters = {
  getRatedFeedback: (state) => {
    let feedbackWithRatings = state.feedback.filter(
      (x) => x.rating !== undefined
    );
    let detractors = feedbackWithRatings.filter((x) => x.rating < 7);
    let promoters = feedbackWithRatings.filter((x) => x.rating > 8);
    let ratedFeedback = {
      totalRatings: feedbackWithRatings.length,
      detractorCount: detractors.length,
      promoterCount: promoters.length,
      nps:
        (promoters.length * 100) / feedbackWithRatings.length -
        (detractors.length * 100) / feedbackWithRatings.length,
      'last X months': '3',
    };
    // returning this as an array so it can be broken down by month over month for comparison later
    return [ratedFeedback];
  },
  getFeedback: (state) => {
    return _.orderBy(state.feedback, ['submitDate'], ['desc']);
  },
  isFeedbackLoadProcessing: (state) => {
    return state.isFeedbackLoadProcessing;
  },
};

const actions = {
  clearFeedback: ({ commit }) => {
    commit('clearData');
  },
  saveFeedback: async ({ dispatch }, newFeedback) => {
    try {
      console.time('feedback.modules.actions.saveFeedback');

      let rating = newFeedback.rating;
      let comment = newFeedback.comment;
      let loginTokenAssembly = newFeedback.loginTokenAssembly;

      dispatch(
        'toast',
        {
          type: 'info',
          displayText: `Submitting Feedback / Review.`,
        },
        { root: true }
      );

      let result = await feedbackApi.saveFeedback(
        rating,
        comment,
        loginTokenAssembly
      );

      if (
        result.responseCode === 'normal' &&
        result.feedback[0].responseCode === 'normal'
      ) {
        dispatch(
          'toast',
          {
            type: 'success',
            displayText: 'Feedback / Review submitted successfully.',
          },
          { root: true }
        );
      } else {
        let toastText = `There was a problem submitting Feedback / Review. Please try entering it again or contact support.`;
        console.log(toastText + ` ${JSON.stringify(result)}`);
        dispatch(
          'toast',
          { type: 'error', displayText: toastText },
          { root: true }
        );
      }
    } catch (err) {
      let toastText = `There was a problem submitting Feedback / Review. Please try entering it again or contact support.`;
      console.log(toastText);
      dispatch(
        'toast',
        { type: 'error', displayText: toastText },
        { root: true }
      );
    } finally {
      console.timeEnd('feedback.modules.actions.saveFeedback');
    }
  },
  loadFeedback: async ({ commit, dispatch }, loginTokenAssembly) => {
    try {
      console.time('feedback.module.actions.loadFeedback');
      commit('setFeedbackLoadProcessing', true);
      let result = await feedbackApi.loadFeedback(loginTokenAssembly);
      if (result.responseCode === 'normal') {
        commit('setFeedback', result.feedback);
      } else {
        console.log('there was a problem loading the feedback');
        dispatch(
          'toast',
          {
            type: 'error',
            displayText: `Error loading feedback/reviews, please try again.`,
          },
          { root: true }
        );
        commit(
          'setFeedbackLoadError',
          'Failed to load feedback/review data with loginTokenAssembly'
        );
      }
      commit('setFeedbackLoadProcessing', false);
    } catch (err) {
      dispatch(
        'toast',
        {
          type: 'error',
          displayText: `Error loading feedback/reviews, please try again.`,
        },
        { root: true }
      );
      commit('setFeedbackLoadError', err);
      commit('setFeedbackLoadProcessing', false);
    } finally {
      console.timeEnd('feedback.module.actions.loadFeedback');
    }
  },
};

const mutations = {
  clearData: (state) => {
    state.feedback = [];
    state.isFeedbackLoadProcessing = false;
    state.feedbackLoadError = '';
  },
  setFeedbackLoadError: (state, error) => {
    state.feedbackLoadError = error;
  },
  setFeedbackLoadProcessing: (state, value) => {
    state.isFeedbackLoadProcessing = value;
  },
  setFeedback: (state, value) => {
    state.feedback = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
