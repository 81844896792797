<template>
  <div>
    <b-form id="addParticipantForm" @submit="onSubmit" @reset="onCancel">
      <b-form-group>
        <b-input-group
          id="firstNameGroup"
          prepend="First Name*"
          :size="globalComponentSize"
        >
          <b-form-input
            id="firstName"
            v-model="form.firstName"
            required
            placeholder="Enter First Name"
            :autofocus="true"
          ></b-form-input>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group
          id="lastNameGroup"
          prepend="Last Name*"
          :size="globalComponentSize"
        >
          <b-form-input
            id="lastName"
            v-model="form.lastName"
            required
            placeholder="Enter Last Name"
          ></b-form-input>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group
          id="classificationGroup"
          prepend="Classification*"
          :size="globalComponentSize"
        >
          <b-form-radio-group
            id="classification"
            v-model="form.classification"
            :options="classifications"
            buttons
            button-variant="outline-primary"
            name="classification-btn-outline"
            required
            :size="globalComponentSize"
          ></b-form-radio-group>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group
          id="birthdateGroup"
          prepend="Birthdate"
          :size="globalComponentSize"
        >
          <b-form-input
            id="birthdate"
            type="date"
            v-model="form.birthdate"
          ></b-form-input>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group
          id="bowTypeGroup"
          prepend="Bow Type"
          :size="globalComponentSize"
        >
          <b-form-radio-group
            id="bowType"
            v-model="form.bowType"
            :options="bowTypes"
            buttons
            button-variant="outline-primary"
            :size="globalComponentSize"
          ></b-form-radio-group>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group
          id="USAAMemGroup"
          prepend="Has USA Archery Membership"
          :size="globalComponentSize"
          ><span style="margin-right: 10px" />
          <b-form-checkbox
            id="hasUSAAMem"
            switch
            v-model="form.hasUSAAMembership"
          ></b-form-checkbox>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group
          id="emergencyContactGroup"
          prepend="Emergency Contact"
          :size="globalComponentSize"
        >
          <b-form-textarea
            id="emergencyContact"
            type="text"
            v-model="form.emergencyContact"
          ></b-form-textarea>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group
          id="equipmentNoteGroup"
          prepend="Equipment Note"
          :size="globalComponentSize"
        >
          <b-form-textarea
            id="equipmentNote"
            type="text"
            v-model="form.equipmentNote"
          ></b-form-textarea>
        </b-input-group>
      </b-form-group>

      <b-form-group>
        <b-input-group
          id="notesGroup"
          prepend="General Notes"
          :size="globalComponentSize"
        >
          <b-form-textarea
            id="notes"
            type="text"
            v-model="form.notes"
          ></b-form-textarea>
        </b-input-group>
      </b-form-group>

      <b-form-group id="participantGroup" v-slot="{ ariaDescribedby }">
        <b-input-group prepend="Groups" :size="globalComponentSize">
          <span style="margin-right: 10px" />
          <b-form-checkbox-group
            v-model="participantGroups"
            :aria-describedby="ariaDescribedby"
            :size="globalComponentSize"
          >
            <b-form-checkbox
              v-for="participantGroup in getParticipantGroups"
              :key="participantGroup._id"
              :value="participantGroup._id"
              >{{ participantGroup.name }}</b-form-checkbox
            >
          </b-form-checkbox-group>
        </b-input-group>
      </b-form-group>

      <b-form-group id="accountAccess" label-for="accessAccounts">
        <b-input-group
          prepend="Access account (email)"
          :size="globalComponentSize"
        >
          <b-button
            id="btnAdd"
            v-on:click="addBasicAccessButtonClick"
            variant="outline-info"
            :size="globalComponentSize"
            >Add Access Account</b-button
          >
          <b-avatar
            id="participantAccessAccountsHelpIcon"
            size="sm"
            icon="question"
            button
            variant="info"
            class="m-1"
            tabindex="-1"
          />
          <b-popover
            id="participantAccessAccountsHelpIconPopover"
            target="participantAccessAccountsHelpIcon"
            triggers="click"
            placement="auto"
            variant="info"
          >
            <template #title>
              <b-button class="close" aria-label="Close">
                <span class="d-inline-block" aria-hidden="true">&times;</span>
              </b-button>
              Account Access
            </template>
            <b-list-group style="height: auto">
              <b-list-group-item
                >Adding email addresses to this list will allow logged in
                accounts associated with those emails to view this participants
                <ul>
                  <li>First and Last Name</li>
                  <li>Classification</li>
                  <li>DOB</li>
                  <li>Bow Type</li>
                  <li>USA Archery Membership Status</li>
                  <li>Account Access Emails</li>
                  <li>Scores</li>
                  <li>Earned pins</li>
                </ul>
              </b-list-group-item>
              <b-list-group-item
                >They will <strong>NOT</strong> be able to see
                <ul>
                  <li>Equipment Notes</li>
                  <li>General Notes</li>
                  <li>Groups</li>
                  <li>Archival status</li>
                </ul>
              </b-list-group-item>
            </b-list-group>
          </b-popover>
        </b-input-group>
        <br />
        <template v-for="ba in form.accessAccounts">
          <div class="input-group" :key="ba._id + 'inputGrp'">
            <b-form-input
              :key="ba._id + 'inputForm'"
              v-model.number="ba.loginId"
              type="email"
              placeholder="Enter email to grant basic access"
              :size="globalComponentSize"
            ></b-form-input>
            <b-button
              :key="ba._id + 'btnremove'"
              v-on:click="removeBasicAccessButtonClick(ba._id)"
              variant="warning"
              tabindex="-1"
              :size="globalComponentSize"
              >Remove</b-button
            >
          </div>
          <p :key="ba._id + 'paragraph'" />
        </template>
      </b-form-group>

      <hr />
      <div>* indicates required field</div>

      <div>
        <b-button
          class="float-left"
          id="btnCancel"
          type="reset"
          variant="default"
          tabindex="-1"
          >Cancel</b-button
        >

        <b-button
          class="float-right"
          id="btnSubmitParticipant"
          type="submit"
          variant="primary"
          >Submit</b-button
        >
      </div>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
const uuidv4 = require('uuid').v4;

const defaultFormValues = {
  firstName: '',
  lastName: '',
  classification: null,
  bowType: null,
  birthdate: null,
  emergencyContact: null,
  equipmentNote: null,
  notes: null,
  hasUSAAMembership: false,
  accessAccounts: [],
};
export default {
  name: 'AddParticipantForm',
  created() {
    this.$root.$emit('bv::hide::popover');
  },
  data() {
    return {
      form: defaultFormValues,
      classifications: [
        { text: 'JOAD', value: 'JOAD' },
        { text: 'Adult', value: 'Adult' },
      ],
      participantGroups: [],
    };
  },
  computed: {
    ...mapGetters('club', {
      getParticipantGroups: 'getParticipantGroups',
    }),
    ...mapGetters('sitewide', ['globalComponentSize']),
    ...mapGetters('pin', ['getBowTypeDisplayName']),
    bowTypes: {
      get() {
        let bowTypesList = [
          {
            text: this.getBowTypeDisplayName('barebow'),
            value: 'barebow',
          },
          {
            text: this.getBowTypeDisplayName('compound'),
            value: 'compound',
          },
          {
            text: this.getBowTypeDisplayName('recurve'),
            value: 'recurve',
          },
        ];
        return bowTypesList;
      },
    },
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let newParticipant = Object.assign({}, this.form);
      newParticipant.participantGroups = this.participantGroups;

      newParticipant.accessAccounts = [];
      for (let baa of this.form.accessAccounts) {
        if (baa !== undefined && baa.loginId !== undefined) {
          newParticipant.accessAccounts.push({ loginId: baa.loginId });
        }
      }

      this.$emit('newparticipant', newParticipant);
      this.setDefaultValues();
    },
    onCancel() {
      this.$emit('cancel');
      this.setDefaultValues();
    },
    setDefaultValues() {
      this.form.firstName = '';
      this.form.lastName = '';
      this.form.birthdate = null;
      this.form.classification = null;
      this.form.bowType = null;
      this.form.emergencyContact = null;
      this.form.equipmentNote = null;
      this.form.notes = null;
      this.form.hasUSAAMembership = false;
      this.participantGroups = [];
      this.form.accessAccounts = [];
    },
    addBasicAccessButtonClick() {
      this.form.accessAccounts.push({ _id: uuidv4() });
    },
    removeBasicAccessButtonClick(_idToRemove) {
      const indexOfExisting = this.form.accessAccounts.findIndex(
        (x) => x._id === _idToRemove
      );
      if (indexOfExisting !== -1) {
        this.form.accessAccounts.splice(indexOfExisting, 1);
      } else {
        console.log(
          'unexptected attempted removal of unknown object from form.accessAccounts: ' +
            _idToRemove
        );
      }
    },
  },
};
</script>
<style scoped></style>
