import Vue from 'vue';
import pinapi from '../../api/pinapi';
import _ from 'lodash';

// Remember if you add something here to update the index.js persistedState
const state = () => ({
  pinGroupsLoaded: false,
  pinGroups: [],
  notes: [],
  pinLevels: [],
  pinScoreOptionsByClassificationLocalBowType: {},
  distancesByClassificationLocalBowType: {},
  targetSizesByClassificationLocalBowType: {},
  earnedPins: {},
  earnedPinsLoaded: false,
});

const getters = {
  pinGroups: (state) => {
    return state.pinGroups;
  },
  pinNotes: (state) => {
    return state.notes;
  },
  pinGroupsLoaded: (state) => {
    return state.pinGroupsLoaded;
  },
  areEarnedPinsLoaded: (state) => {
    return state.earnedPinsLoaded;
  },
  getBowTypeDisplayName: () => (bowType) => {
    let upperForCompare = bowType.toUpperCase();
    let displayName = bowType;

    if (upperForCompare === 'BAREBOW') {
      displayName = 'Barebow / Basic Comp.';
    } else if (upperForCompare === 'RECURVE') {
      displayName = 'Olympic Recurve';
    } else if (upperForCompare === 'COMPOUND') {
      displayName = 'Compound';
    }

    return displayName;
  },
  getPinLevelInfo: (state) => (pinLevel) => {
    let result = state.pinLevels.filter(
      (pinLevelInfo) => pinLevelInfo.pinLevel_id === pinLevel
    );
    if (result.length === 0) return null;
    return result[0];
  },
  getPinOptionsForLevel: (state) => (
    pinLevel,
    bowType,
    local,
    classification
  ) => {
    let classificationLocalBowType = (
      classification +
      local +
      getNormalizedBowType(bowType)
    ).toUpperCase();

    let allLevels =
      state.pinScoreOptionsByClassificationLocalBowType[
        classificationLocalBowType
      ];

    return allLevels ? allLevels.filter((x) => x.pinLevel_id === pinLevel) : [];
  },
  getHighestPinForParticipant: (state) => (participant_id, local) => {
    if (participant_id in state.earnedPins) {
      let result = state.earnedPins[participant_id]
        .filter((pin) => pin.local === local)
        .sort((firstEl, secondEl) =>
          firstEl.pinLevel > secondEl.pinLevel ? -1 : 1
        ); // TODO: convert sort to lodash syntax

      if (result.length > 0) {
        return result[0];
      }
    }
    return null;
  },
  getHighestPinForParticipantBowClass: (state) => (
    participant_id,
    local,
    bowType,
    classification
  ) => {
    if (participant_id in state.earnedPins) {
      let result = state.earnedPins[participant_id]
        .filter(
          (pin) =>
            pin.local === local &&
            pin.bowType === bowType &&
            pin.classification === classification
        )
        .sort((firstEl, secondEl) =>
          firstEl.pinLevel > secondEl.pinLevel ? -1 : 1
        ); // TODO: convert sort to lodash syntax

      if (result.length > 0) {
        return result[0];
      }
    }
    return null;
  },
  getEarnedPinForParticipantAndScore: (state) => (participant_id, score_id) => {
    if (participant_id in state.earnedPins) {
      let participantsEarnedPins = state.earnedPins[participant_id].filter(
        (pin) => pin.score_id === score_id
      );

      if (participantsEarnedPins.length > 0) {
        return participantsEarnedPins[0];
      }
    }
    return null;
  },
  getDistancesForClassificationLocalBowType: (state) => (
    classification,
    local,
    bowType
  ) => {
    return state.distancesByClassificationLocalBowType[
      (classification + local + getNormalizedBowType(bowType)).toUpperCase()
    ];
  },
  getTargetSizesForClassificationLocalBowType: (state) => (
    classification,
    local,
    bowType
  ) => {
    return state.targetSizesByClassificationLocalBowType[
      (classification + local + getNormalizedBowType(bowType)).toUpperCase()
    ];
  },
  getEarnedPins: (state) => {
    return state.earnedPins;
  },
};

const actions = {
  clearPinData: ({ commit }) => {
    commit('clearData');
  },
  async initializePins({ commit }) {
    try {
      console.time('pin.module.actions.initializePins');
      let pinFetchData = await pinapi.getPinData();
      commit('initPinData', pinFetchData);
    } catch (err) {
      console.log(err);
    } finally {
      console.timeEnd('pin.module.actions.initializePins');
    }
  },
  async loadEarnedPins({ commit, state }, { scores }) {
    try {
      console.time('pin.module.actions.loadEarnedPins');
      
      // JACK THE TOGGLE IS HERE
      // let earnedPins = await pinapi.getEarnedPinsForScores(scores);
      let earnedPins = getEarnedPinsForScores(scores, state.pinGroups);
      
      commit('setEarnedPinsLoaded', true);
      commit('addAllEarnedPins', earnedPins);
    } catch (err) {
      commit('setEarnedPinsLoaded', true); // Should be notifying of issues that are real here.
      console.log('error getting all earned pins ' + err);
    } finally {
      console.timeEnd('pin.module.actions.loadEarnedPins');
    }
  },
  async checkIfScoreEarnedPin(
    { commit, dispatch, getters, rootGetters },
    { participant_id, score_id }
  ) {
    try {
      console.time('pin.module.actions.checkIfScoreEarnedPin');
      let earnedPin = getters.getEarnedPinForParticipantAndScore(
        participant_id,
        score_id
      );

      if (earnedPin != null) {
        let pinLevelInfo = getters.getPinLevelInfo(earnedPin.pinLevel);
        let participant = rootGetters['participant/getParticipantById'](
          earnedPin.participant_id
        );

        let earnedPinText = `${participant.firstName} ${participant.lastName} has earned a ${pinLevelInfo.pinName} pin for ${earnedPin.local} ${earnedPin.classification} ${earnedPin.bowType}`;

        commit('addPinForParticipant', earnedPin);

        dispatch(
          'toast',
          { type: 'pinEarned', displayText: earnedPinText },
          { root: true }
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      console.timeEnd('pin.module.actions.checkIfScoreEarnedPin');
    }
  },
};

const mutations = {
  clearData: (state) => {
    state.earnedPins = {};
    state.earnedPinsLoaded = false;
  },
  setEarnedPinsLoaded(state, value) {
    state.earnedPinsLoaded = value;
  },
  initPinData(state, pinData) {
    try {
      console.time('pin.module.mutation.initializePins');

      let todayDate = new Date()
      todayDate = todayDate.toISOString().split('T')[0]

      state.pinGroups = pinData.pinGroups;
      state.notes = pinData.notes;
      state.pinLevels = pinData.pinLevels;

      state.pinScoreOptionsByClassificationLocalBowType = {};

      // Set the PinColor for each pin
      state.pinGroups.forEach((pinGroup) => {
        pinGroup.bowClasses.forEach((bowClass) => {
          bowClass.pinScoreOptions = []; // initialize default
          bowClass.pinScoreOptionsHasNotes = false; // initialize default
          bowClass.pins.forEach((pin) => {
            let pinLookup = state.pinLevels.find(
              (x) => x.pinLevel_id === pin.pinLevel_id
            );

            pin.scoreOptions.forEach((option) => {
              if (option.note) {
                bowClass.pinScoreOptionsHasNotes = true;
              }

              // Map the rest of the object to the flattened pinScoreOptions
              let flatPinScoreOption = {
                pinLevel_id: pin.pinLevel_id,
                distance_meters: option.distance_meters,
                targetSize_cm: option.targetSize_cm,
                score: option.score,
                note: option.note,
                pinColor: pinLookup.pinColor,
                pinName: pinLookup.pinName,
                shouldDisplayUI: ((option.validTo === undefined || todayDate < option.validTo) && (option.validFrom === undefined || todayDate >= option.validFrom)),
              };
              bowClass.pinScoreOptions.push(flatPinScoreOption);

              // Generate Key for lookups
              let classificationLocalBowType = (
                pinGroup.classification +
                pinGroup.local +
                getNormalizedBowType(bowClass.bowType)
              ).toUpperCase();

              // Build lookup for next pin options
              if (
                classificationLocalBowType in
                state.pinScoreOptionsByClassificationLocalBowType
              ) {
                state.pinScoreOptionsByClassificationLocalBowType[
                  classificationLocalBowType
                ].push(flatPinScoreOption);
              } else {
                Vue.set(
                  state.pinScoreOptionsByClassificationLocalBowType,
                  classificationLocalBowType,
                  [flatPinScoreOption]
                );
              }

              // build lookups for score add form
              let distance = option.distance_meters;
              let distanceToAdd = {
                text: distance,
                value: distance,
              };
              if (
                classificationLocalBowType in
                state.distancesByClassificationLocalBowType
              ) {
                let indexOfExisting = state.distancesByClassificationLocalBowType[
                  classificationLocalBowType
                ].findIndex((x) => x.value === distance);

                if (indexOfExisting === -1) {
                  state.distancesByClassificationLocalBowType[
                    classificationLocalBowType
                  ].push(distanceToAdd);
                } else {
                  state.distancesByClassificationLocalBowType[
                    classificationLocalBowType
                  ].splice(indexOfExisting, 1, distanceToAdd);
                }
              } else {
                Vue.set(
                  state.distancesByClassificationLocalBowType,
                  classificationLocalBowType,
                  [distanceToAdd]
                );
              }
              // Build lookups for add score form
              let targetSize = option.targetSize_cm;
              let targetSizeToAdd = {
                text: targetSize,
                value: targetSize,
              };
              if (
                classificationLocalBowType in
                state.targetSizesByClassificationLocalBowType
              ) {
                let indexOfExisting = state.targetSizesByClassificationLocalBowType[
                  classificationLocalBowType
                ].findIndex((x) => x.value === targetSize);

                if (indexOfExisting === -1) {
                  state.targetSizesByClassificationLocalBowType[
                    classificationLocalBowType
                  ].push(targetSizeToAdd);
                } else {
                  state.targetSizesByClassificationLocalBowType[
                    classificationLocalBowType
                  ].splice(indexOfExisting, 1, targetSizeToAdd);
                }
              } else {
                Vue.set(
                  state.targetSizesByClassificationLocalBowType,
                  classificationLocalBowType,
                  [targetSizeToAdd]
                );
              }
            });
          });
        });
      });

      state.pinGroupsLoaded = true;
    } finally {
      console.timeEnd('pin.module.mutation.initializePins');
    }
  },
  addPinForParticipant(state, pin) {
    if (pin.participant_id in state.earnedPins) {
      state.earnedPins[pin.participant_id].push(pin);
    } else {
      Vue.set(state.earnedPins, pin.participant_id, [pin]);
    }
  },
  addAllEarnedPins(state, participantGroupings) {
    for (let participantGrouping of participantGroupings) {
      let pinArray = participantGrouping.earnedPins;
      if (Array.isArray(pinArray)) {
        let idToInsert = participantGrouping.participant_id;
        Vue.set(state.earnedPins, idToInsert, pinArray);
      } 
    }
  },
};

function getNormalizedBowType(bowType) {
  let normalizedBowTypeKey = bowType;
  if (!bowType) {
    normalizedBowTypeKey = '';
  } else if (bowType == 'Barebow/Basic Compound') {
    normalizedBowTypeKey = 'BAREBOW';
  } else if (bowType == 'Olympic Recurve') {
    normalizedBowTypeKey = 'RECURVE';
  } else if (bowType == 'Compound**') {
    normalizedBowTypeKey = 'COMPOUND';
  }
  return normalizedBowTypeKey.toUpperCase();
}


function getEarnedPinsForScores(allTheScores, pinGroups) {
  let allScores = allTheScores
  const results = []

  // group scores by participant
  const scoresGroupedByParticipant = _.groupBy(allScores, x => x.participant_id)

  for (const participantId in scoresGroupedByParticipant) {
    const scores = scoresGroupedByParticipant[participantId].filter(x => Boolean(x.isDeleted) === false)

    // compute pins earned
    const earnedPins = computePins(scores, pinGroups)
    results.push({ participant_id: participantId, earnedPins: earnedPins })
  }
  return results;
}

function computePins (participantScores, pinGroups) {
  const sortedScoresByGroup = getSortedScoresByGroup(participantScores)
  const earnedPins = reconcilePins(sortedScoresByGroup, pinGroups)

  return earnedPins
}

function scoreGroupKeyByScore (score) {
  return scoreGroupKey(score.bowType, score.local, score.classification)
}

function scoreGroupKey (bowType, local, classification) {
  const stringBuilder = []

  const normalizedBowTypeKey = getNormalizedBowTypeForCalc(bowType)
  const normalizedLocalKey = getNormalizedLocal(local)
  const normalizedClassification = getNormalizedClassification(classification)

  stringBuilder.push(normalizedBowTypeKey)
  stringBuilder.push(normalizedLocalKey)
  stringBuilder.push(normalizedClassification)

  return stringBuilder.join('.')
}

function getNormalizedBowTypeForCalc (bowType) {
  let normalizedBowTypeKey = bowType
  if (bowType === 'Barebow/Basic Compound') {
    normalizedBowTypeKey = 'barebow'
  } else if (bowType === 'Olympic Recurve') {
    normalizedBowTypeKey = 'recurve'
  } else if (bowType === 'Compound**') {
    normalizedBowTypeKey = 'compound'
  }
  return normalizedBowTypeKey.toLowerCase()
}

function getNormalizedLocal (local) {
  return local.toLowerCase()
}

function getNormalizedClassification (classification) {
  return classification.toLowerCase()
}

// * For each `pin group` combination (12)
// * For each `participants score` (unbounded) in that `pin group` in order of their scoredDate (then by created date for tie breakers)
// * A pin is awarded if all of the following are true:
//     * The previous level has already be awarded for an earlier score
//     * The score fits one of the score options for the pin level (distance, target size, score (greater or equal to))
function reconcilePins (sortedScoresByGroup, pinGroups) {
  let earnedPins = []

  // const pinLookup = require('./pinLookup').pinLookup
  // const pinLookup = JSON.parse(JSON.stringify(state));

  //pinLookup.pinGroups.forEach(pinGroup => {
  pinGroups.forEach(pinGroup => {
    pinGroup.bowClasses.forEach(bowClass => {
      const pinLookupKey = scoreGroupKey(bowClass.bowType, pinGroup.local, pinGroup.classification)
      const dateSortedScoresForScoreGroup = sortedScoresByGroup[pinLookupKey]

      const earnedPinsForScoreGroup = reconcileEarnedPinsForScoresInBowClass(
        dateSortedScoresForScoreGroup,
        bowClass,
        pinGroup.classification,
        pinGroup.local
      )

      earnedPins = earnedPins.concat(earnedPinsForScoreGroup)
    })
  })
  return earnedPins
}

function getSortedScoresByGroup (participantScores) {
  if (!participantScores || participantScores.length === 0) {
    return []
  }

  // lodash groupBy will retain order
  const orderedScores = _.orderBy(participantScores, ['scoredDate', 'createdDateUTC'], ['asc', 'asc'])

  return _.groupBy(orderedScores, scoreGroupKeyByScore)
}

function reconcileEarnedPinsForScoresInBowClass (
  dateSortedScoresForScoreGroup, // bow class specific
  bowClassLookup,
  bowclassClassification,
  bowclassLocal
) {
  const earnedPins = []
  if (!dateSortedScoresForScoreGroup || dateSortedScoresForScoreGroup.length === 0) {
    // if there are no scores in this group, just return
    
    return []
  }

  let highestPinLevelTracker = 0
  let pinLevelIterator = 0 // used to keep track of where the pin levels iterations are at

  for (let scoreIterator = 0; scoreIterator < dateSortedScoresForScoreGroup.length; scoreIterator++) { // (n)
    pinLevelIterator = highestPinLevelTracker // reset for a new score to the last pin earned (we'll do this till we run out of scores that could get this level and move on to the next level.)
    const score = dateSortedScoresForScoreGroup[scoreIterator]
    for (; pinLevelIterator < bowClassLookup.pins.length; pinLevelIterator++) { // There are 11; 1 through 7, bronze (8), silver (9), gold (10)
      const pinLevel = bowClassLookup.pins[pinLevelIterator]
      let pinAwardedBreakAgain = false

      // ** Core Earn Logic: have we earned the level before this one?
      if (highestPinLevelTracker === pinLevel.pinLevel_id - 1) {
        for (const scoreOption of pinLevel.scoreOptions) { // up to 4, usually 1 or 2 (up to 214 if we hit all)
          //* * Core Earn Logic: Is there a matching lookup target we match with an equal or greater score?
          if (score.distance === scoreOption.distance_meters &&
            score.targetSize === scoreOption.targetSize_cm &&
            score.points >= scoreOption.score &&
            (scoreOption.validTo === undefined || score.scoredDate < scoreOption.validTo) && // ValidTo and From added to handle when Matrix Changed
            (scoreOption.validFrom === undefined || score.scoredDate >= scoreOption.validFrom)) {
            highestPinLevelTracker = pinLevel.pinLevel_id
            const normalizedBowType = getNormalizedBowTypeForCalc(bowClassLookup.bowType)
            earnedPins.push({
              bowType: normalizedBowType,
              classification: bowclassClassification,
              local: bowclassLocal,
              participant_id: score.participant_id,
              score_id: score._id,
              pinLevel: pinLevel.pinLevel_id
            })

            // need to break out of the two for loops and go to the next pinlevel.
            pinAwardedBreakAgain = true // prep for outer break
            break // break inner loop of pin options
          }
        }
      } else {
        break
      }

      if (pinAwardedBreakAgain) {
        // also going to want to prime the iterator for the next loop when we do this break.
        pinLevelIterator++
        break
      }
    }
  }
  return earnedPins
}








export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
