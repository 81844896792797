<template>
  <div>
    <p>
      Activation codes can be acquired by contacting support. Activation codes
      are only being provided to those we have invited to help test the site.
    </p>
    <hr />
    <b-form id="addSubscriptionCodeForm" @submit="onSubmit" @reset="onCancel">
      <b-form-group
        id="subscriptionCodeGroup"
        label="Activation Code*:"
        label-for="subscriptionCode"
      >
        <b-form-input
          id="subscriptionCode"
          v-model="form.subscriptionCode"
          required
          placeholder="Enter Activation Code"
          v-mask="'NNNN-NNNN-NNNN'"
          :autofocus="true"
          :size="globalComponentSize"
        ></b-form-input>
      </b-form-group>

      <hr />
      <div>* indicates required field</div>

      <div>
        <b-button
          class="float-left"
          id="btnCancel"
          type="reset"
          variant="default"
          tabindex="-1"
          :size="globalComponentSize"
          >Cancel</b-button
        >

        <b-button
          class="float-right"
          id="btnSubmitClub"
          type="submit"
          variant="primary"
          :size="globalComponentSize"
          >Submit Code</b-button
        >
      </div>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

const defaultFormValues = {
  clubName: '',
};
export default {
  name: 'AddSubscriptionCodeForm',
  created() {
    this.$root.$emit('bv::hide::popover');
  },
  data() {
    return {
      form: defaultFormValues,
    };
  },
  computed: {
    ...mapGetters('sitewide', ['globalComponentSize']),
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let newSubscriptionCode = Object.assign({}, this.form.subscriptionCode);
      newSubscriptionCode = Object.values(newSubscriptionCode)
        .join('')
        .toUpperCase();
      this.$emit('newsubscriptioncode', newSubscriptionCode);
      this.setDefaultValues();
    },
    onCancel() {
      this.$emit('cancel');
      this.setDefaultValues();
    },
    setDefaultValues() {
      this.form.subscriptionCode = '';
    },
  },
};
</script>
<style scoped></style>
