<template>
  <div class="container">
    <p v-if="!isLoggedIn || !isAdmin">
      You need to be logged in as an admin in to view this page.
    </p>
    <div v-else>
      <div class="row-center">
        <h2>You are in the admin space</h2>
      </div>

      <b-tabs>
        <b-tab title="Subscriptions">
          <div id="subscriptionsSection">
            <b-modal
              id="bv-modal-add-subscription-option"
              v-if="showAddSubscriptionOptionForm"
              v-model="showAddSubscriptionOptionForm"
              hide-footer
              :static="true"
              centered
            >
              <template v-slot:modal-title
                >Enter a New Subscription Option</template
              >

              <AddSubscriptionOptionForm
                v-on:newSubscriptionOption="onNewSubscriptionOption"
                v-on:cancel="toggleAddSubscriptionOptionForm"
              />
            </b-modal>

            <h3>Subscriptions</h3>
            <b-button @click="loadSubscriptionOptionsClicked"
              >Load Subscription Options</b-button
            >
            <div
              v-if="
                !areSubscriptionOptionsLoaded &&
                isSubscriptionOptionLoadProcessing
              "
            >
              <b-spinner /> Loading Subscription Options...
            </div>
            <div v-else class="row">
              <b-button
                id="addSubscriptionOptionButton"
                v-on:click="toggleAddSubscriptionOptionForm"
                variant="outline-info"
                >Add Subscription Option</b-button
              >
              <subscription-option-card
                v-for="so in getSubscriptionOptions"
                :key="so._id"
                class="col-md-4"
                :subscriptionOption="so"
                :isAdmin="isAdmin"
              />
            </div>
            <div>
              <h4>Generated Codes</h4>
              <b-list-group class="list-group">
                <b-list-group-item
                  v-for="c in getSubscriptionCodes"
                  :key="c.code"
                  class="col-md-4"
                >
                  Code: {{ c.code }} :: Price: {{ c.price }} :: Months:
                  {{ c.months }}
                </b-list-group-item>
              </b-list-group>
              <div
                v-for="c in getSubscriptionCodes"
                :key="c.code"
                class="col-md-4"
              >
                {{ c.code }},
              </div>
            </div>
          </div>
        </b-tab>

        <b-tab title="Login Attempts">
          <h3>Login Attempts</h3>
          <sup>hard coded 1 month in api load</sup><br />
          <b-button @click="loadLoginAttemptsClicked"
            >Load Login Attempts</b-button
          >
          <div v-if="isLoadLoginAttemptsProcessing">
            <b-spinner /> Loading Login Attempts...
          </div>
          <div v-else>
            <h4>Login Attempts</h4>
            <div
              v-for="loginAttempt in getLoginAttempts"
              :key="loginAttempt._id"
              class="col"
            >
              Login: {{ loginAttempt.loginId }} ......... Attempted Date:
              {{ new Date(loginAttempt.attemptDate) }} <br />
              Was Login Successful:
              {{ loginAttempt.wasLoginSuccessful }} ......... reason:
              {{ loginAttempt.reason }} <br />
              <hr />
            </div>
          </div>
        </b-tab>

        <b-tab title="Feedback & NPS">
          <h3>Feedback / Review (NPS)</h3>
          <sup>hard coded 3 months max in api load</sup><br />
          <b-button @click="loadFeedbackClicked">Load Feedback</b-button>
          <div v-if="isFeedbackLoadProcessing">
            <b-spinner /> Loading Feedback...
          </div>
          <div v-else>
            <div class="col">
              <h4>Net Prom Score</h4>
              <div v-for="feedback in getRatedFeedback" :key="feedback._id">
                Submit By Id: {{ JSON.stringify(feedback) }}
              </div>
            </div>
            <div class="col">
              <h4>Raw Feedback</h4>
              <div v-for="feedback in getFeedback" :key="feedback._id">
                Submit By Id: {{ feedback.submitById }} ......... Submit Date:
                {{ feedback.submitDate }} <br />
                Rating: {{ feedback.rating }} ......... Comment:
                {{ feedback.comment }} <br />
                <hr />
              </div>
            </div>
          </div>
        </b-tab>

        <b-tab title="Actions">
          <div id="adminActionsSection">
            <h3>Admin Data Management</h3>
            <b-button @click="updateScoresRemoveTimeClicked"
              >Update Scores Remove Time</b-button
            >
            <div v-if="isUpdateScoresRemoveTimeProcessing">
              <b-spinner /> processing update scores remove time...
            </div>
            <div v-else class="row">
              {{ getUpdateScoresRemoveTimeResult }}
            </div>
          </div>
        </b-tab>

        <b-tab title="CC Payments Testing">
          <StripePayment />
        </b-tab>

        <b-tab title="Event Status Bar Testing">
          <StatusBar />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import SubscriptionOptionCard from '../components/SubscriptionOptionCard.vue';
import AddSubscriptionOptionForm from '../components/AddSubscriptionOptionForm';
import StripePayment from '../components/StripePayment.vue';
import StatusBar from '../components/StatusBar.vue';

/*
  Client UI:
    Get encryption public key from subscription service
    use key to encrypt string in client side only
    display result on client
    Clear result from client

  Service:
    Documentation display for how to create a secret with this data
    create the secret in k8s
    bind the secret to subscription service deployment as file reference (can't do env var as it will need to get latest whenever referenced without restarting the pod)
    endpoint for getting keys. Should create and store keys if do not exist in DB. (copy password token process)
    get keys from file when needed (this will ensure we get the latest value for each use)
      And decrypt.....


  OR
    // JACK HERE JACK JACK JACK - This is the best option for what key to use but probably best to put it into a k8s secret and pull in from there for just that little bit more security.
    Don't encrypt the damn thing and just put it in the pod via env var because all it can do is verify data 

*/

export default {
  name: 'AdminPortal',
  data() {
    return {};
  },
  components: {
    SubscriptionOptionCard,
    AddSubscriptionOptionForm,
    StripePayment,
    StatusBar,
  },
  computed: {
    ...mapGetters('login', {
      isLoggedIn: 'isLoggedIn',
      getLoginTokenAssembly: 'getLoginTokenAssembly',
      isAdmin: 'isAdmin',
      isLoadLoginAttemptsProcessing: 'isLoadLoginAttemptsProcessing',
      getLoginAttempts: 'getLoginAttempts',
    }),
    ...mapGetters('subscription', {
      areSubscriptionOptionsLoaded: 'areSubscriptionOptionsLoaded',
      getSubscriptionOptions: 'getSubscriptionOptions',
      isSubscriptionOptionLoadProcessing: 'isSubscriptionOptionLoadProcessing',
      showAddSubscriptionOptionForm: 'showAddSubscriptionOptionForm',
      getSubscriptionCodes: 'getSubscriptionCodes',
    }),
    ...mapGetters('admin', {
      isUpdateScoresRemoveTimeProcessing: 'isUpdateScoresRemoveTimeProcessing',
      getUpdateScoresRemoveTimeResult: 'getUpdateScoresRemoveTimeResult',
    }),
    ...mapGetters('feedback', {
      getFeedback: 'getFeedback',
      getRatedFeedback: 'getRatedFeedback',
      isFeedbackLoadProcessing: 'isFeedbackLoadProcessing',
    }),
  },
  created() {},
  methods: {
    ...mapActions('subscription', {
      loadSubscriptionOptions: 'loadSubscriptionOptions',
      toggleAddSubscriptionOptionForm: 'toggleAddSubscriptionOptionForm',
      saveSubscriptionOption: 'saveSubscriptionOption',
    }),
    ...mapActions('admin', {
      updateScoresRemoveTime: 'updateScoresRemoveTime',
    }),
    ...mapActions('login', {
      loadLoginAttempts: 'loadLoginAttempts',
    }),
    ...mapActions('feedback', {
      loadFeedback: 'loadFeedback',
    }),
    async loadSubscriptionOptionsClicked() {
      let loginAssemblyToken = await this.getLoginTokenAssembly;
      this.loadSubscriptionOptions(loginAssemblyToken);
    },
    async loadLoginAttemptsClicked() {
      let loginAssemblyToken = await this.getLoginTokenAssembly;
      this.loadLoginAttempts(loginAssemblyToken);
    },
    async updateScoresRemoveTimeClicked() {
      let loginAssemblyToken = this.getLoginTokenAssembly;
      this.updateScoresRemoveTime(loginAssemblyToken);
    },
    async onNewSubscriptionOption(subscriptionOption) {
      this.toggleAddSubscriptionOptionForm();
      this.saveSubscriptionOption({
        subscriptionOptionToSave: subscriptionOption,
        loginTokenAssembly: await this.getLoginTokenAssembly,
      });
    },
    async loadFeedbackClicked() {
      let loginAssemblyToken = await this.getLoginTokenAssembly;
      this.loadFeedback(loginAssemblyToken);
    },
  },
};
</script>
<style scoped>
.list-group {
  max-height: 225px;
  margin-bottom: 10px;
  overflow-x: auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}
</style>
