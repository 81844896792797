<template>
  <div v-if="!isBracketStarted" style="text-align: left">
    <ol>
      <li>List everyone you want in this bracket.</li>
      <li>Select 'Start Bracket' to generate bracket.</li>
      <dt>Note</dt>
      Brackets are not saved and are only visible on the device you start them
      on (on the same tab).
    </ol>

    <b-form-group
      class="col"
      id="assistantGroup"
      label="Participants to include in bracket:"
    >
      <template v-for="participant in participants">
        <div class="input-group" :key="participant._id + 'inputGrp'">
          <b-form-input
            :key="participant._id + 'inputForm'"
            v-model.number="participant.name"
            type="email"
            placeholder="Enter participant name"
            :size="globalComponentSize"
          ></b-form-input>
          <b-button
            :key="participant._id + 'btnremove'"
            v-on:click="removeParticipantButtonClick(participant._id)"
            variant="warning"
            tabindex="-1"
            :size="globalComponentSize"
            >X</b-button
          >
        </div>
        <p :key="participant._id + 'paragraph'" />
      </template>
      <b-button-group style="width: 100%">
        <b-button
          v-on:click="addParticipantButtonClick"
          variant="outline-primary"
          :size="globalComponentSize"
          >Add Archer</b-button
        >
        <b-button
          v-on:click="clearParticipantsButtonClick"
          variant="outline-primary"
          :size="globalComponentSize"
          tabindex="-1"
          >Clear All</b-button
        >
        <b-button
          v-if="isLoggedIn"
          v-on:click="fillWithVisibleParticipantsButtonClick"
          variant="outline-primary"
          :size="globalComponentSize"
          >Add Visible Participants</b-button
        >
      </b-button-group>
    </b-form-group>

    <b-button-group class="col" style="width: 100%">
      <b-button
        v-on:click="startBracketButtonClick"
        variant="primary"
        :size="globalComponentSize"
        >Start Bracket</b-button
      >
    </b-button-group>
  </div>

  <div class="col" v-else>
    <div v-if="participants.length < 2">
      You need at least two participants to run a bracket
    </div>
    <div v-else class="row">
      <div
        class="col border"
        v-for="level in bracket
          .map((item) => item.level)
          .filter((value, index, self) => self.indexOf(value) === index)"
        :key="level"
      >
        Round of {{ bracket.filter((x) => x.level === level).length * 2 }}
        <b-button-group
          style="width: 100%"
          class="row m-2"
          v-for="battle in bracket.filter((x) => x.level === level)"
          :key="battle.challengerA._id"
          vertical
        >
          <b-button
            :id="battle.level + '_' + battle.sequence"
            :variant="
              battle.winner !== null &&
              battle.winner.name === battle.challengerA.name
                ? 'success'
                : battle.challengerA.name === 'TBD' ||
                  battle.challengerB.name === 'TBD'
                ? 'outline-info'
                : 'info'
            "
            v-on:click="toggleWinnerButtonClick(battle, battle.challengerA)"
            :disabled="
              battle.challengerA.name === 'TBD' ||
              battle.challengerB.name === 'TBD' ||
              battle.challengerA.name === 'BYE'
            "
          >
            {{ battle.challengerA.name }}
          </b-button>
          <b-button variant="info" disabled>VS.</b-button>
          <b-button
            :id="battle.level + '_' + battle.sequence"
            :variant="
              battle.winner !== null &&
              battle.winner.name === battle.challengerB.name
                ? 'success'
                : battle.challengerA.name === 'TBD' ||
                  battle.challengerB.name === 'TBD'
                ? 'outline-info'
                : 'info'
            "
            v-on:click="toggleWinnerButtonClick(battle, battle.challengerB)"
            :disabled="
              battle.challengerA.name === 'TBD' ||
              battle.challengerB.name === 'TBD' ||
              battle.challengerB.name === 'BYE'
            "
          >
            {{ battle.challengerB.name }}
          </b-button>
        </b-button-group>
        <br />
      </div>
    </div>

    <b-button-group class="row m-3" style="width: 100%">
      <b-button
        v-on:click="resetBracketButtonClick"
        variant="warning"
        tabindex="-1"
        :size="globalComponentSize"
        >Reset (back to participant list)</b-button
      >
    </b-button-group>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
const uuidv4 = require('uuid').v4;

// TODO: review fairness and put byes in the first round only. https://www.printyourbrackets.com/how-byes-work-in-a-tournament.html

export default {
  name: 'QuickBracket',
  props: {},
  components: {},
  data: () => {
    return {};
  },
  methods: {
    ...mapActions('sitewide', { toastSuccess: 'toastSuccess' }),
    ...mapActions('quickBracket', {
      setIsBracketStarted: 'setIsBracketStarted',
      setBracket: 'setBracket',
      setParticipants: 'setParticipants',
      addParticipant: 'addParticipant',
      removeParticipantAt: 'removeParticipantAt',
      startBracket: 'startBracket',
      setBattleWinner: 'setBattleWinner',
      setBattleChallengerA: 'setBattleChallengerA',
      setBattleChallengerB: 'setBattleChallengerB',
    }),
    addParticipantButtonClick() {
      this.addParticipant({ _id: uuidv4(), name: '' });
    },
    removeParticipantButtonClick(_idToRemove) {
      const indexOfExisting = this.participants.findIndex(
        (x) => x._id === _idToRemove
      );
      if (indexOfExisting !== -1) {
        this.removeParticipantAt(indexOfExisting);
      } else {
        console.log(
          'unexptected attempted removal of unknown object: ' + _idToRemove
        );
      }
    },
    fillWithVisibleParticipantsButtonClick() {
      for (let part of this.getFilteredParticipants) {
        this.addParticipant({
          _id: uuidv4(),
          name: part.firstName + ' ' + part.lastName,
        });
      }
    },
    resetBracketButtonClick() {
      this.setBracket([]);
      this.setIsBracketStarted(false);
    },
    clearParticipantsButtonClick() {
      this.setParticipants([{ _id: uuidv4(), name: '' }]);
    },
    clearBracketButtonClick() {
      this.setBracket([]);
      this.setIsBracketStarted(false);
    },
    startBracketButtonClick() {
      this.startBracket();
    },
    toggleWinnerButtonClick(battle, winner) {
      let nextLevel = battle.level - 1;
      let nextSequence = Math.floor(battle.sequence / 2);
      let battleToUpdate = this.bracket.filter(
        (x) => x.level === nextLevel && x.sequence === nextSequence
      )[0];

      if (battle.winner !== null && battle.winner.name === winner.name) {
        this.setBattleWinner({ battle: battle, winner: null });
        if (battle.level > 0 && battle.sequence % 2 === 0) {
          this.setBattleChallengerA({
            battle: battleToUpdate,
            challenger: { name: 'TBD' },
          });
        } else if (battle.level > 0) {
          this.setBattleChallengerB({
            battle: battleToUpdate,
            challenger: { name: 'TBD' },
          });
        }
      } else {
        this.setBattleWinner({ battle: battle, winner: winner });
        if (battle.level === 0) {
          this.toastSuccess(winner.name + ' WON!');
        } else if (battle.sequence % 2 === 0) {
          this.setBattleChallengerA({
            battle: battleToUpdate,
            challenger: winner,
          });
        } else {
          this.setBattleChallengerB({
            battle: battleToUpdate,
            challenger: winner,
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters('participant', {
      getFilteredParticipants: 'getFilteredParticipants',
    }),
    ...mapGetters('sitewide', {
      globalComponentSize: 'globalComponentSize',
    }),
    ...mapGetters('login', {
      isLoggedIn: 'isLoggedIn',
    }),
    ...mapGetters('quickBracket', {
      isBracketStarted: 'isBracketStarted',
      bracket: 'bracket',
      participants: 'participants',
    }),
    placeholder: function () {
      return true;
    },
  },
};
</script>
<style scoped></style>
