<template>
  <div>
    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="publishableKey"
      :line-items="lineItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
      @loading="(v) => (loading = v)"
    />
    <button @click="submit">Purchase One Month</button>
  </div>
</template>
<script>
// DOCS For this components code: https://vuestripe.com/stripe-elements/card

import { StripeCheckout } from '@vue-stripe/vue-stripe';
//import { loadStripe } from '@stripe/stripe-js';

export default {
  name: 'StripePayment',
  created() {
    this.$root.$emit('bv::hide::popover');

    this.doWhatNow();
  },
  components: {
    StripeCheckout,
  },
  data() {
    // TODO: inject this to env vars or override if env vars are present and thus only for production/live
    this.publishableKey = 'pk_test_51J...';
    return {
      loading: false,
      lineItems: [
        {
          price: 'price_1JSsDVE7wMfjP7nsESmjJm0j', // TODO: Need to inject testing vs non-test ids
          quantity: 1,
        },
      ],
      successURL: `https://${window.location.hostname.toLowerCase()}/#/adminportal?session_id={CHECKOUT_SESSION_ID}&success`, // TODO Need to return to current page, ensure same www or non www variant to pick up cached login credentials
      cancelURL: `https://${window.location.hostname.toLowerCase()}/#/adminportal?session_id={CHECKOUT_SESSION_ID}&failed`, // TODO Need to return to current page, ensure same www or non www variant to pick up cached login credentials
    };
  },
  methods: {
    submit() {
      // You will be redirected to Stripe's secure checkout page
      try {
        this.$refs.checkoutRef.redirectToCheckout();
      } catch (e) {
        console.log(e);
      }
    },
    async doWhatNow() {
      const queryString = this.$route.query;
      const urlParams = new URLSearchParams(queryString);
      const session_id = urlParams.get('session_id');
      console.log('session_id is : ' + session_id);

      // Check to see if this is the return from payment processing. aka has session_id value (and others)
      // if not, do nothing.
      // if it is get the session_id, clubId, and subscriptionId

      // Submit to Claim subscription. Claim Sub to verify session club and sub all match. ... maybe just submit session, get everything else via reload of session object which will inlcude the club id and subscrId. though if we pass them we could load them up and if they are already claimed then do nothing.

      // if that returns successfully then clear the url params. TODO: test mashing refresh with the url in place and check the club activation duration.

      // // TODO remove this code from client side unless we are going to actually do something with this. if removed don't forget to uninstall stripe from npm but do not remove vuestripe
      // let stripe = await loadStripe(
      //   'pk_test_51J...'
      // );

      // let session = null;
      // try {
      //   session = await stripe.checkout.sessions.retrieve(session_id);
      //   console.log('the session is : ' + JSON.stringify(session));
      // } catch (e) {
      //   console.log('there was an error : ' + e);
      // } finally {
      //   console.log('done getting session');
      // }

      // let customer = null;
      // try {
      //   customer = await stripe.customers.retrieve(session.customer);

      //   console.log('the customer is : ' + JSON.stringify(customer));
      // } catch (e) {
      //   console.log('there was an error : ' + e);
      // } finally {
      //   console.log('done getting customer');
      // }
    },
  },
  mounted: function () {},
  computed: {},
};
</script>
<style scoped></style>
