import subscriptionApi from '../../api/subscription';

const state = () => ({
  subscriptionOptions: [],
  isSubscriptionOptionLoadProcessing: false,
  subscriptionOptionsLoaded: false,
  subscriptionOptionsLoadError: '',
  subscriptionCodeGenerationError: '',
  subscriptionForClubLoadError: '',
  showAddSubscriptionOptionForm: false,
  showAddSubscriptionCodeForm: false,
  subscriptionCodes: [],
  latestSubscriptionTokenAssembly: {
    additionalData: { clubId: null, isActive: false, validTo: new Date() },
  },
  isLatestSubscriptionTokenAssemblyLoading: false,
});
const getters = {
  getSubscriptionOptions: (state) => {
    return state.subscriptionOptions;
  },
  isSubscriptionOptionLoadProcessing: (state) => {
    return state.isSubscriptionOptionLoadProcessing;
  },
  isLatestSubscriptionTokenAssemblyLoading: (state) => {
    return state.isLatestSubscriptionTokenAssemblyLoading;
  },
  areSubscriptionOptionsLoaded: (state) => {
    return state.subscriptionOptionsLoaded;
  },
  showAddSubscriptionOptionForm: (state) => {
    return state.showAddSubscriptionOptionForm;
  },
  showAddSubscriptionCodeForm: (state) => {
    return state.showAddSubscriptionCodeForm;
  },
  getSubscriptionCodes: (state) => {
    return state.subscriptionCodes;
  },
  getLatestSubscriptionTokenAssembly: (state) => {
    return state.latestSubscriptionTokenAssembly;
  },
  getDaysRemainingInSubscription: (state) => (clubId) => {
    if (
      // state.latestSubscriptionTokenAssembly?.additionalData?.clubId === clubId
      state.latestSubscriptionTokenAssembly &&
      state.latestSubscriptionTokenAssembly.additionalData &&
      state.latestSubscriptionTokenAssembly.additionalData.clubId === clubId
    ) {
      const oneDay = 86400000; // 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      let now = new Date();
      let validTo = new Date(
        state.latestSubscriptionTokenAssembly.additionalData.validTo
      );
      let numberOfDaysBetween = Math.ceil((validTo - now) / oneDay);

      if (numberOfDaysBetween < 0) {
        numberOfDaysBetween = 0;
      }

      return numberOfDaysBetween;
    } else {
      return null;
    }
  },
  isSubscriptionActive: (state) => (clubId) => {
    if (
      // state.latestSubscriptionTokenAssembly?.additionalData?.clubId === clubId // FUCKING FUCK YOU AND YOUR SHIT NOT UNDERSTANDING SYNTAX FOR TESTS
      state.latestSubscriptionTokenAssembly &&
      state.latestSubscriptionTokenAssembly.additionalData &&
      state.latestSubscriptionTokenAssembly.additionalData.clubId === clubId &&
      state.latestSubscriptionTokenAssembly.additionalData.isActive !==
        undefined
    ) {
      return state.latestSubscriptionTokenAssembly.additionalData.isActive;
    } else {
      return false;
    }
  },
};

const actions = {
  clearSubscriptionData: ({ commit }) => {
    commit('clearData');
  },
  loadSubscriptionOptions: async ({ commit, dispatch }, loginTokenAssembly) => {
    try {
      console.time('subscription.module.actions.loadSubscriptionOptions');
      commit('setSubscriptionOptionLoadProcessing', true);
      let result = await subscriptionApi.getAllSubscriptionOptions(
        loginTokenAssembly
      );
      if (result.responseCode === 'normal') {
        commit('setSubscriptionOptions', result.subscriptionOptions);
      } else {
        console.log('there was a problem loading the subscriptions');
        dispatch(
          'toast',
          {
            type: 'error',
            displayText: `Error loading club activation status, please try logging out and back in.`,
          },
          { root: true }
        );
        commit(
          'setSubscriptionOptionLoadError',
          'Failed to load subscription options data with loginTokenAssembly'
        );
      }
      commit('setSubscriptionOptionLoadProcessing', false);
    } catch (err) {
      dispatch(
        'toast',
        {
          type: 'error',
          displayText: `Error loading club activation status, please try logging out and back in.`,
        },
        { root: true }
      );
      commit('setSubscriptionOptionLoadError', err);
      commit('setSubscriptionOptionLoadProcessing', false);
    } finally {
      console.timeEnd('subscription.module.actions.loadSubscriptionOptions');
    }
  },
  toggleAddSubscriptionOptionForm: ({ commit }) => {
    commit('toggleAddSubscriptionOptionForm');
  },
  toggleAddSubscriptionCodeForm: ({ commit }) => {
    commit('toggleAddSubscriptionCodeForm');
  },
  saveSubscriptionOption: async (
    { commit, dispatch },
    saveSubscriptionOptionRequest
  ) => {
    try {
      console.time('subscription.modules.actions.saveSubscriptionOption');

      let subscriptionOption =
        saveSubscriptionOptionRequest.subscriptionOptionToSave;
      let loginTokenAssembly = saveSubscriptionOptionRequest.loginTokenAssembly;

      let results = await subscriptionApi.saveSubscriptionOption(
        subscriptionOption,
        loginTokenAssembly
      );
      if (results.responseCode === 'normal') {
        for (let result of results.subscriptionOptions) {
          commit('pushSubscriptionOption', result.subscriptionOption);
          let alertText = '';
          let logText = '';
          if (result.responseCode == 'conflicted') {
            alertText =
              `Someone has edited ${result.subscriptionOption.displayName}` +
              ' before you. Local data has been updated with the newer data. Please reapply your edits.';
            logText = alertText;
          } else if (
            result.responseCode == 'conflictedError' ||
            result.responseCode == 'error'
          ) {
            alertText =
              'Error Saving ' +
              result.subscriptionOption.displayName +
              '. Please reload the page and make your edits again.';
            logText =
              alertText +
              ' Response Code: ' +
              result.responseCode +
              ' Error: ' +
              result.error;
          } else if (
            result.responseCode != undefined &&
            result.responseCode != 'normal'
          ) {
            alertText =
              'An unknown response was returned when saving Subscription Option. Please reload the page.';
            logText = 'Unknown response code.' + JSON.stringify(result);
          }

          if (alertText !== '') {
            console.log(logText);
            dispatch(
              'toast',
              { type: 'error', displayText: alertText },
              { root: true }
            );
          }
        }
      } else {
        console.log('there was a problem saving the subscription option');
        dispatch(
          'toast',
          {
            type: 'error',
            displayText:
              'There was an unexpected problem saving the subscription option, please try logging out and back in.',
          },
          { root: true }
        );
      }
    } catch (err) {
      commit('setSubscriptionOptionLoadError', err);
      dispatch(
        'toast',
        {
          type: 'error',
          displayText: 'An error occurred. Please reload the page.',
        },
        { root: true }
      );
    } finally {
      console.timeEnd('subscription.modules.actions.saveSubscriptionOption');
    }
  },
  generateSubscriptionCode: async (
    { commit, dispatch },
    generateSubscriptionCodeRequest
  ) => {
    try {
      console.time('subscription.modules.actions.generateSubscriptionCode');

      let option = generateSubscriptionCodeRequest.subscriptionOption;
      let subscriptionOptionId = option._id;
      let loginTokenAssembly =
        generateSubscriptionCodeRequest.loginTokenAssembly;

      let result = await subscriptionApi.generateSubscriptionCode(
        subscriptionOptionId,
        loginTokenAssembly
      );
      if (result.responseCode === 'normal') {
        commit('pushSubscriptionCode', {
          code: result.subscriptionCode,
          months: option.displayDurationMonths,
          price: option.price,
        });
      } else {
        console.log('there was a problem generating a subscription code.');
        dispatch(
          'toast',
          {
            type: 'error',
            displayText: 'there was a problem generating a subscription code.',
          },
          { root: true }
        );
      }
    } catch (err) {
      dispatch(
        'toast',
        {
          type: 'error',
          displayText: 'there was a problem generating a subscription code.',
        },
        { root: true }
      );
      commit('setSubscriptionCodeGenerationError', err);
    } finally {
      console.timeEnd('subscription.modules.actions.generateSubscriptionCode');
    }
  },
  claimSubscriptionCode: async (
    { commit, dispatch },
    claimSubscriptionRequest
  ) => {
    try {
      console.time('subscription.modules.actions.claimSubscriptionCode');

      let subscriptionId = claimSubscriptionRequest.requestCode;
      let subscriptionIdType = claimSubscriptionRequest.type;
      let loginTokenAssembly = claimSubscriptionRequest.loginTokenAssembly;
      let club = claimSubscriptionRequest.club;
      let clubTokenAssembly = club.clubTokenAssembly;

      dispatch(
        'toast',
        {
          type: 'info',
          displayText: `Your Activation code claim has been submitted.`,
        },
        { root: true }
      );

      let result = await subscriptionApi.claimSubscriptionCode(
        subscriptionId,
        subscriptionIdType,
        loginTokenAssembly,
        clubTokenAssembly
      );

      if (result.responseCode === 'normal') {
        dispatch(
          'toast',
          {
            type: 'success',
            displayText: `You've claimed ${
              result.durationMonthsApplied
            } month(s) for club ${
              club.clubName
            }. Club is now active till ${new Date(result.validTo)}.`,
          },
          { root: true }
        );

        // update the active subscriptions
        commit(
          'setLatestSubscriptionTokenAssembly',
          result.subscriptionTokenAssembly
        );
      } else {
        let toastText = `There was a problem claiming the Activation code. Please try entering it again or contact support.`;
        console.log(toastText + ` ${JSON.stringify(result)}`);
        dispatch(
          'toast',
          { type: 'error', displayText: toastText },
          { root: true }
        );
      }
    } catch (err) {
      let toastText = `There was a problem claiming the Activation code. Please try entering it again or contact support.`;
      console.log(toastText);
      dispatch(
        'toast',
        { type: 'error', displayText: toastText },
        { root: true }
      );
      commit('setSubscriptionCodeClaimError', err);
    } finally {
      console.timeEnd('subscription.modules.actions.claimSubscriptionCode');
    }
  },
  loadLatestSubscriptionForClub: async (
    { commit },
    loadLatestSubscriptionForClubRequest
  ) => {
    try {
      console.time('subscription.module.actions.loadLatestSubscriptionForClub');
      await commit('clearData');
      commit('setIsLatestSubscriptionTokenAssemblyLoading', true);

      let loginTokenAssembly =
        loadLatestSubscriptionForClubRequest.loginTokenAssembly;
      let clubTokenAssembly =
        loadLatestSubscriptionForClubRequest.clubTokenAssembly;
      let result = await subscriptionApi.getLatestSubscriptionForClubId(
        loginTokenAssembly,
        clubTokenAssembly
      );

      if (result.responseCode === 'normal') {
        if (result.hasActiveSubscription === true) {
          commit(
            'setLatestSubscriptionTokenAssembly',
            result.subscriptionTokenAssembly
          );
        } else {
          commit('setLatestSubscriptionTokenAssembly', {
            additionalData: {
              clubId: clubTokenAssembly._id,
              isActive: false,
              validTo: new Date(),
            },
          });
        }
      } else {
        commit(
          'setSubscriptionForClubLoadError',
          'Failed to load subscription data'
        );
      }
    } catch (err) {
      commit('setSubscriptionForClubLoadError', err);
    } finally {
      commit('setIsLatestSubscriptionTokenAssemblyLoading', false);
      console.timeEnd(
        'subscription.module.actions.loadLatestSubscriptionForClub'
      );
    }
  },
};

const mutations = {
  clearData: (state) => {
    state.subscriptionOptions = [];
    state.isSubscriptionOptionLoadProcessing = false;
    state.subscriptionOptionsLoaded = false;
    state.subscriptionOptionsLoadError = '';
    state.subscriptionCodeGenerationError = '';
    state.subscriptionForClubLoadError = '';
    state.showAddSubscriptionOptionForm = false;
    state.showAddSubscriptionCodeForm = false;
    state.subscriptionCodes = [];
    state.latestSubscriptionTokenAssembly = {
      additionalData: { clubId: null, isActive: false, validTo: new Date() },
    };
  },
  setSubscriptionOptionLoadProcessing: (state, value) => {
    state.isSubscriptionOptionLoadProcessing = value;
  },
  setIsLatestSubscriptionTokenAssemblyLoading: (state, value) => {
    state.isLatestSubscriptionTokenAssemblyLoading = value;
  },
  setSelectedClubById: (state, clubId) => {
    state.selectedClubId = clubId;
  },
  setLatestSubscriptionTokenAssembly: (state, subscriptionTokenAssembly) => {
    state.latestSubscriptionTokenAssembly = subscriptionTokenAssembly;
  },
  setSubscriptionOptions: (state, subscriptionOptionData) => {
    if (Array.isArray(subscriptionOptionData)) {
      state.subscriptionOptions = subscriptionOptionData;
    } else {
      console.log(
        'setSubscriptionOptions mutation payload must be an array, but it is not'
      );
    }
    // we got in here because it was a "normal" load, so we should set the loaded flag.
    if (state.subscriptionOptionsLoaded === false) {
      state.subscriptionOptionsLoaded = true;
    }
  },
  setSubscriptionOptionLoadError: (state, err) => {
    console.log('Error loading Subscription Options: ' + err);
    state.subscriptionOptionsLoadError = err;
  },
  setSubscriptionCodeGenerationError: (state, err) => {
    console.log('Error generating Subscription Code: ' + err);
    state.subscriptionCodeGenerationError = err;
  },
  setSubscriptionCodeClaimError: (state, err) => {
    console.log('Error claiming subscription code: ' + err);
    state.subscriptionCodeClaimError = err;
  },
  setSubscriptionForClubLoadError: (state, err) => {
    console.log('Error loading Subscription Status: ' + err);
    state.subscriptionForClubLoadError = err;
  },
  toggleAddSubscriptionOptionForm: (state) => {
    state.showAddSubscriptionOptionForm = !state.showAddSubscriptionOptionForm;
  },
  toggleAddSubscriptionCodeForm: (state) => {
    state.showAddSubscriptionCodeForm = !state.showAddSubscriptionCodeForm;
  },
  pushSubscriptionOption: (state, subscriptionOptionToPush) => {
    // check for existing with same ID and replace
    let indexOfExisting = state.subscriptionOptions.findIndex(
      (x) => x._id === subscriptionOptionToPush._id
    );

    if (indexOfExisting === -1) {
      state.subscriptionOptions.push(subscriptionOptionToPush);
    } else {
      state.subscriptionOptions.splice(
        indexOfExisting,
        1,
        subscriptionOptionToPush
      );
    }
  },
  pushSubscriptionCode: (state, subscriptionCodeToPush) => {
    // check for existing with same ID and replace
    let indexOfExisting = state.subscriptionCodes.findIndex(
      (x) => x.code === subscriptionCodeToPush.code
    );

    if (indexOfExisting === -1) {
      state.subscriptionCodes.push(subscriptionCodeToPush);
    } else {
      state.subscriptionCodes.splice(
        indexOfExisting,
        1,
        subscriptionCodeToPush
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
