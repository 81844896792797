import axios from 'axios';

export default {
  saveScore: (
    score,
    loginTokenAssembly,
    clubTokenAssembly,
    subscriptionTokenAssembly
  ) =>
    new Promise((resolve, reject) => {
      let credentials = {
        loginTokenAssembly: loginTokenAssembly,
        subscriptionTokenAssembly: subscriptionTokenAssembly,
        clubTokenAssembly: clubTokenAssembly,
        scoresToSave: [score],
      };
      let path = '/api/score/v2/';
      axios
        .put(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .put(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
  getAllScoresForClub: (loginTokenAssembly, clubTokenAssembly) =>
    new Promise((resolve, reject) => {
      let credentials = {
        loginTokenAssembly: loginTokenAssembly,
        clubTokenAssembly: clubTokenAssembly,
      };
      let path = '/api/score/';
      axios
        .post(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .post(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
  getAllScoresForParticipantSummaries: (
    loginTokenAssembly,
    participantSummaryTokenAssemblies
  ) =>
    new Promise((resolve, reject) => {
      let credentials = {
        loginTokenAssembly: loginTokenAssembly,
        participantSummaryTokenAssemblies: participantSummaryTokenAssemblies,
      };
      let path = '/api/score/summary/';
      axios
        .post(path, credentials)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          window.console.log('1 ' + e);
          setTimeout(() => {
            axios
              .post(path, credentials)
              .then((response2) => {
                resolve(response2.data);
              })
              .catch((e2) => {
                window.console.log('2 ' + e2);
                reject(e2);
              });
          }, 500);
        });
    }),
};
