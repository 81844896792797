<template>
  <div class="subscriptionOption">
    <b-card
      variant="primary"
      :id="'subscriptionOption-card-' + subscriptionOption._id"
    >
      <b-card-title
        :id="'subscriptionOption-card-title' + subscriptionOption._id"
      >
        {{ subscriptionOption.displayName }}
      </b-card-title>
      <b-card-sub-title v-if="isAdmin">
        <b-card-text>
          <div class="row,col" :id="'isActive-' + subscriptionOption._id">
            <label>IsActive:</label>
            {{ subscriptionOption.isActive }}
          </div>
        </b-card-text>
        <b-button-group>
          <b-button
            :id="
              'toggleEditSubscriptionOptionFormButton-' + subscriptionOption._id
            "
            variant="outline-primary"
            v-on:click="toggleEditSubscriptionOptionForm"
            :size="globalComponentSize"
          >
            <PencilIcon style="width: 25px" /> Edit
          </b-button>
          <b-button
            :id="'generateOptionCodeButton-' + subscriptionOption._id"
            variant="outline-primary"
            v-on:click="generateOptionCodeButtonClick"
            :size="globalComponentSize"
            >Generate Code
          </b-button>
        </b-button-group>
      </b-card-sub-title>
      <b-card-text>
        <div class="row,col" :id="'price-' + subscriptionOption._id">
          <label>Price:</label>
          $ {{ subscriptionOption.price / 100 }}
          <!-- TODO: formatting to display amount with 100.50 instead of 100.5 -->
        </div>
      </b-card-text>
    </b-card>
    <!-- Admins will have edit buttons in the future, keeping this as placeholder reminder -->
    <b-modal
      :id="'bv-modal-edit-subscription-option' + subscriptionOption._id"
      hide-footer
      :static="true"
      v-if="isAdmin && showEditSubscriptionOptionForm"
      v-model="showEditSubscriptionOptionForm"
    >
      <template v-slot:modal-title
        >Edit {{ subscriptionOption.displayName }}</template
      >
      <EditSubscriptionOptionForm
        v-if="showEditSubscriptionOptionForm"
        :subscriptionOption="subscriptionOption"
        v-on:updateSubscriptionOption="onUpdateSubscriptionOption"
        v-on:cancel="toggleEditSubscriptionOptionForm()"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PencilIcon from './PencilIcon';
import EditSubscriptionOptionForm from './EditSubscriptionOptionForm';

export default {
  name: 'SubscriptionOptionCard',
  props: {
    subscriptionOption: Object,
    isAdmin: Boolean,
  },
  components: {
    PencilIcon,
    EditSubscriptionOptionForm,
  },
  data: () => {
    return {
      showEditSubscriptionOptionForm: false,
    };
  },
  methods: {
    ...mapActions('subscription', {
      saveSubscriptionOption: 'saveSubscriptionOption',
      generateSubscriptionCode: 'generateSubscriptionCode',
    }),
    toggleEditSubscriptionOptionForm() {
      this.showEditSubscriptionOptionForm = !this
        .showEditSubscriptionOptionForm;
    },
    async onUpdateSubscriptionOption(updatedSubscriptionOption) {
      this.toggleEditSubscriptionOptionForm();
      // TODO: show "working" message
      this.saveSubscriptionOption({
        subscriptionOptionToSave: updatedSubscriptionOption,
        loginTokenAssembly: await this.getLoginTokenAssembly,
      });
      // TODO: clear "working" message
    },

    async generateOptionCodeButtonClick() {
      this.generateSubscriptionCode({
        subscriptionOption: this.subscriptionOption,
        loginTokenAssembly: await this.getLoginTokenAssembly,
      });
    },
  },
  computed: {
    ...mapGetters('login', {
      getLoginTokenAssembly: 'getLoginTokenAssembly',
    }),
    ...mapGetters('sitewide', ['globalComponentSize']),
  },
};
</script>
<style scoped></style>
