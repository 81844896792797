<template>
  <b-card
    :id="
      'participant-card-' + participant.firstName + '-' + participant.lastName
    "
    variant="light"
  >
    <b-card-title v-if="title !== undefined">
      {{ title }}
    </b-card-title>
    <b-card-text>
      <span class="row">
        <div
          class="col-lg-4 col-sm-12 my-1"
          :id="'classification-' + participant._id"
        >
          <fieldset class="m-1" style="width: 100%">
            {{ participant.classification }} {{ ageText }}
          </fieldset>
        </div>

        <div class="col-lg-4 col-sm-12 my-1" :id="'bowType-' + participant._id">
          <fieldset class="m-1" style="width: 100%">
            {{ this.getBowTypeDisplayName(participant.bowType) }}
          </fieldset>
        </div>

        <div
          class="col-lg-4 col-sm-12 my-1"
          :id="'hasUSAAMembership-' + participant._id"
        >
          <fieldset class="m-1" style="width: 100%">
            <label>USAA Memberships:</label>
            <BIconCheck
              v-if="participant.hasUSAAMembership"
              variant="success"
            />
            <BIconXCircle v-else variant="danger" />
          </fieldset>
        </div>
      </span>

      <div class="row">
        <div
          class="col-lg-6 col-sm-12 my-1"
          :id="'highestEarnedIndoorPin-' + participant._id"
        >
          <fieldset class="m-1 px-3" style="width: 100%">
            <legend>Earned Indoor</legend>
            <div v-if="!areEarnedPinsLoaded">
              <b-spinner small /> Checking Pins
            </div>
            <span v-else-if="indoorPin">
              <PinIcon :pinColor="pinColor(indoorPin)" :pinLevel="indoorPin" />
              {{ indoorPin }} / 12
            </span>
            <span v-else>None Earned Yet</span>
          </fieldset>
        </div>
        <div
          class="col-lg-6 col-sm-12 my-1"
          :id="'bestOutdoor-' + participant._id"
        >
          <fieldset class="m-1 px-3" style="width: 100%">
            <legend>Earned Outdoor</legend>
            <div v-if="!areEarnedPinsLoaded">
              <b-spinner small /> Checking Pins
            </div>
            <span v-else-if="outdoorPin">
              <PinIcon
                :pinColor="pinColor(outdoorPin)"
                :pinLevel="outdoorPin"
              />
              {{ outdoorPin }} / 12
            </span>
            <span v-else>None Earned Yet</span>
          </fieldset>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-6 col-sm-12 my-1"
          :id="'nextIndoorPin-' + participant._id"
        >
          <fieldset class="m-1 px-3" style="width: 100%">
            <legend>Next Indoor Pin</legend>
            <div v-if="!areEarnedPinsLoaded">
              <b-spinner small /> Checking Pins
            </div>

            <b-table
              v-else-if="nextIndoorPinOptions.filter(opt => opt.option.shouldDisplayUI).length > 0"
              :items="nextIndoorPinOptions.filter(opt => opt.option.shouldDisplayUI)"
              striped
              small
              :fields="[
                { key: 'option.distance_meters', label: 'Dist (m)' },
                { key: 'option.targetSize_cm', label: 'Target (cm)' },
                { key: 'option.score', label: 'Score' },
              ]"
            >
            </b-table>
          </fieldset>
        </div>

        <div
          class="col-lg-6 col-sm-12 my-1"
          :id="'nextOutdoorPin-' + participant._id"
        >
          <fieldset class="m-1 px-3" style="width: 100%">
            <legend>Next Outdoor Pin</legend>
            <div v-if="!areEarnedPinsLoaded">
              <b-spinner small /> Checking Pins
            </div>

            <b-table
              v-else-if="nextOutdoorPinOptions.filter(opt => opt.option.shouldDisplayUI).length > 0"
              :items="nextOutdoorPinOptions.filter(opt => opt.option.shouldDisplayUI)"
              striped
              small
              :fields="[
                { key: 'option.distance_meters', label: 'Dist (m)' },
                { key: 'option.targetSize_cm', label: 'Target (cm)' },
                { key: 'option.score', label: 'Score' },
              ]"
            >
            </b-table>
          </fieldset>
        </div>
      </div>

      <div class="row,col my-1">
        <fieldset class="m-1 px-3" style="width: 100%">
          <legend>Scores</legend>
          <div
            v-if="
              (!areScoreSummariesLoaded && !isSummaryPreview) ||
              (!areScoresLoaded && isSummaryPreview)
            "
            class="score-group"
          >
            <b-spinner small /> Loading Scores
          </div>
          <b-tabs v-else-if="participantScores.length > 0" fill>
            <b-tab
              :title="
                'Indoor (' +
                participantScores.filter((x) => x.local === 'indoor').length +
                ' scores)'
              "
            >
              <b-table
                class="score-group"
                stacked="lg"
                small
                striped
                style="width: 100%"
                :items="participantScores.filter((x) => x.local === 'indoor')"
                :fields="[
                  { key: 'scoredDate' },
                  { key: 'group', label: 'Group' },
                  { key: 'targetAndDist', label: 'Target & Distance' },
                  { key: 'points', label: 'Score' },
                  { key: '_id', label: 'Earned Pin' },
                  { key: 'counts', label: 'Xs 10s 9s' },
                ]"
              >
                <template #cell(scoredDate)="cell">
                  {{
                    new Date(
                      new Date(cell.value) -
                        new Date().getTimezoneOffset() * 60 * -1000
                    ).toLocaleDateString()
                  }}
                </template>

                <template #cell(group)="cell">
                  <span>
                    {{ getBowTypeDisplayName(cell.item.bowType) }} |
                    {{ cell.item.classification }}
                  </span>
                </template>

                <template #cell(targetAndDist)="cell">
                  <span>
                    {{ `${cell.item.targetSize}cm at ${cell.item.distance}m` }}
                  </span>
                </template>

                <template #cell(_id)="cell">
                  <span
                    v-if="
                      getEarnedPinForParticipantAndScore(
                        participant._id,
                        cell.value
                      )
                    "
                  >
                    {{
                      getEarnedPinForParticipantAndScore(
                        participant._id,
                        cell.value
                      ).pinLevel + ' / 12'
                    }}
                  </span>
                  <PinIcon
                    v-if="
                      getEarnedPinForParticipantAndScore(
                        participant._id,
                        cell.value
                      )
                    "
                    :pinColor="
                      pinColor(
                        getEarnedPinForParticipantAndScore(
                          participant._id,
                          cell.value
                        ).pinLevel
                      )
                    "
                    :pinLevel="
                      getEarnedPinForParticipantAndScore(
                        participant._id,
                        cell.value
                      ).pinLevel
                    "
                  />
                </template>

                <template #head(counts)>
                  <table style="width: 100%">
                    <td style="width: 33%">Xs</td>
                    <td style="width: 34%">10s</td>
                    <td style="width: 33%">9s</td>
                  </table>
                </template>
                <template #cell(counts)="cell">
                  <table style="width: 100%">
                    <td style="width: 33%">{{ cell.item.xCount }}</td>
                    <td style="width: 34%">{{ cell.item.tenCount }}</td>
                    <td style="width: 33%">{{ cell.item.nineCount }}</td>
                  </table>
                </template>
              </b-table>
            </b-tab>
            <b-tab
              :title="
                'Outdoor (' +
                participantScores.filter((x) => x.local === 'outdoor').length +
                ' scores)'
              "
            >
              <b-table
                class="score-group"
                stacked="lg"
                small
                striped
                style="width: 100%"
                :items="participantScores.filter((x) => x.local === 'outdoor')"
                :fields="[
                  { key: 'scoredDate' },
                  { key: 'group', label: 'Group' },
                  { key: 'targetAndDist', label: 'Target & Distance' },
                  { key: 'points', label: 'Score' },
                  { key: '_id', label: 'Earned Pin' },
                  { key: 'counts', label: 'Xs 10s 9s' },
                ]"
              >
                <template #cell(scoredDate)="cell">
                  {{
                    new Date(
                      new Date(cell.value) -
                        new Date().getTimezoneOffset() * 60 * -1000
                    ).toLocaleDateString()
                  }}
                </template>

                <template #cell(group)="cell">
                  <span>
                    {{ getBowTypeDisplayName(cell.item.bowType) }} |
                    {{ cell.item.classification }}
                  </span>
                </template>

                <template #cell(targetAndDist)="cell">
                  <span>
                    {{ `${cell.item.targetSize}cm at ${cell.item.distance}m` }}
                  </span>
                </template>

                <template #cell(_id)="cell">
                  <span
                    v-if="
                      getEarnedPinForParticipantAndScore(
                        participant._id,
                        cell.value
                      )
                    "
                  >
                    {{
                      getEarnedPinForParticipantAndScore(
                        participant._id,
                        cell.value
                      ).pinLevel + ' / 12'
                    }}
                  </span>
                  <PinIcon
                    v-if="
                      getEarnedPinForParticipantAndScore(
                        participant._id,
                        cell.value
                      )
                    "
                    :pinColor="
                      pinColor(
                        getEarnedPinForParticipantAndScore(
                          participant._id,
                          cell.value
                        ).pinLevel
                      )
                    "
                    :pinLevel="
                      getEarnedPinForParticipantAndScore(
                        participant._id,
                        cell.value
                      ).pinLevel
                    "
                  />
                </template>

                <template #head(counts)>
                  <table style="width: 100%">
                    <td style="width: 33%">Xs</td>
                    <td style="width: 34%">10s</td>
                    <td style="width: 33%">9s</td>
                  </table>
                </template>
                <template #cell(counts)="cell">
                  <table style="width: 100%">
                    <td style="width: 33%">{{ cell.item.xCount }}</td>
                    <td style="width: 34%">{{ cell.item.tenCount }}</td>
                    <td style="width: 33%">{{ cell.item.nineCount }}</td>
                  </table>
                </template>
              </b-table>
            </b-tab>
          </b-tabs>

          <div v-else class="score-group">
            <em>There are no scores to display.</em>
          </div>
        </fieldset>
      </div>

      <div class="row,col my-1" :id="'accessAccounts-' + participant._id">
        <fieldset class="m-1 px-3" style="width: 100%">
          <legend>Access Account(s)</legend>
          <li
            style="list-style-type: none"
            v-for="accessAccount in participant.accessAccounts"
            :key="accessAccount.loginId"
          >
            {{ accessAccount.loginId }}
          </li>
        </fieldset>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import PinIcon from '../components/PinIcon';
import { BIconCheck, BIconXCircle } from 'bootstrap-vue';

export default {
  name: 'ParticipantCard',
  props: {
    participant: Object,
    title: String,
    isSummaryPreview: Boolean,
  },
  components: {
    PinIcon,
    BIconCheck,
    BIconXCircle,
  },
  data: () => {
    return {
      nextIndoorPinOptionsStatic: new Map(),
      nextOutdoorPinOptionsStatic: new Map(),
    };
  },
  methods: {
    ...mapActions('sitewide', {
      toastError: 'toastError',
    }),
    pinColor(pinLevel) {
      let option = this.getPinLevelInfo(pinLevel);
      if (option != null) {
        return option.pinColor;
      }
      return null;
    },
  },
  computed: {
    ...mapGetters('score', {
      getScoresByParticipantId: 'getScoresByParticipantId',
      areScoreSummariesLoaded: 'areScoreSummariesLoaded',
      areScoresLoaded: 'areScoresLoaded',
    }),
    ...mapGetters('pin', {
      getHighestPinForParticipantBowClass:
        'getHighestPinForParticipantBowClass',
      getPinLevelInfo: 'getPinLevelInfo',
      getEarnedPinForParticipantAndScore: 'getEarnedPinForParticipantAndScore',
      areEarnedPinsLoaded: 'areEarnedPinsLoaded',
      getPinOptionsForLevel: 'getPinOptionsForLevel',
      getBowTypeDisplayName: 'getBowTypeDisplayName',
    }),
    ...mapGetters('login', {
      getLoginTokenAssembly: 'getLoginTokenAssembly',
    }),
    ...mapGetters('sitewide', ['globalComponentSize']),
    ageText: function () {
      if (
        this.participant.birthdate !== null &&
        this.participant.birthdate !== undefined &&
        this.participant.birthdate !== ''
      ) {
        var dateOfBirth = new Date(this.participant.birthdate);
        var dateDiff = Date.now() - dateOfBirth.getTime();
        var calculatedDate = new Date(dateDiff);
        var calculatedAge = Math.abs(calculatedDate.getUTCFullYear() - 1970);

        return calculatedAge + ' years old';
      } else {
        return '';
      }
    },
    indoorPin: function () {
      let topPin = this.getHighestPinForParticipantBowClass(
        this.participant._id,
        'Indoor',
        this.participant.bowType,
        this.participant.classification
      );
      if (topPin != null) {
        return topPin.pinLevel;
      }
      return null;
    },
    nextIndoorPinOptions: {
      get() {
        let pinOptions = this.getPinOptionsForLevel(
          this.indoorPin + 1,
          this.participant.bowType,
          'Indoor',
          this.participant.classification
        );
        let staticKey =
          'pinNum:' +
          this.indoorPin +
          1 +
          ':bowtype:' +
          this.participant.bowType +
          ':Indoor:classification:' +
          this.participant.classification;
        let pinOptionsStatic = this.nextIndoorPinOptionsStatic.get(staticKey);
        if (pinOptionsStatic === undefined) {
          let remappedPinsOptions = [];
          for (let option of pinOptions) {
            let remappedOption = {
              option: option,
              participant: this.participant,
            };
            remappedPinsOptions.push(remappedOption);
          }
          this.nextIndoorPinOptionsStatic.set(staticKey, remappedPinsOptions);
          pinOptionsStatic = remappedPinsOptions;
        }

        return pinOptionsStatic;
      },
    },
    outdoorPin: function () {
      let topPin = this.getHighestPinForParticipantBowClass(
        this.participant._id,
        'Outdoor',
        this.participant.bowType,
        this.participant.classification
      );
      if (topPin != null) {
        return topPin.pinLevel;
      }
      return null;
    },
    nextOutdoorPinOptions: {
      get() {
        let pinOptions = this.getPinOptionsForLevel(
          this.outdoorPin + 1,
          this.participant.bowType,
          'Outdoor',
          this.participant.classification
        );
        let staticKey =
          'pinNum:' +
          this.outdoorPin +
          1 +
          ':bowtype:' +
          this.participant.bowType +
          ':Outdoor:classification:' +
          this.participant.classification;
        let pinOptionsStatic = this.nextOutdoorPinOptionsStatic.get(staticKey);
        if (pinOptionsStatic === undefined) {
          let remappedPinsOptions = [];
          for (let option of pinOptions) {
            let remappedOption = {
              option: option,
              participant: this.participant,
            };
            remappedPinsOptions.push(remappedOption);
          }
          this.nextOutdoorPinOptionsStatic.set(staticKey, remappedPinsOptions);
          pinOptionsStatic = remappedPinsOptions;
        }

        return pinOptionsStatic;
      },
    },
    participantScores: function () {
      let scores = this.getScoresByParticipantId(this.participant._id);
      return _.orderBy(
        scores,
        ['scoredDate', 'createdDateUTC'],
        ['desc', 'desc']
      );
    },
    participantName: function () {
      return this.participant.firstName + ' ' + this.participant.lastName;
    },
  },
};
</script>
<style scoped>
fieldset {
  border: 1px solid silver;
  border-radius: 10px;
  display: table;
  width: 95%;
  *display: inline; /* ie6/7 hack for inline block */
  *width: auto; /* ie 6/7 */
  zoom: 1; /* ie6/7 hack for inline block */
  vertical-align: middle;
  height: 100%;
}
fieldset legend {
  font-size: 1.25rem;
}
</style>
