<template>
  <div>
    <b-form :id="'FeedbackForm'" @submit="onSubmit" @reset="onCancel">
      <b-form-group
        id="comment"
        label="How likely are you to recomend Archery Coach Notes to a friend or colleague?"
        label-for="comment"
      >
        <b-form-rating
          v-model="form.rating"
          show-value
          precision="0"
          variant="success"
          stars="10"
          show-clear
          :size="globalComponentSize"
        ></b-form-rating>
      </b-form-group>

      <hr />

      <b-form-group
        id="comment"
        label="Sing our praises or let us know what we can do better:"
        label-for="comment"
      >
        <b-form-textarea
          id="comment"
          type="text"
          v-model="form.comment"
          :size="globalComponentSize"
        ></b-form-textarea>
      </b-form-group>

      <hr />
      <sub>Feedback is not anonymous.</sub>
      <div>
        <b-button
          class="float-left"
          id="btnCancel"
          type="reset"
          variant="default"
          tabindex="-1"
          :size="globalComponentSize"
          >Cancel</b-button
        >

        <b-button
          class="float-right"
          id="btnSubmitFeedback"
          type="submit"
          variant="primary"
          :size="globalComponentSize"
          >Submit Feedback</b-button
        >
      </div>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

const defaultFormValues = {
  rating: null,
  comment: '',
};
export default {
  name: 'FeedbackForm',
  data() {
    return {
      form: defaultFormValues,
    };
  },
  props: {},
  components: {},
  computed: {
    ...mapGetters('login', ['getLoginTokenAssembly', 'isLoggedIn']),
    ...mapGetters('sitewide', ['globalComponentSize']),
  },
  created() {
    this.$root.$emit('bv::hide::popover');
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let newFeedback = Object.assign({}, this.form);

      newFeedback.loginTokenAssembly = this.getLoginTokenAssembly;

      if (newFeedback.rating === null) {
        newFeedback.rating = undefined;
      }

      console.log(JSON.stringify(newFeedback));

      this.$emit('newFeedback', newFeedback);
      this.setDefaults();
    },
    setDefaults() {
      this.form.rating = null;
      this.form.comment = '';
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
<style scoped></style>
