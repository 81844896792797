<template>
  <!-- sourced from https://www.svgrepo.com/svg/13972/target -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480.001 480.001">
    <g>
      <g>
        <g>
          <path
            d="M364.997,167.453c-5.14,2.021-7.668,7.826-5.647,12.966c8.044,20.459,12.124,42.115,12.124,64.366
            c0,96.893-78.851,175.72-175.772,175.72c-96.881,0-175.7-78.828-175.7-175.72c0-96.876,78.819-175.691,175.7-175.691
            c48.879,0,94.279,19.559,127.835,55.072c3.794,4.015,10.123,4.194,14.136,0.401c4.015-3.793,4.194-10.122,0.401-14.137
            c-18.107-19.164-39.48-34.245-63.525-44.826c-24.899-10.955-51.426-16.51-78.849-16.51C87.79,49.094,0,136.88,0,244.785
            c0,107.92,87.79,195.72,195.7,195.72c52.297,0,101.46-20.353,138.432-57.311c36.977-36.962,57.341-86.117,57.341-138.409
            c0-24.767-4.546-48.885-13.51-71.685C375.941,167.961,370.139,165.432,364.997,167.453z"
          />
          <path
            d="M291.622,158.77c3.808-4,3.652-10.33-0.348-14.138c-25.892-24.649-59.834-38.225-95.574-38.225
            c-76.264,0-138.309,62.076-138.309,138.377c0,76.306,62.045,138.385,138.309,138.385c76.347,0,138.46-62.079,138.46-138.385
            c0-14.27-2.175-28.322-6.466-41.766c-1.679-5.261-7.306-8.164-12.567-6.487c-5.262,1.679-8.165,7.306-6.487,12.567
            c3.663,11.477,5.52,23.483,5.52,35.686c0,65.277-53.141,118.385-118.46,118.385c-65.236,0-118.309-53.107-118.309-118.385
            c0-65.273,53.073-118.377,118.309-118.377c30.582,0,59.627,11.617,81.784,32.71C281.484,162.926,287.814,162.77,291.622,158.77z"
          />
          <path
            d="M195.7,180.313c12.1,0,23.862,3.345,34.034,9.683c1.606,0.996,3.128,2.047,4.525,3.123
            c4.376,3.369,10.654,2.554,14.024-1.821c3.37-4.376,2.554-10.655-1.821-14.024c-1.929-1.485-4.011-2.923-6.169-4.262
            c-13.33-8.307-28.75-12.698-44.594-12.698c-46.535,0-84.395,37.894-84.395,84.472c0,46.594,37.859,84.501,84.395,84.501
            c46.611,0,84.532-37.907,84.532-84.501c0-3.803-0.254-7.523-0.778-11.373c-0.744-5.472-5.783-9.301-11.257-8.561
            c-5.472,0.745-9.305,5.784-8.561,11.257c0.401,2.947,0.596,5.785,0.596,8.677c0,35.566-28.949,64.501-64.532,64.501
            c-35.507,0-64.395-28.935-64.395-64.501C131.306,209.235,160.193,180.313,195.7,180.313z"
          />
          <path
            d="M475.132,119.668l-40.815-24.406l-4.888-46.804c-0.36-3.455-2.488-6.474-5.619-7.977
            c-3.13-1.502-6.818-1.273-9.738,0.606l-39.283,25.28c-2.819,1.814-4.542,4.921-4.587,8.273l-0.704,51.944l-158.391,96.197
            c-4.553-3.296-10.138-5.246-16.172-5.246c-15.304,0-27.754,12.442-27.754,27.735c0,15.293,12.451,27.735,27.754,27.735
            c15.264,0,27.682-12.442,27.682-27.735c0-1.96-0.207-3.872-0.595-5.718l159.055-96.6l45.577,19.711
            c1.272,0.55,2.623,0.821,3.969,0.821c1.886,0,3.762-0.533,5.396-1.581l39.377-25.235c2.916-1.868,4.657-5.112,4.604-8.574
            C479.945,124.633,478.104,121.445,475.132,119.668z M429.708,142.194l-40.185-17.378l0.604-44.535l21.099-13.578l3.719,35.612
            c0.327,3.132,2.111,5.928,4.814,7.544l31.248,18.685L429.708,142.194z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>
