<template>
  <div>
    <b-modal
      id="modal-addSubscriptionForm"
      right
      shadow
      hide-footer
      :static="true"
      v-if="toggleAddSubscriptionCodeForm"
      v-model="showAddSubscriptionCodeForm"
    >
      <template v-slot:modal-title>Enter Activation Time Code</template>
      <AddSubscriptionCode
        v-on:newsubscriptioncode="onNewSubscriptionCode"
        v-on:cancel="toggleAddSubscriptionCodeForm"
      />
    </b-modal>
    <div>{{ isClubActiveText }}</div>
    <b-button
      variant="outline-info"
      :size="globalComponentSize"
      v-on:click="toggleAddSubscriptionCodeForm"
      >Add Activation Code</b-button
    >
    <hr />

    <b-form :id="'editClubForm'" @submit="onSubmit" @reset="onCancel">
      <b-form-group id="clubNameGroup" label="Club Name*:" label-for="clubName">
        <b-form-input
          id="clubName"
          v-model="form.clubName"
          required
          placeholder="Enter Club Name"
          :autofocus="true"
          :size="globalComponentSize"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="ownerGroup"
        label="Owner Name (E-mail)*:"
        label-for="ownerName"
      >
        <b-form-input
          id="ownerName"
          v-model.number="form.owner"
          required
          placeholder="Enter Owner E-mail"
          :disabled="true"
          :size="globalComponentSize"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="assistantGroup"
        label="Assistant Coaches (E-mails):"
        label-for="assistantCoaches"
      >
        <template v-for="ac in form.assistantCoaches">
          <div class="input-group" :key="ac._id + 'inputGrp'">
            <b-form-input
              :key="ac._id + 'inputForm'"
              v-model.number="ac.loginId"
              type="email"
              placeholder="Enter assistant coaches email"
              :size="globalComponentSize"
            ></b-form-input>
            <b-button
              :key="ac._id + 'btnremove'"
              v-on:click="removeAssistantCoachButtonClick(ac._id)"
              variant="warning"
              tabindex="-1"
              :size="globalComponentSize"
              >Remove</b-button
            >
          </div>
          <p :key="ac._id + 'paragraph'" />
        </template>
        <b-button
          id="btnAdd"
          v-on:click="addAssistantCoachButtonClick"
          variant="outline-info"
          :size="globalComponentSize"
          >Add Assistant Coach</b-button
        >
      </b-form-group>

      <section id="ParticipantGroups">
        <label
          >Archer Groups:
          <b-avatar
            id="participantGroupsHelpIcon"
            size="sm"
            icon="question"
            button
            variant="info" /></label
        ><br />
        <b-popover
          id="participantGroupsHelpIconPopover"
          target="participantGroupsHelpIcon"
          triggers="click"
          placement="auto"
          variant="info"
        >
          <template #title>
            <b-button class="close" aria-label="Close">
              <span class="d-inline-block" aria-hidden="true">&times;</span>
            </b-button>
            What can you do with Archer Groups?
          </template>
          <b-list-group style="overflow-y: scroll; height: 65vh">
            <b-list-group-item
              >You can put archers in one or many groups. <br />
              <b-avatar style="float: right" icon="people-fill"
            /></b-list-group-item>
            <b-list-group-item
              >You can filter the visible archers by one or multiple
              groups. This filtering can show archers in either selected
              group using the "OR" filter while the "AND" filter will only show
              those that are in both groups.
              <VinDiagramORIcon style="width: 100%" />
              <VinDiagramANDIcon style="width: 100%" />
            </b-list-group-item>
            <b-list-group-item
              >You could make groups for
              <ul>
                <li>Beginner</li>
                <li>Intermediate</li>
                <li>Advanced</li>
                <li>Lefty</li>
                <li>Righty</li>
                <li>Spring 21</li>
                <li>Fall 2021</li>
                <li>Etc...</li>
              </ul>
              It is often easier to manage groups in this way than if you
              combine them into "Beginner Spring 21" (for
              example).</b-list-group-item
            >
            <b-list-group-item
              >You can change the name of the group here and the name will be
              updated on all archers already in that
              group.</b-list-group-item
            >
            <b-list-group-item
              >You can remove a group from here and no archer will be in
              that group anymore.</b-list-group-item
            >
            <b-list-group-item
              >You can have up to 20 groups. If you need more, please contact
              support.</b-list-group-item
            >
          </b-list-group>
        </b-popover>
        <template v-for="participantGroup in form.participantGroups">
          <div class="input-group" :key="participantGroup._id + 'inputGrp'">
            <b-form-input
              :key="participantGroup._id + 'inputForm'"
              v-model.number="participantGroup.name"
              placeholder="Enter a group name"
              :size="globalComponentSize"
            ></b-form-input>
            <b-button
              :key="participantGroup._id + 'btnremove'"
              v-on:click="
                removeParticipantGroupButtonClick(participantGroup._id)
              "
              variant="warning"
              tabindex="-1"
              :size="globalComponentSize"
              >Remove</b-button
            >
          </div>
          <p :key="participantGroup._id + 'paragraph'" />
        </template>
        <b-button
          id="btnAdd"
          v-on:click="addParticipantGroupButtonClick"
          variant="outline-info"
          :size="globalComponentSize"
          >Add Archer Group</b-button
        >
      </section>

      <hr />
      <div>* indicates required field</div>

      <div>
        <b-button
          class="float-left"
          id="btnCancel"
          type="reset"
          variant="default"
          tabindex="-1"
          :size="globalComponentSize"
          >Cancel</b-button
        >

        <b-button
          class="float-right"
          :id="'btnSaveClub-' + club._id"
          type="submit"
          variant="primary"
          :size="globalComponentSize"
          >Save</b-button
        >
      </div>
    </b-form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
const uuidv4 = require('uuid').v4;
import AddSubscriptionCode from './AddSubscriptionCode';
import VinDiagramANDIcon from './VinDiagramANDIcon';
import VinDiagramORIcon from './VinDiagramORIcon';

const defaultFormValues = {
  clubName: '',
  owner: '',
  assistantCoaches: [],
  participantGroups: [],
};
export default {
  name: 'EditClubForm',
  data() {
    return {
      form: defaultFormValues,
    };
  },
  props: {
    club: Object,
  },
  components: {
    AddSubscriptionCode,
    VinDiagramANDIcon,
    VinDiagramORIcon,
  },
  computed: {
    ...mapGetters('subscription', [
      'showAddSubscriptionCodeForm',
      'getDaysRemainingInSubscription',
    ]),
    ...mapGetters('club', ['getSelectedClub']),
    ...mapGetters('login', ['getLoginTokenAssembly']),
    ...mapGetters('sitewide', ['globalComponentSize']),
    isClubActiveText: {
      get() {
        let clubId = this.getSelectedClub._id;
        let days = this.getDaysRemainingInSubscription(clubId);
        return `Active for ${days} more days`;
      },
      set(value) {
        console.log(value);
      },
    },
  },
  created() {
    this.$root.$emit('bv::hide::popover');

    // to avoid problems with the modal having paths that can lead to an uncleared form we will set defaults before setting other data.
    this.setDefaults();

    this.form.clubName = this.club.clubName;
    this.form.owner = this.club.owner;
    if (this.club.assistantCoaches !== undefined) {
      for (let ac of this.club.assistantCoaches) {
        this.form.assistantCoaches.push({ _id: uuidv4(), loginId: ac.loginId });
      }
    } else {
      this.form.assistantCoaches = [];
    }
    if (this.club.participantGroups !== undefined) {
      for (let pg of this.club.participantGroups) {
        this.form.participantGroups.push(Object.assign({}, pg));
      }
    } else {
      this.form.participantGroups = [];
    }
  },
  methods: {
    ...mapActions('subscription', {
      toggleAddSubscriptionCodeForm: 'toggleAddSubscriptionCodeForm',
      claimSubscriptionCode: 'claimSubscriptionCode',
    }),
    ...mapActions('sitewide', {
      toastError: 'toastError',
    }),
    isEmptyOrSpaces(str) {
      if (str === undefined || str === null) {
        return true;
      } else {
        return str.match(/^ *$/) !== null;
      }
    },
    onSubmit(evt) {
      evt.preventDefault();
      let updatedClub = Object.assign({}, this.form);
      updatedClub._id = this.club._id; // map values not bound to the form.
      updatedClub._rev = this.club._rev;
      updatedClub.assistantCoaches = [];
      for (let ac of this.form.assistantCoaches) {
        if (ac !== undefined && ac.loginId !== undefined) {
          updatedClub.assistantCoaches.push({ loginId: ac.loginId });
        }
      }
      updatedClub.participantGroups = [];
      for (let participantGroup of this.form.participantGroups) {
        if (!this.isEmptyOrSpaces(participantGroup.name)) {
          updatedClub.participantGroups.push(participantGroup);
        }
      }
      updatedClub.clubTokenAssembly = this.club.clubTokenAssembly;

      this.$emit('updateClub', updatedClub);
      this.setDefaults();
    },
    async onNewSubscriptionCode(newSubscriptionCode) {
      this.toggleAddSubscriptionCodeForm();
      let club = await this.getSelectedClub;
      let claimSubscriptionRequest = {
        requestCode: newSubscriptionCode,
        loginTokenAssembly: await this.getLoginTokenAssembly,
        type: 'admin',
        club: club,
      };
      this.claimSubscriptionCode(claimSubscriptionRequest);
    },
    setDefaults() {
      this.form.clubName = '';
      this.form.owner = '';
      this.form.assistantCoaches = [];
      this.form.participantGroups = [];
    },
    onCancel() {
      this.setDefaults();
      this.$emit('cancel');
    },
    addAssistantCoachButtonClick() {
      this.form.assistantCoaches.push({ _id: uuidv4() });
    },
    addParticipantGroupButtonClick() {
      if (this.form.participantGroups.length >= 20) {
        this.toastError('You cannot add more than 20 participant groups.');
      } else {
        this.form.participantGroups.push({ _id: uuidv4() });
      }
    },
    removeAssistantCoachButtonClick(_idToRemove) {
      const indexOfExisting = this.form.assistantCoaches.findIndex(
        (x) => x._id === _idToRemove
      );
      if (indexOfExisting !== -1) {
        this.form.assistantCoaches.splice(indexOfExisting, 1);
      } else {
        console.log(
          'unexptected attempted removal of unknown object: ' + _idToRemove
        );
      }
    },
    removeParticipantGroupButtonClick(_idToRemove) {
      const indexOfExisting = this.form.participantGroups.findIndex(
        (x) => x._id === _idToRemove
      );
      if (indexOfExisting !== -1) {
        this.form.participantGroups.splice(indexOfExisting, 1);
      } else {
        console.log(
          'unexptected attempted removal of unknown object: ' + _idToRemove
        );
      }
    },
  },
};
</script>
<style scoped></style>
