<template>
  <div />
</template>
<script>
export default {
  name: 'toaster',
  data: () => {
    return {};
  },
  // https://github.com/shakee93/vue-toasted
  created() {
    this.$store.subscribeAction((action) => {
      if (action.type === 'toast') {
        // TODO: these should have ? syntax but the shitty test tools the ui uses do not understand the this?.this syntax. :(
        // TODO: could have the toast settings passed in the payload to more the responsibility back to the context of usage.
        //       Though having it all in one place is also usefull for controling consistency in how the toast looks
        let displayText = action.payload.displayText;
        let toastType = action.payload.type;
        console.log('TOASTED TYPE: ' + toastType + ' TEXT: ' + displayText);
        if (toastType === 'pinEarned') {
          this.$toasted.show(displayText, {
            theme: 'outline',
            duration: 10000,
            keepOnHover: true,
            type: 'success',
          });
        } else if (toastType === 'createLogin') {
          this.$toasted.show(displayText, {
            theme: 'outline',
            duration: 10000,
            keepOnHover: true,
            type: 'success',
          });
        } else if (toastType === 'createLoginFailed') {
          this.$toasted.show(displayText, {
            theme: 'outline',
            duration: 10000,
            keepOnHover: true,
            type: 'error',
          });
        } else if (toastType === 'createLoginSubmit') {
          this.$toasted.show(displayText, {
            theme: 'outline',
            duration: 10000,
            keepOnHover: true,
            type: 'info',
          });
        } else if (toastType === 'error') {
          this.$toasted.show(displayText, {
            theme: 'outline',
            duration: 5000,
            keepOnHover: true,
            type: 'error',
          });
        } else if (toastType === 'info') {
          this.$toasted.show(displayText, {
            theme: 'outline',
            duration: 5000,
            keepOnHover: true,
            type: 'info',
          });
        } else if (toastType === 'success') {
          this.$toasted.show(displayText, {
            theme: 'outline',
            duration: 5000,
            keepOnHover: true,
            type: 'success',
          });
        }
      }
    });
  },
};
</script>
<style scoped></style>
